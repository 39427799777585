import React from 'react'
import styled from 'styled-components'

type PersonSubTreeProps = {}
const PersonSubTree = ({}: PersonSubTreeProps) => {
  return <Wrapper></Wrapper>
}

export default React.memo(PersonSubTree)

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: crimson;
`
