import React from 'react'
import styled from 'styled-components'

import Pet from './Pet'

import {PetModel} from '@components/organisms/Tree/types'
import {TreeModel} from '@api/models'

const PET_SIZE: number = 40

type PetsProps = {
  style: any
  sizeKoef?: number
  pets: PetModel[]
  treeId: TreeModel['id']
  editMode: boolean
}

const Pets = ({style, sizeKoef = 1, pets, treeId, editMode}: PetsProps) => {
  if (!pets?.length) return null

  const size = sizeKoef * PET_SIZE

  // const debugColor = Math.floor(Math.random() * 16777215).toString(16)

  const handleMouseOver = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Wrapper onMouseOver={handleMouseOver}>
      {pets.map((pet) => (
        <Pet key={pet.id} size={size} pet={pet} treeId={treeId} editMode={editMode} />
      ))}
    </Wrapper>
  )
}

export default React.memo(Pets)

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  z-index: 1;
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;

  //border: 1px solid green;
`
