/*
-1 красный
-2 Розовый
-3 Оранжевый
-4 Зеленый
-5 Голубой
*/

export enum TreeBackgroundColorsEnum {
  red = -1,
  pink = -2,
  orange = -3,
  green = -4,
  blue = -5,
  byKiraKey = -6,
  byPitsaSHIZA = -7,
  backDark = -8,
  backLight = -9,
}

type TreeBackgroundColors = {
  [key in TreeBackgroundColorsEnum]: {image: string; name: string}
}

export const TREE_BACKGROUND_COLORS: TreeBackgroundColors = {
  [TreeBackgroundColorsEnum.red]: {
    image: require('@assets/backgrounds/red.jpg'),
    name: 'Red',
  },
  [TreeBackgroundColorsEnum.pink]: {
    image: require('@assets/backgrounds/pink.jpg'),
    name: 'Pink',
  },
  [TreeBackgroundColorsEnum.orange]: {
    image: require('@assets/backgrounds/orange.jpg'),
    name: 'Orange',
  },
  [TreeBackgroundColorsEnum.green]: {
    image: require('@assets/backgrounds/green.jpg'),
    name: 'Green',
  },
  [TreeBackgroundColorsEnum.blue]: {
    image: require('@assets/backgrounds/blue.jpg'),
    name: 'Blue',
  },
  [TreeBackgroundColorsEnum.byKiraKey]: {
    image: require('@assets/backgrounds/byKiraKey.jpg'),
    name: 'by Kira Key',
  },
  [TreeBackgroundColorsEnum.byPitsaSHIZA]: {
    image: require('@assets/backgrounds/byPitsaSHIZA.jpg'),
    name: 'by pitsaSHIZA',
  },
  [TreeBackgroundColorsEnum.backDark]: {
    image: require('@assets/backgrounds/back_dark.jpg'),
    name: 'by summerannj',
  },
  [TreeBackgroundColorsEnum.backLight]: {
    image: require('@assets/backgrounds/back_light.jpg'),
    name: 'by summerannj',
  },
}

export const TREE_BACKGROUND_COLORS_ORDER = [
  TreeBackgroundColorsEnum.red,
  TreeBackgroundColorsEnum.pink,
  TreeBackgroundColorsEnum.orange,
  TreeBackgroundColorsEnum.green,
  TreeBackgroundColorsEnum.blue,
  TreeBackgroundColorsEnum.byKiraKey,
  TreeBackgroundColorsEnum.byPitsaSHIZA,
  TreeBackgroundColorsEnum.backDark,
  TreeBackgroundColorsEnum.backLight,
]
