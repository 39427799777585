import {useLayoutEffect, useMemo, useRef, useState} from 'react'
import {NODE_HEIGHT, NODE_WIDTH} from '../constants'

const useMinScale = ({treeData}) => {
  const canvasSizes = useMemo(() => {
    return {
      width: treeData.canvas.width * NODE_WIDTH,
      height: treeData.canvas.height * NODE_HEIGHT,
    }
  }, [treeData.canvas.height, treeData.canvas.width])

  const wrapperRef = useRef(null)
  const [wrapperSizes, setWrapperSizes] = useState()

  const updateWrapperSize = () => {
    // console.log('### updateWrapperSize')
    setWrapperSizes({
      height: wrapperRef.current?.offsetHeight,
      width: wrapperRef.current?.offsetWidth,
    })
  }

  const timeooutUpdateWrapperSizeRef = useRef(null)

  useLayoutEffect(() => {
    const delayedUpdate = (delay = 100) => {
      clearTimeout(timeooutUpdateWrapperSizeRef.current)
      timeooutUpdateWrapperSizeRef.current = setTimeout(() => {
        timeooutUpdateWrapperSizeRef.current = null
        updateWrapperSize()
      }, delay)
    }

    window.addEventListener('resize', delayedUpdate)
    delayedUpdate(100)

    return () => window.removeEventListener('resize', delayedUpdate)
  }, [])

  const minScale = useMemo(() => {
    if (wrapperSizes && canvasSizes) {
      return Math.min(
        (wrapperSizes.height || wrapperSizes.width) / canvasSizes.height,
        wrapperSizes.width / canvasSizes.width
      )
    }
  }, [wrapperSizes, canvasSizes])

  return {wrapperRef, minScale}
}

export default useMinScale
