/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import {uploadImage} from '@api/requests'

import useOnScreen from '@hooks/useOnScreen'

import {usePhotosStore} from '@store/photos'
import {useCultsStore} from '@pages/CultsPage/store'
import {useTagsStore} from '@pages/TagsPage/store'

import ReactQuillEditor from '@components/molecules/ReactQuillEditor'
import CustomFields from '@components/molecules/CustomFields'
import ThemedSelect from '@components/atoms/ThemedSelect'

import {AVATAR_IMAGE_TARGET_SIZES} from '@constants/imageUploading'

const AddSimDrawerContent = ({handleValuesChange, setParentAvatar, initialType}) => {
  // console.log('### AddSimDrawerContent.initialType', initialType)
  const intl = useIntl()
  const butOpenEditorRef = useRef<any>()
  const {selectPhoto} = usePhotosStore()
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [alive, setAlive] = useState<number>()
  const [deadReason, setDeadReason] = useState('')
  const [species, setSpecies] = useState('')
  const [type, setType] = useState<number>(initialType)
  console.log('### AddSimDrawerContent.type', type)
  const {tags: unSortTags, loadTags} = useTagsStore()
  const [selectedTags, setSelectedTags] = useState<any>([])
  const tags = useMemo(
    () => unSortTags?.map((tag) => ({label: tag.name, value: tag.id})),
    [unSortTags]
  )
  const [gender, setGender] = useState<number>()
  const [otherGender, setOtherGender] = useState('')
  const [genderPronoun, setGenderPronoun] = useState('')

  const [look, setLook] = useState('')
  const [city, setCity] = useState('')
  const [character_traits, setCharacterTraits] = useState('')
  const [life_goals, setLifeGoals] = useState('')
  const [description, setDescription] = useState('')
  const [avatar, setAvatar] = useState(null)
  const [avatar_id, setAvatarId] = useState<number | null>(null)
  const [customsFields, setCustomFields] = useState<any>([])

  const {cults, loadCults} = useCultsStore()
  useEffect(() => {
    loadCults()
  }, [])

  const addSimDrawerRef = useRef(null)
  const visible = useOnScreen(addSimDrawerRef)

  useEffect(() => {
    if (!visible) {
      setFirstName('')
      setLastName('')
      setParentAvatar(null)
      setSelectedTags(null)
      // setAlive(1)
      setSpecies('')
      // setType(0)
      setGender(undefined)
      setOtherGender('')
      setGenderPronoun('')

      setCustomFields([])
      setCharacterTraits('')
      setLifeGoals('')
      setDescription('')
      setAvatar(null)
      setAvatarId(null)
    } else loadTags()
  }, [visible])

  useEffect(() => {
    handleValuesChange({
      first_name,
      species,
      last_name,
      avatar_id,
      params: {fields: customsFields ? customsFields : [], genderPronoun},
      gender,
      tags: selectedTags ? selectedTags.map(({value}) => value) : [],
      other_gender: otherGender,
      is_alive: alive,
      deadReason,
      type,
      city,
      look_id: look,
      character_traits,
      life_goals,
      description,
    })
  }, [
    first_name,
    last_name,
    selectedTags,
    customsFields,
    alive,
    avatar_id,
    species,
    deadReason,
    gender,
    otherGender,
    genderPronoun,
    type,
    city,
    look,
    character_traits,
    life_goals,
    description,
  ])
  const callBackForCrop = (file) => {
    let reader = new FileReader()
    reader.onload = function (e) {
      if (e?.target?.result) {
        uploadImage(file, 1).then((r) => {
          setAvatarId(r.data.id)
          // @ts-ignore
          setParentAvatar(r.data)
        })
      }
      // @ts-ignore
      setAvatar(e.target.result)
    }
    reader.readAsDataURL(file)
  }
  function handleFiles(event) {
    let file = event.currentTarget.files[0]
    selectPhoto(file, callBackForCrop, 1, AVATAR_IMAGE_TARGET_SIZES, false, true)

    event.currentTarget.value = ''

    if (butOpenEditorRef?.current)
      //@ts-ignore
      butOpenEditorRef.current.click()
  }

  return (
    <div
      className='card-body hover-scroll-overlay-y h-400px pt-5'
      id='kt_help_body'
      ref={addSimDrawerRef}
    >
      {/* @ts-ignore */}
      <button
        ref={butOpenEditorRef}
        style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_edit_photo'
      />
      <div className='row mb-6'>
        <label className='col-lg-12 col-form-label fw-semibold fs-6'>
          {intl.formatMessage({id: 'TREE.ADD.PHOTO'})}
        </label>
        <div className='col-lg-12'>
          <div className='image-input image-input-outline' data-kt-image-input='true'>
            <div
              style={{
                backgroundImage: avatar
                  ? `url('${avatar}')`
                  : 'url("https://my-engine.ru/modules/users/avatar.png")',
              }}
              className='image-input-wrapper w-125px h-125px'
            />
            <label
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              title='Change avatar'
            >
              <i className='ki-duotone ki-pencil fs-7'>
                <span className='path1' />
                <span className='path2' />
              </i>
              <input onChange={handleFiles} type='file' name='avatar' accept='.png, .jpg, .jpeg' />
              <input type='hidden' name='avatar_remove' />
            </label>
            <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='ki-duotone ki-cross fs-2'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </span>
          </div>
          <div className='form-text'>{intl.formatMessage({id: 'TREE.ADD.PHOTO.FORMAT'})}</div>
        </div>
      </div>
      <div className='mb-10'>
        <label className='required form-label'>
          {intl.formatMessage({id: 'AUTH.INPUT.FIRST_NAME'})}
        </label>
        <input
          type='text'
          value={first_name}
          onChange={(e) => setFirstName(e.currentTarget.value)}
          className='form-control'
          placeholder=''
        />
      </div>
      <div className='mb-10'>
        <label className={clsx('form-label', {required: type !== 1})}>
          {intl.formatMessage({id: 'AUTH.INPUT.LAST_NAME'})}
        </label>
        <input
          type='text'
          value={last_name}
          onChange={(e) => setLastName(e.currentTarget.value)}
          className='form-control'
          placeholder=''
        />
      </div>
      <div className='mb-10'>
        <label className='form-label'>{intl.formatMessage({id: 'SIM.GENDER'})}</label>
        <select
          value={gender}
          onChange={(e) => setGender(parseInt(e.currentTarget.value))}
          className='form-select'
          aria-label='Select example'
        >
          <option>{intl.formatMessage({id: 'SELECT.GENDER'})}</option>
          <option value={0}>{intl.formatMessage({id: 'SELECT.GENDER.MALE'})}</option>
          <option value={1}>{intl.formatMessage({id: 'SELECT.GENDER.FEMALE'})}</option>
          <option value={2}>{intl.formatMessage({id: 'SELECT.GENDER.OTHER'})}</option>
        </select>
      </div>
      {gender === 2 ? (
        <>
          <div className='mb-10'>
            <input
              type='text'
              value={otherGender}
              className='form-control'
              placeholder={intl.formatMessage({id: 'SIM.GENDER.ENTER'})}
              onChange={(e) => setOtherGender(e.currentTarget.value)}
            />
          </div>
          <div className='mb-10'>
            <label className=' form-label'>
              {intl.formatMessage({id: 'SELECT.GENDER.PRONOUNS'})}
            </label>
            <input
              type='text'
              value={genderPronoun}
              className='form-control'
              placeholder=''
              onChange={(e) => setGenderPronoun(e.currentTarget.value)}
            />
          </div>
        </>
      ) : null}

      <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'SIM.CONDITION'})}</label>
        <select
          value={alive}
          className='form-select'
          aria-label='Select example'
          onChange={(e) => setAlive(parseInt(e.currentTarget.value))}
        >
          <option>{intl.formatMessage({id: 'SIM.CONDITION.SELECT'})}</option>
          <option value={1}>{intl.formatMessage({id: 'SIM.CONDITION.ALIVE'})}</option>
          <option value={0}>{intl.formatMessage({id: 'SIM.CONDITION.DEAD'})}</option>
        </select>
      </div>
      {/*<div className="mb-10">
          <label className=" form-label">Причина смерти</label>
          <input type="email" value={deadReason} onChange={e=>setDeadReason(e.currentTarget.value)} className="form-control form-control-solid" placeholder=""/>
      </div>*/}
      <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'SIM.TYPE'})}</label>
        <select
          value={type}
          className='form-select'
          aria-label='Select example'
          onChange={(e) => setType(parseInt(e.currentTarget.value))}
        >
          <option>{intl.formatMessage({id: 'SIM.TYPE.SELECT'})}</option>
          <option value={0}>{intl.formatMessage({id: 'SIM.TYPE.PERSON'})}</option>
          <option value={1}>{intl.formatMessage({id: 'SIM.TYPE.PET'})}</option>
        </select>
      </div>
      <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'SIDEBAR.MENU.TAGS'})}</label>
        <ThemedSelect
          value={selectedTags}
          onChange={(selectedTag) => setSelectedTags(selectedTag)}
          placeholder={intl.formatMessage({id: 'SIM.TAG.SELECT'})}
          className='react-select-styled'
          classNamePrefix='react-select'
          isMulti
          options={tags}
        />
      </div>
      <div className='mb-10'>
        <label className='form-label'>{intl.formatMessage({id: 'SIDEBAR.MENU.CULT'})}</label>
        <select
          value={look}
          className='form-control'
          placeholder=''
          onChange={(e) => {
            // @ts-ignore
            setLook(parseInt(e.currentTarget.value))
          }}
        >
          <option>{intl.formatMessage({id: 'SIM.CULT.SELECT'})}</option>
          {cults?.map(({id, name, file}) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </select>
      </div>

      {/* <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'SIM.RACE'})}</label>
        <input
          type='text'
          value={species}
          onChange={(e) => setSpecies(e.currentTarget.value)}
          className='form-control'
          placeholder=''
        />
      </div>*/}
      <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'PERSON.PERS'})}</label>
        <input
          type='text'
          value={character_traits}
          onChange={(e) => setCharacterTraits(e.currentTarget.value)}
          className='form-control'
          placeholder=''
        />
      </div>
      <div className='mb-10'>
        <label className='form-label'>{intl.formatMessage({id: 'PERSON.GOALS'})}</label>
        <input
          type='text'
          value={life_goals}
          onChange={(e) => setLifeGoals(e.currentTarget.value)}
          className='form-control '
          placeholder=''
        />
      </div>

      <CustomFields allowCopy values={customsFields} onChange={setCustomFields} />

      <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'TREE.ADD.DESCRIPTION'})}</label>
        <ReactQuillEditor initValue={description} setValue={setDescription} />
      </div>
      {/* end::Content */}
    </div>
  )
}

export {AddSimDrawerContent}
