import {useEffect, useMemo, useState} from 'react'

const useCalculateTreeLevels = (tree) => {
  const [levelsCount, setLevelsCount] = useState<number | null>(null)
  useEffect(() => {
    if (tree) {
      // calculation of tree height
      const nodesParentToChildrenMap = tree.nodes.reduce((res, node) => {
        if (node.parent_id) {
          if (!res[node.parent_id]) {
            res[node.parent_id] = []
          }
          res[node.parent_id] = [...res[node.parent_id], node.id]
        }

        return res
      }, {} as {[key: number]: number[]})
      const rootNodeId = tree.nodes
        .filter(({parent_id}) => !parent_id)
        .sort(({id: idA}, {id: idB}) => idB - idA)[0]?.id

      if (rootNodeId) {
        const calcChildrenCount = (nodeId: number, prevCount = 0) => {
          const nodeChildren = nodesParentToChildrenMap[nodeId]
          if (nodeChildren?.length > 0) {
            return Math.max(...nodeChildren.map((id) => calcChildrenCount(id, prevCount + 1)))
          }

          return prevCount + 1
        }

        const maxParentsLevelsLength = calcChildrenCount(rootNodeId)

        setLevelsCount(maxParentsLevelsLength)
      }
    }
  }, [tree])

  return levelsCount
}

export default useCalculateTreeLevels
