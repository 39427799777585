import React, {useEffect, useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import {useIntl} from 'react-intl'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

import {toAbsoluteUrl} from '@_metronic/helpers'

const MySwal = withReactContent(Swal)

type ShareLinkProps = {
  link: string
  copiedLabelId: string
  children?: any
}

const ShareLink = ({link, copiedLabelId, children}: ShareLinkProps) => {
  const intl = useIntl()

  const [copied, setCopied] = useState(false)
  useEffect(() => {
    if (!copied) {
      return
    }

    MySwal.fire({
      position: 'center',
      icon: 'success',
      title: intl.formatMessage({id: copiedLabelId}),
      showConfirmButton: false,
      timer: 1500,
      heightAuto: false,
    }).then(() => {
      setCopied(false)
    })
  }, [copied])

  return (
    <CopyToClipboard text={link} onCopy={() => setCopied(true)}>
      {children ? (
        children
      ) : (
        <span className='btn btn-sm btn-icon btn-outline btn-outline-dashed me-2'>
          <ShareIcon src={toAbsoluteUrl('/media/icons/duotune/arrows/arr078.svg')} />
        </span>
      )}
    </CopyToClipboard>
  )
}

export default React.memo(ShareLink)

export const ShareIcon = styled(SVG)`
  height: 20px;
  color: #78829d;
`
