import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useLang} from '@_metronic/i18n/Metronici18n'

const REF_KEY = '@ref'

const useReferralString = () => {
  const language = useLang()
  const languageRelativeStorageKey = `${REF_KEY}_${language}`
  const [ref, setRef] = useState<string | null>(() =>
    localStorage.getItem(languageRelativeStorageKey)
  )

  useEffect(() => {
    if (ref) {
      localStorage.setItem(languageRelativeStorageKey, ref)
    }
  }, [ref])

  const updateRef = (newRefValue) => {
    setRef(newRefValue)
  }

  const location = useLocation()
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const refFromQuery = searchParams.get('ref')
    if (refFromQuery) {
      updateRef(refFromQuery)
    }
  }, [location])

  return ref
}

export default useReferralString
