import React, {useEffect, useMemo} from 'react'
import styled from 'styled-components'

import {useSimDrawerStore} from '@_metronic/partials/layout/sim-drawer/SimDrawer'

import useHover from '@components/organisms/Tree/hooks/useHover'
import useHighlightParents from '@components/organisms/Tree/hooks/useHighlightParents'
import useFirstLetters from '@components/organisms/Tree/hooks/useFirstLetters'
import useSessionTs from '@hooks/useSessionTs'
import useTreeStyles from '@hooks/useTreeStyles'
import useHostBasedUrl from '@hooks/useHostBasedUrl'

import Partners from './components/Partners'
import EditButtons from './components/EditButtons'
import Pets from './components/Pets'
import NodeInfo from './components/NodeInfo'
import SubTreeEditButtons from '@components/organisms/Tree/components/Node/components/SubTreeEditButtons'
import SubTreeButton from '@components/atoms/SubTreeButton'
import Cult from '@components/organisms/Tree/components/Node/components/Cult'

import {NODE_AVATAR_SIZE, NODE_HEIGHT} from '../../constants'

const Node = ({treeId, node, style, person, owner, editMode, subTreeOwner, readonly}) => {
  const {hover, onMouseOver, onMouseLeave} = useHover()

  const {show: showSimDrawer} = useSimDrawerStore()
  const handleNodePress = (e) => {
    if (person.person_id && !editMode) {
      showSimDrawer(parseInt(person.person_id), {allowSharing: !subTreeOwner})
    } else {
      e.preventDefault(true)
      e.stopPropagation()
    }
  }

  const personForHighlight = useMemo(
    () => ({id: person.person_id, parents: person.parents}),
    [person]
  )
  const highlight = useHighlightParents(personForHighlight, hover, editMode)
  // const ref = useBlockContextMenu()
  const {
    avatarBorderColor,
    avatarBorderWidth,
    avatarBorderRadius,
    avatarBorderType,
    petsDisplayingOnTreeEnabled: petsDisplayingOnTreeEnabledString,
  } = useTreeStyles(treeId)

  const petsDisplayingOnTreeEnabled = petsDisplayingOnTreeEnabledString === 'true'

  const firstLetters = useFirstLetters(person.name)
  const {ts} = useSessionTs()
  const avatarUrl = useHostBasedUrl(node.avatar)
  const avatar = useMemo(() => {
    if (avatarUrl) {
      return <AvatarBackground src={`${avatarUrl}?ts=${ts}`} />
    }
    if (firstLetters) return <Initials>{firstLetters}</Initials>

    return <AvatarBackground src={'https://my-engine.ru/modules/users/avatar.png'} />
  }, [avatarUrl, firstLetters, ts])

  if (owner) {
    return (
      <Partners
        style={style}
        owner={owner}
        editMode={editMode}
        treeId={treeId}
        subTreeOwner={subTreeOwner}
        readonly={readonly}
      />
    )
  }

  const realPartnersCount = person.partners.length

  const avatarBorderWidthParsed = parseInt(`${avatarBorderWidth}`)

  // console.log('### Node.readonly', readonly)

  return (
    <>
      <Wrapper
        style={style}
        $highlight={highlight}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onDragStart={(e) => e.preventDefault()}
      >
        <AvatarWrapper>
          <Avatar
            id='kt_sim_toggle'
            $clickable={!editMode && person.person_id}
            $avatarBorderColor={avatarBorderColor}
            $avatarBorderWidth={avatarBorderWidth}
            $avatarBorderRadius={avatarBorderRadius}
            $avatarBorderType={avatarBorderType}
            onClick={handleNodePress}
          >
            {avatar}
          </Avatar>

          {person.look_id ? <Cult lookId={person.look_id} treeId={treeId} /> : null}

          <SubTreeButton
            personId={person.person_id}
            treeId={treeId}
            hover={hover}
            nodeSize={NODE_AVATAR_SIZE}
            avatarBorderWidthParsed={avatarBorderWidthParsed}
            editMode={editMode}
          />
        </AvatarWrapper>

        {petsDisplayingOnTreeEnabled ? (
          <Pets style={style} pets={person.pets} treeId={treeId} editMode={editMode} />
        ) : null}

        {editMode ? (
          <DashedBorder $partnersCount={realPartnersCount}>
            {subTreeOwner ? (
              <SubTreeEditButtons
                subTreeOwner={subTreeOwner}
                node={node}
                person={person}
                hover={hover}
              />
            ) : (
              <EditButtons treeId={treeId} node={node} person={person} hover={hover} />
            )}
          </DashedBorder>
        ) : null}
      </Wrapper>
      <NodeInfo style={style} treeId={treeId} person={person} leftOffset={-18} />
    </>
  )
}

export default React.memo(Node)

const Wrapper = styled.div`
  position: absolute;
  display: flex;

  opacity: ${({virtual, $highlight}) => (virtual ? 0.1 : $highlight ? 1 : 0.4)};
  transition: opacity 300ms;

  height: ${NODE_HEIGHT}px;
  width: ${NODE_HEIGHT}px;
  min-height: ${NODE_HEIGHT}px;
  min-width: ${NODE_HEIGHT}px;
  left: -13px;

  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`

const AvatarWrapper = styled.div`
  height: ${NODE_AVATAR_SIZE}px;
  width: ${NODE_AVATAR_SIZE}px;

  position: relative;
`

const Avatar = styled.div`
  height: ${NODE_AVATAR_SIZE}px;
  width: ${NODE_AVATAR_SIZE}px;

  cursor: ${({$clickable}) => ($clickable ? 'pointer' : 'initial')};

  border-radius: ${({$avatarBorderRadius}) => ($avatarBorderRadius * 0.8) / 2}px;
  border: ${({$avatarBorderType}) => $avatarBorderType}
    ${({$avatarBorderWidth}) => $avatarBorderWidth}px
    ${({$avatarBorderColor}) => $avatarBorderColor};
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  overflow: hidden;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;
  user-drag: none;
`

const AvatarBackground = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  flex: 1;
`

const Initials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  width: 100%;
  height: 100%;

  font-size: ${NODE_HEIGHT * 0.3}px;
  color: #fff;
  font-weight: 500;
`

const DashedBorder = styled.div`
  position: absolute;

  height: ${NODE_HEIGHT}px;
  width: ${({$partnersCount}) => {
    if ($partnersCount === 1) {
      return NODE_HEIGHT * 2 + 46
    }
    if ($partnersCount === 2) {
      return NODE_HEIGHT * 2 + 20
    }

    if ($partnersCount > 2) {
      return NODE_HEIGHT * 2 + 20
    }

    return NODE_HEIGHT
  }}px;

  left: 0;
  top: 0;

  border: 2px dashed #ffffffaa;
  border-radius: ${NODE_HEIGHT / 2 + 4}px;

  box-sizing: border-box;
`
