import {useEffect, useState} from 'react'
import {useLocation, Link} from 'react-router-dom'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {PasswordMeterComponent} from '@_metronic/assets/ts/components'
import {getUserByToken, recoverPassword} from '../core/_requests'
import {useIntl} from 'react-intl'
import {resetPasswordSchema} from '../validation-schemas/reset-password-schema'
import {useAuth} from '../core/Auth'

const initialValues = {
  password: '',
  changepassword: '',
}

export function ResetPassword() {
  const intl = useIntl()
  const currentLocale = intl.locale
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const {saveAuth, setCurrentUser} = useAuth()
  const location = useLocation()
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema(currentLocale),
    validateOnChange: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await recoverPassword(values.password, token)
        saveAuth(auth)
        const {data} = await getUserByToken(auth.TOKEN)
        setCurrentUser(data)
        setLoading(false)
      } catch (err) {
        console.error(err)
        setLoading(false)
        setSubmitting(false)
        setStatus('The token is incorrect.')
      }
    },
  })

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const token = urlParams.get('token') ?? ''
    setToken(token)
  }, [location])

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_password_recover_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({id: 'AUTH.RESET.TITLE'})}</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          {intl.formatMessage({id: 'AUTH.RESET.DESC'})}
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
          </label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>{intl.formatMessage({id: 'AUTH.VALIDATION.PASSWORD'})}</div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
        </label>
        <input
          type='password'
          placeholder={intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>{intl.formatMessage({id: 'ACTIONS.SUBMIT'})}</span>
          {loading && (
            <span className='indicator-progress'>
              {intl.formatMessage({id: 'ACTIONS.WAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {intl.formatMessage({id: 'ACTIONS.CANCEL'})}
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
