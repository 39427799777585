import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {create} from 'zustand'

import {getPerson} from '@api/requests'
import {PersonModel, TreeModel} from '@api/models'

import {usePersonsStore} from '@store/persons'
import {useTreesStore} from '@store/trees'

import SubTree from '@components/molecules/SubTreeModal/components/SubTree'

import {KTIcon} from '@_metronic/helpers'
import PersonInfoBlock from '@components/molecules/SubTreeModal/components/SubTree/components/PersonInfoBlock'

type Payload = {
  personId: PersonModel['id']
  treeId: TreeModel['id']
}

type SubTreeModalStore = {
  opened: boolean
  payload: Payload | null
  show: (payload: Payload) => void
  hide: () => void
}
export const useSubTreeModalStore = create<SubTreeModalStore>((set) => ({
  opened: false,
  payload: null,
  show: (payload) => set((state) => ({...state, payload, opened: true})),
  hide: () => set((state) => ({...state, opened: false, payload: null})),
}))

type SubTreeModalProps = {
  readonly?: boolean
  editMode: boolean
  children?: any
  onEditModeChange?: (state: boolean) => void
}

const SubTreeModal = ({readonly, editMode, children, onEditModeChange}: SubTreeModalProps) => {
  const {opened, hide, payload} = useSubTreeModalStore()
  const {persons, updatePersons} = usePersonsStore()
  const {activeTreeId} = useTreesStore()

  useEffect(() => {
    hide()
  }, [activeTreeId])

  const [person, setPerson] = useState<PersonModel | null>(null)
  useEffect(() => {
    if (opened && payload) {
      getPerson(payload.personId).then((response) => {
        // console.log('### SubTreeModal.received person ', response.data)
        setPerson(response.data)
        const indexOfPerson = persons.findIndex(({id}) => payload.personId === id)

        const updatedPersons = [...persons]
        updatedPersons[indexOfPerson] = response.data
        updatePersons(updatedPersons, true)
      })
    } else if (!opened) {
      setPerson(null)
    }

    // onEditModeChange?.(false)
  }, [opened, payload])

  if (!opened || !person) return children

  return (
    <FullSizeContent $readonly={readonly}>
      <CloseButton type='button' className='btn btn-sm btn-icon me-n5' onClick={hide}>
        <KTIcon iconName='cross' className='fs-2' />
      </CloseButton>
      {person && payload ? (
        <>
          <SubTree
            treeId={payload?.treeId}
            person={person}
            editMode={editMode}
            readonly={readonly}
          />
          <PersonInfoBlock treeId={payload?.treeId} person={person} editMode={editMode} />
        </>
      ) : (
        <Loader className='indicator-progress'>
          <span className='spinner-border spinner-border-sm align-middle ms-2' />
        </Loader>
      )}
    </FullSizeContent>
  )
}

export default React.memo(SubTreeModal)

const Loader = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
`

const FullSizeContent = styled.div<{$readonly?: boolean}>`
  width: 100%;
  height: 100%;

  position: relative;

  border-radius: ${({$readonly}) => ($readonly ? 0 : 5)}px;
  overflow: hidden;
`

const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
  background-color: rgba(255, 255, 255, 0.3);

  z-index: 1;
`
