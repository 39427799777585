import React from 'react'
import styled from 'styled-components'

import useHostBasedUrl from '@hooks/useHostBasedUrl'

import {useSimDrawerStore} from '@_metronic/partials/layout/sim-drawer/SimDrawer'
import {usePersonsStore} from '@store/persons'

import {PersonModel} from '@api/models'
import {RelationType} from '@app/types'

type RelationProps = {
  relation: PersonModel
  relationType: RelationType
  person: PersonModel
  readonly: boolean
}
const Relation = ({relation, relationType, person, readonly}: RelationProps) => {
  const {deleteRelationOfPerson} = usePersonsStore()
  const handleDeleteRelationClick = (relation: PersonModel) => {
    deleteRelationOfPerson({personId: person?.id!, relationId: relation.id, relationType})
  }

  const {show: showSimDrawer} = useSimDrawerStore()
  const handleParentClick = (parent: PersonModel) => {
    showSimDrawer(parent.id)
  }

  const avatarUrl = useHostBasedUrl(relation.avatar?.url)

  return (
    <>
      <Wrapper onClick={() => handleParentClick(relation)}>
        <div className='symbol symbol-45px me-5'>
          <AvatarBackground
            src={
              avatarUrl
                ? avatarUrl
                : relationType !== RelationType.pet
                ? 'https://my-engine.ru/modules/users/avatar.png'
                : require('@assets/images/pet-avatar.jpeg')
            }
            alt=''
          />
        </div>
        <div className='d-flex justify-content-start flex-column'>
          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
            {relation.first_name} {relation.last_name}
          </a>
        </div>
      </Wrapper>
      {!readonly ? (
        <button
          className='btn btn-sm btn-icon btn-outline btn-outline-dashed'
          onClick={() => handleDeleteRelationClick(relation)}
        >
          <i className='ki-duotone ki-trash fs-2'>
            <span className='path1' />
            <span className='path2' />
            <span className='path3' />
            <span className='path4' />
          </i>
        </button>
      ) : null}
    </>
  )
}

export default React.memo(Relation)

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const AvatarBackground = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  flex: 1;
`
