import React, {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'

import {changePersonArticlesSort} from '@api/requests'

import {useAuth} from '@modules/auth'
import usePrevious from '@hooks/usePrevious'

import {useArticleStore} from '@store/articles'
import {useSubscriptionModalStore} from '@components/molecules/SubscriptionModal/SubscriptionModal'
import {useEditArticleDrawerStore} from '@_metronic/partials/layout/editarticle-drawer/EditArticleDrawer'

import SimDrawerArticle from '@components/atoms/SimDrawerArticle'

import {Article, PersonModel} from '@api/models'

const getArrayOfSortedArticles = (articles: Article[]) => {
  articles = articles.map((article, index) => {
    return {
      ...article,
      sort: index,
    }
  })

  return articles.map((article) => {
    return {
      _id: article._id,
      sort: article.sort,
    }
  })
}

type SimDrawerArticlesProps = {
  personId: PersonModel['id']
  readonly: boolean
  allowSharing?: boolean
}

const SimDrawerArticles = ({personId, readonly, allowSharing}: SimDrawerArticlesProps) => {
  const intl = useIntl()

  const {activePersonArticles, setSortForArticles} = useArticleStore()
  const {open: showEditArticle} = useEditArticleDrawerStore()

  const {currentUser} = useAuth()
  const {show: showSubscriptionModal} = useSubscriptionModalStore()

  const handleAddPress = (event) => {
    if (!currentUser?.is_premium) {
      showSubscriptionModal()
      event.preventDefault()
      event.stopPropagation()

      return
    }

    showEditArticle({personId})
  }

  // console.log('### activePersonArticles', activePersonArticles)
  const sortedArticles = useMemo(
    () =>
      activePersonArticles?.sort((a, b) => {
        if (a.sort === undefined) return 0
        if (b.sort === undefined) return 0

        return a.sort - b.sort
      }),
    [activePersonArticles]
  )
  // console.log('### sortedArticles', sortedArticles)

  const updateArticlesSort = (articles: Article[]) => {
    const arrOfSortedArticles = getArrayOfSortedArticles(articles)

    changePersonArticlesSort(personId, arrOfSortedArticles).then(() => {
      setSortForArticles(arrOfSortedArticles)
    })
  }

  const upArticle = (key) => {
    let articles = activePersonArticles
    if (key > 0) {
      let prevArticle = articles[key - 1]
      articles[key - 1] = articles[key]
      articles[key] = prevArticle

      updateArticlesSort(articles)
    }
  }

  const downArticle = (key) => {
    let articles = activePersonArticles
    // console.log('### articles', articles)
    if (key < activePersonArticles.length - 1) {
      let nextArticle = articles[key + 1]
      articles[key + 1] = articles[key]
      articles[key] = nextArticle

      updateArticlesSort(articles)
    }
  }

  const prevArticlesCount = usePrevious(activePersonArticles.length)
  useEffect(() => {
    if (
      typeof activePersonArticles.length !== 'undefined' &&
      prevArticlesCount !== activePersonArticles.length
    ) {
      // console.log('### UPDATE SORTING', prevArticlesCount, activePersonArticles.length)
      updateArticlesSort(activePersonArticles)
    }
  }, [activePersonArticles.length])

  return (
    <div className='tab-pane fade active show' id='kt_tab_pane_2' role='tabpanel'>
      <div className='card-header p-0 mb-4'>
        <h3 className='card-title align-items-start flex-column mt-0 pt-0'>
          <span className='card-label fw-bold text-gray-900'>
            {intl.formatMessage({id: 'SIM.VIEW.TAB_ARTICLES'})}
          </span>
        </h3>

        {!readonly ? (
          <div className='card-toolbar'>
            <a className='btn btn-sm btn-light' onClick={handleAddPress}>
              {intl.formatMessage({id: 'PERSON.ADD'})}
            </a>
          </div>
        ) : null}
      </div>
      {sortedArticles?.map((article, index, arr) => (
        <SimDrawerArticle
          key={article._id}
          first={index === 0}
          last={index === arr.length - 1}
          upArticle={() => upArticle(index)}
          downArticle={() => downArticle(index)}
          maxLength={arr.length}
          personId={personId}
          article={article}
          readonly={readonly}
          allowSharing={allowSharing}
        />
      ))}
    </div>
  )
}

export {SimDrawerArticles}
