import React from 'react'
import InputColor from 'react-input-color'
import styled from 'styled-components'
import {useIntl} from 'react-intl'

import {useAuth} from '@modules/auth'
import {DEFAULT_TREE_STYLES} from '@hooks/useTreeStyles'

import {TreeModel} from '@api/models'
import {FIELDS_ORDER, FieldTypeEnum} from './constants'

type Values = {
  lineColor?: string
  avatarBorderColor?: string
  avatarBorderWidth?: string
  avatarBorderRadius?: string
  heirLineColor?: string
}

type StylingFieldsProps = {
  focused: boolean
  treeId?: TreeModel['id']
  values: Values
  onChange: (values: Values) => void
  onReset: () => void
}
const StylingFields = ({focused, treeId, values, onChange, onReset}: StylingFieldsProps) => {
  const intl = useIntl()
  const handleValueChange = (name: string, value: string, type: FieldTypeEnum) => {
    onChange({...values, [name]: value})
  }

  const fields = FIELDS_ORDER.map((field) => ({
    ...field,
    value: values?.[field.name],
    defaultValue: DEFAULT_TREE_STYLES[field.name],
  }))

  const {currentUser} = useAuth()

  if (!focused) return null

  return (
    <Wrapper className='mb-10'>
      <div className='card-header mb-6 px-0'>
        <h4 className='card-title fw-bold text-gray-900'>
          {intl.formatMessage({id: 'TREE.STYLING.HEADER'})}
        </h4>
      </div>
      {fields.map(({name, type, label, value, defaultValue, options, max, forAll}, index) => {
        // if (!forAll && !currentUser?.is_premium) return null
        const disabled = !forAll && !currentUser?.is_premium

        return (
          <FieldWrapper key={name} className='mb-5'>
            <FieldLabel className='form-label'>
              {intl.formatMessage({id: label})}{' '}
              {max ? `(${intl.formatMessage({id: 'TREE.STYLING.MAX'})} ${max})` : ''}:
            </FieldLabel>
            {type === FieldTypeEnum.color ? (
              <ColorWrapper>
                <ColorInput
                  disabled={disabled}
                  value={value ?? defaultValue}
                  onChange={(e: {currentTarget: {value: string}}) =>
                    handleValueChange(name, e.currentTarget.value, type)
                  }
                />
                <ColorField
                  initialValue={value ?? defaultValue}
                  placement='right'
                  disabled={disabled}
                  onChange={(color: {hex: string}) => handleValueChange(name, color.hex, type)}
                />
              </ColorWrapper>
            ) : null}
            {type === FieldTypeEnum.number ? (
              <NumberInput
                type='text'
                disabled={disabled}
                className='form-control'
                value={value ?? defaultValue}
                placeholder={`${defaultValue}`}
                onChange={(e: {currentTarget: {value: string}}) => {
                  const newValue = e.currentTarget.value.replace(/\D/, '')

                  handleValueChange(name, max && parseInt(newValue) > max ? value : newValue, type)
                }}
              />
            ) : null}
            {type === FieldTypeEnum.select ? (
              <Select
                value={value}
                className='form-select'
                aria-label={intl.formatMessage({id: label})}
                disabled={disabled}
                onChange={(e) => handleValueChange(name, e.currentTarget.value, type)}
              >
                {options?.map(({id, label}) => (
                  <option key={id} value={id}>
                    {intl.formatMessage({id: label})}
                  </option>
                ))}
              </Select>
            ) : null}

            {type === FieldTypeEnum.switch ? (
              <div className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value=''
                  checked={value ? value === 'true' : defaultValue === 'true'}
                  disabled={disabled}
                  onChange={(e) =>
                    handleValueChange(
                      name,
                      (value ? value === 'true' : defaultValue === 'true') ? 'false' : 'true',
                      type
                    )
                  }
                />
              </div>
            ) : null}
          </FieldWrapper>
        )
      })}

      {currentUser?.is_premium ? (
        <div className='mt-5 pt-5'>
          <button
            className='btn btn-secondary d-flex justify-content-center align-items-center'
            onClick={onReset}
          >
            {intl.formatMessage({id: 'TREE.STYLING.DEFAULT_STYLES'})}
          </button>
        </div>
      ) : null}
    </Wrapper>
  )
}

export default React.memo(StylingFields)

const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const FieldLabel = styled.label`
  width: 330px;
`

const ColorWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ColorField = styled(InputColor)`
  width: 40px !important;
  height: 40px !important;
  box-sizing: border-box;
  border-radius: 5px !important;

  margin-left: 5px;
`

const ColorInput = styled.input`
  width: 140px !important;
  height: 40px !important;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid var(--bs-gray-300);
  text-transform: uppercase;
`

const NumberInput = styled.input`
  width: 182px !important;

  box-sizing: border-box;
`

const Select = styled.select`
  width: 182px !important;

  box-sizing: border-box;
`
