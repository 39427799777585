import {FC, MouseEvent} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'

import {checkIsActive, KTIcon, WithChildren} from '@_metronic/helpers'
import {useLayout} from '../../../core'

type Props = {
  to: string
  title: string
  icon?: string
  openId?: string
  active?: boolean | null
  fontIcon?: string
  hasBullet?: boolean
  openRightToolbar?: boolean
  id?: string
  onClick?: (event: MouseEvent) => void
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  active = null,
  to,
  openId,
  title,
  openRightToolbar,
  icon,
  fontIcon,
  hasBullet = false,
  id,
  onClick,
}) => {
  const {pathname} = useLocation()
  const isActive = active === null ? checkIsActive(pathname, to) : active
  const {config} = useLayout()
  const {app} = config

  return (
    <div className='menu-item'>
      <Link
        id={openRightToolbar ? openId : ''}
        title='Learn & Get Inspired'
        data-bs-toggle={openRightToolbar ? 'tooltip' : ''}
        data-bs-placement={openRightToolbar ? 'left' : ''}
        data-bs-dismiss={openRightToolbar ? 'click' : ''}
        data-bs-trigger={openRightToolbar ? 'hover' : ''}
        className={clsx('menu-link without-sub', {active: isActive})}
        to={to}
        onClick={onClick}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
