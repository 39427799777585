import {useMemo} from 'react'

const useFirstLetters = (name?: string) => {
  return useMemo(
    () =>
      name
        ?.split(' ')
        .map((name) => name.toUpperCase()[0])
        .join(''),
    [name]
  )
}

export default useFirstLetters
