import React, {useEffect, useMemo, useRef, useState} from 'react'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'

import {PublishStatusEnum} from '@api/models'

import useTreePublishRootNodePerson from '@components/organisms/Tree/hooks/useTreePublishRootNodePerson'

import {useTreesStore} from '@store/trees'

import useOnScreen from '@hooks/useOnScreen'
import useShareTreeLink from '@hooks/useShareTreeLink'

import ImageField, {Image} from '@components/atoms/ImageField'
import CopyLink from '@components/atoms/CopyLink'

import {KTIcon} from '@_metronic/helpers'

import {TREE_GALLERY_PREVIEW_IMAGE_TARGET_SIZES} from '@constants/imageUploading'

const MySwal = withReactContent(Swal)

const PUBLISH_STATUSES: {id: PublishStatusEnum; labelId: string}[] = [
  {id: PublishStatusEnum.private, labelId: 'TREE.PUBLISH_STATUS.PRIVATE'},
  // {id: PublishStatusEnum.public, labelId: 'TREE.PUBLISH_STATUS.PUBLIC'},
  {id: PublishStatusEnum.link, labelId: 'TREE.PUBLISH_STATUS.BY_LINK'},
  {id: PublishStatusEnum.public, labelId: 'GALLERY.TITLE'},
]

type TreePublishDrawerProps = {}
const TreePublishDrawer = ({}: TreePublishDrawerProps) => {
  const intl = useIntl()

  const ref = useRef(null)
  const visible = useOnScreen(ref)

  const {activeTreeId, trees, updateTree} = useTreesStore()
  const tree = activeTreeId ? trees[activeTreeId] : null

  // @ts-ignore
  const [publishStatus, setPublishStatus] = useState<PublishStatusEnum>(tree?.publish?.status)

  useEffect(() => {
    if (tree?.publish?.status) {
      setPublishStatus(tree?.publish?.status)
    }
  }, [tree])

  const [treePublishToken, setTreePublishToken] = useState(tree?.publish?.token)
  useEffect(() => {
    setTreePublishToken(tree?.publish?.token)
  }, [tree?.publish?.token])

  useEffect(() => {
    // setTreePublishToken(tree?.publish?.token)
    if (activeTreeId && tree?.publish?.status !== publishStatus) {
      updateTree(activeTreeId, {publish: {status: publishStatus}}).then((response) => {
        setTreePublishToken(response?.data?.publish?.token)

        MySwal.fire({
          title: intl.formatMessage({id: 'TREE.PUBLISH_STATUS.UPDATE_SUCCESS'}),
          text: '',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false,
        })
      })
    }
  }, [publishStatus])

  const [previewImage, setPreviewImage] = useState<Image | null>(tree?.publish?.preview || null)
  const firstNodePersonId = useTreePublishRootNodePerson(tree)
  const nonEmptyTree = firstNodePersonId || (tree?.nodes || []).length > 1

  useEffect(() => {
    setPreviewImage(tree?.publish?.preview || null)
  }, [tree?.publish?.preview])

  useEffect(() => {
    if (
      visible &&
      activeTreeId &&
      previewImage?.id &&
      tree?.publish?.preview?.id !== previewImage.id
    ) {
      //@ts-ignore
      updateTree(activeTreeId, {publish: {preview_id: previewImage?.id}}).then((response) => {
        setPreviewImage(response?.data?.publish?.preview)
      })
    }
  }, [previewImage])

  const availableStatuses = useMemo(() => {
    return PUBLISH_STATUSES
  }, [])

  const shareTreeLink = useShareTreeLink()

  return (
    <div
      id='kt_tree_publish'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='addsim'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'350px', 'md': '525px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_tree_publish_toggle'
      data-kt-drawer-close='#kt_tree_publish_close'
    >
      {/* begin::Card */}
      <div className='card shadow-none rounded-0 w-100'>
        {/* begin::Header */}
        <div className='card-header' id='kt_help_header'>
          <h5 className='card-title fw-bold'>{intl.formatMessage({id: 'TREE.PUBLIC.SHARING'})}</h5>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='kt_tree_publish_close'
            >
              <KTIcon iconName='cross' className='fs-2' />
            </button>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div
          className='card-body hover-scroll-overlay-y h-400px pt-5'
          id='kt_tree_publish_body'
          ref={ref}
        >
          <ImageField
            initialValue={previewImage}
            defaultUrl={require('@assets/images/noimage.jpg')}
            labelId={'TREE.PUBLIC.PREVIEW'}
            targetSize={TREE_GALLERY_PREVIEW_IMAGE_TARGET_SIZES}
            aspectRatio={16 / 9}
            onChange={setPreviewImage}
          />

          <div className='card border-0 shadow-none rounded-0 w-100'>
            <div className='card-header p-0 mb-4'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-gray-900'>
                  {intl.formatMessage({id: 'TREE.PUBLIC.STATUS'})}
                </span>
              </h3>
            </div>

            <div className='card-body p-0'>
              <select
                value={publishStatus}
                className='form-select mb-5'
                onChange={(event) =>
                  setPublishStatus(event.currentTarget.value as PublishStatusEnum)
                }
              >
                {availableStatuses.map(({id, labelId}) => {
                  const disabled =
                    id === PublishStatusEnum.public && (!previewImage || !nonEmptyTree)
                  const disabledLabelId = !previewImage
                    ? 'GALLERY.TITLE_WITH_PREVIEW_ASKING'
                    : 'GALLERY.TITLE_WITH_NON_EMPTY_ASKING'

                  return (
                    <option key={id} value={id} disabled={disabled}>
                      {intl.formatMessage({
                        id: disabled ? disabledLabelId : labelId,
                      })}
                    </option>
                  )
                })}
              </select>
              <div className='d-flex align-items-stretch mb-5'>
                {treePublishToken &&
                tree?.publish?.status !== PublishStatusEnum.private &&
                shareTreeLink ? (
                  <CopyLink link={shareTreeLink} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/* end::Body */}
      </div>
      {/* end::Card */}
    </div>
  )
}

export default React.memo(TreePublishDrawer)
