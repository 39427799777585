import {create} from 'zustand'

type Person = {
  id?: number
  parents?: number[]
}

type ParentsHighlightStore = {
  person: Person | null
  activate: (person: Person) => void
  disactivate: () => void
}

const useParentsHighlightStore = create<ParentsHighlightStore>((set) => ({
  person: null,
  activate: (person: Person) => {
    set((state) => ({...state, person}))
  },
  disactivate: () => {
    set((state) => ({...state, person: null}))
  },
}))

export default useParentsHighlightStore
