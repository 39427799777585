import {useMemo} from 'react'

export const addHostPrefixToUrl = (url?: string): string | undefined => {
  if (!url || !process.env.REACT_APP_API_URL) return undefined
  if (!url.match(/^\/uploads\//)) return url

  const host = process.env.REACT_APP_API_URL?.replace(/\/api$/, '')
  return `${host}${url}`
}

const useHostBasedUrl = (url?: string): string | undefined => {
  return useMemo(() => addHostPrefixToUrl(url), [url])
}

export default useHostBasedUrl
