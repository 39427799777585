import {FC} from 'react'
import {HelpDrawer} from './help-drawer/HelpDrawer'
import {AddTreeDrawer} from './addtree-drawer/AddTreeDrawer'
import {ViewTreeDrawer} from './viewtree-drawer/ViewTreeDrawer'
import {AddSimDrawer} from './addsim-drawer/AddSimDrawer'
import {SimDrawer} from './sim-drawer/SimDrawer'

import {UserProfileDrawer} from './userprofile-drawer/UserProfileDrawer'

import {EditSimDrawer} from './editsim-drawer/EditSimDrawer'
import {EditTreeDrawer} from './edittree-drawer/EditTreeDrawer'
import {ViewPhotosDrawer} from './viewphotos-drawer/ViewPhotosDrawer'
import {EditPhotosDrawer} from './editphotos-drawer/EditPhotosDrawer'
import TreeCustomizationDrawer from './TreeCustomizationDrawer'
import TreePublishDrawer from '@_metronic/partials/layout/TreePublishDrawer'
import {EditArticleDrawer} from '@_metronic/partials/layout/editarticle-drawer/EditArticleDrawer'
import {ViewArticleDrawer} from '@_metronic/partials/layout/viewarticle-drawer/ViewArticleDrawer'
import ImagesGalleryModal from '@components/molecules/ImagesGalleryModal'
import {AddEditCultDrawer} from '@_metronic/partials/layout/addcult-drawer/AddEditCultDrawer'
import {CultDrawer} from '@_metronic/partials/layout/cult-drawer/CultDrawer'
import {AddEditRelationDrawer} from '@_metronic/partials/layout/addrelation-drawer/AddEditRelationDrawer'
import {RelationDrawer} from '@_metronic/partials/layout/relation-drawer/RelationDrawer'
import {AddEditTagDrawer} from "@_metronic/partials/layout/addtag-drawer/AddEditTagDrawer";
import {TagDrawer} from "@_metronic/partials/layout/tag-drawer/TagDrawer";

const RightToolbar: FC = () => {
  return (
    <>
      <HelpDrawer />
        <AddEditTagDrawer/>
        <TagDrawer/>
      <AddSimDrawer />
      <SimDrawer />
      <CultDrawer />
      <RelationDrawer />
      <EditSimDrawer />
      <AddTreeDrawer />
      <ViewTreeDrawer />
      <EditPhotosDrawer />
      <EditArticleDrawer />
      <ViewArticleDrawer />
      <ViewPhotosDrawer />
      <ImagesGalleryModal />
      <EditTreeDrawer />
      <UserProfileDrawer />
      <TreeCustomizationDrawer />
      <TreePublishDrawer />
      <AddEditCultDrawer />
      <AddEditRelationDrawer />
    </>
  )
}

export {RightToolbar}
