import React from 'react'
import styled from 'styled-components'

type LockProps = {}
const Lock = ({}: LockProps) => {
  return (
    <Wrapper>
      <i className='ki-solid ki-lock-2 fs-3' />
    </Wrapper>
  )
}

export default React.memo(Lock)

const Wrapper = styled.div`
  position: absolute;
  top: -30px;
  right: -30px;
  width: 80px;
  height: 80px;

  padding-right: 20px;
  padding-top: 20px;

  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.5);

  border-radius: 50%;

  opacity: 0.7;
`
