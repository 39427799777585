/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'

import {toAbsoluteUrl} from '../../../helpers'
import {useAuth} from '@modules/auth'

import Notification from '@components/atoms/Notification'
import {readAllNotifications} from '@api/requests'

const HeaderNotificationsMenu: FC = () => {
  const intl = useIntl()
  const {currentUser, setCurrentUser} = useAuth()

  const handleReadAllNotifications = () => {
    readAllNotifications().then(() => {
      const email = currentUser?.email!
      if (currentUser?.email) {
        setCurrentUser({...currentUser, notifications: []})
      }
    })
  }

  // @ts-ignore
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div className='d-flex flex-column bgi-no-repeat rounded-top bg-success'>
        <h3 className='text-white fw-bold px-9 mt-6 mb-6'>
          {intl.formatMessage({id: 'NOTIFICATIONS.NOTIFICATIONS'})}{' '}
          <span className='fs-8 opacity-75 ps-3'>{currentUser?.notifications?.length}</span>
        </h3>
      </div>

      <div className='scroll-y mh-325px my-5 px-8'>
        {currentUser?.notifications?.reverse().map((notification, index) => (
          <Notification key={notification.id} notification={notification} />
        ))}
      </div>

      <div className='py-3 text-center border-top'>
        <span
          className='btn btn-color-gray-600 btn-active-color-primary'
          onClick={handleReadAllNotifications}
        >
          {intl.formatMessage({id: 'NOTIFICATIONS.MARK_ALL_READ'})}
        </span>
      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}
