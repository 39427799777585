import {useCallback, useEffect, useState} from 'react'

const useHover = () => {
  const [hover, setHover] = useState(false)

  const onMouseOver = useCallback(() => {
    setHover(true)
  }, [])
  const onMouseLeave = () => {
    setHover(false)
  }

  const [startLongPress, setStartLongPress] = useState(false)

  useEffect(() => {
    setHover(false)
    let timerId
    if (startLongPress) {
      timerId = setTimeout(() => {
        setHover(true)
      }, 1000)
    } else {
      clearTimeout(timerId)
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [startLongPress])

  return {
    hover,
    onMouseOver,
    onMouseLeave,
    onTouchStart: () => setStartLongPress(true),
    onTouchEnd: () => setStartLongPress(false),
  }
}

export default useHover
