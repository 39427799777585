import React, {FC} from 'react'

import {PageTitle} from '@_metronic/layout/core'
import {LayoutBuilderPage} from './LayoutBuilderPage'

const LayoutBuilderPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Layout Builder</PageTitle>
      <LayoutBuilderPage />
    </>
  )
}

export default LayoutBuilderPageWrapper
