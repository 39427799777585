import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useIntl} from 'react-intl'

import {SubscriptionPlanType} from '../SubscriptionModal'
import {useLang} from '@_metronic/i18n/Metronici18n'

const RU_LOCALE = 'ru'
const EN_LOCALE = 'en'

type PlanProps = {plan: SubscriptionPlanType; checked: boolean; onClick: () => void}
const SubscriptionPlan = ({plan, checked, onClick}: PlanProps) => {
  const language = useLang()
  const isEnglishLanguage = language === EN_LOCALE
  const isRussianLanguage = language === RU_LOCALE
  const intl = useIntl()

  // if (checked) {
  //   console.log('### plan', plan)
  // }

  const nameLabel = useMemo(() => {
    if (plan.is_promo === 1) {
      return intl.formatMessage({
        id: 'SUB.TYPE.PROMOYEAR', //isEnglishLanguage ? 'SUB.TYPE.PROMOYEAR' : 'SUB.TYPE.LIFETIME',
      })
    }

    if (plan.duration === 1) {
      return `${plan.duration} ${intl.formatMessage({id: 'SUB.TYPE.MONTH'})}`
    }

    if (plan.duration > 1 && plan.duration < 5) {
      return `${plan.duration} ${intl.formatMessage({id: 'SUB.TYPE.MONTHS'})}`
    }

    if (plan.duration === 12) {
      return intl.formatMessage({id: 'SUB.TYPE.YEAR'})
    }

    return `${plan.duration} ${intl.formatMessage({id: 'SUB.TYPE.MONTHS'})}`
  }, [plan.duration, isRussianLanguage])

  const durationLabel = useMemo(() => {
    if (plan.duration === 1) {
      return `${intl.formatMessage({id: 'SUB.TYPE.MONTHLY'})}`
    }

    if (plan.duration === 6) {
      return `${intl.formatMessage({id: 'SUB.TYPE.SIXMONTHS'})}`
    }

    if (plan.duration === 12) {
      return `${intl.formatMessage({id: 'SUB.TYPE.YEARLY'})}`
    }

    if (plan.duration === 120) {
      return `${intl.formatMessage({id: isEnglishLanguage ? 'SUB.TYPE.YEARLY' : 'SUB.TYPE.ONCE'})}`
    }
  }, [plan.duration])

  const stipe = language !== 'ru'
  const currency = !stipe ? '₽' : '$'

  return (
    <label
      className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 ${
        checked ? 'active' : ''
      }  position-relative  mb-5 mt-2`}
      onClick={onClick}
    >
      {plan.is_best === 1 && (
        <span className='badge badge-light-success ms-2 fs-7 position-absolute top-0 start-50 translate-middle'>
          {intl.formatMessage({id: 'SUB.TYPE.POPULAR'})}
        </span>
      )}
      <div className='d-flex align-items-center me-2'>
        <div className='form-check form-check-custom form-check-solid form-check-primary me-6'>
          <Checkbox
            className='form-check-input'
            type='radio'
            name='plan'
            checked={checked}
            value={plan.id}
            disabled
          />
        </div>
        <div className='flex-grow-1'>
          <h2 className='d-flex align-items-center fs-3 fw-bold flex-wrap'>{nameLabel}</h2>
          <div className='fw-semibold opacity-50'>
            {plan.discount && <span className='badge badge-primary'>- {plan.discount}%</span>}{' '}
            {plan.price}
            {currency} {intl.formatMessage({id: 'SUB.TYPE.CHARGED'})} {durationLabel}
          </div>
        </div>
      </div>
    </label>
  )
}

export default React.memo(SubscriptionPlan)

const Checkbox = styled.input`
  opacity: 1 !important;
`
