import React from 'react'
import styled from 'styled-components'

import useTreeStyles from '@hooks/useTreeStyles'

type PartnersCarouselControlsProps = {
  size: number
  treeId: number
  partnersCount: number
  onOffsetChange: (direction: -1 | 1) => void
}
const PartnersCarouselControls = ({
  size,
  treeId,
  partnersCount,
  onOffsetChange,
}: PartnersCarouselControlsProps) => {
  const {lineColor} = useTreeStyles(treeId)

  const onUpPageClick = () => onOffsetChange(1)
  const onDownPageClick = () => onOffsetChange(-1)

  return (
    <Wrapper $size={size}>
      <ArrowButton
        $up={true}
        type='button'
        className='btn btn-sm btn-icon'
        color={lineColor}
        onClick={onUpPageClick}
      >
        <i className='ki-duotone ki-arrow-up'>
          <span className='path1' />
        </i>
      </ArrowButton>

      <PartnersCount color={lineColor}>{partnersCount}</PartnersCount>

      <ArrowButton
        type='button'
        className='btn btn-sm btn-icon'
        color={lineColor}
        onClick={onDownPageClick}
      >
        <i className='ki-duotone ki-arrow-up'>
          <span className='path1' />
        </i>
      </ArrowButton>
    </Wrapper>
  )
}

export default React.memo(PartnersCarouselControls)

const Wrapper = styled.div<{$size: number}>`
  display: flex;
  flex-direction: column;

  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;

  justify-content: center;
  align-items: center;

  height: 100%;
  padding-left: ${({$size}) => $size * 1 + 10}px;
  width: 10px;
`

const PartnersCount = styled.span<{color?: string | number}>`
  font-size: 12px;
  font-weight: bold;

  color: ${({color}) => color || 'white'};

  height: 10px;
`

const ArrowButton = styled.button<{color?: string | number; $up?: boolean}>`
  cursor: pointer;

  width: 30px !important;
  height: 30px !important;
  border-radius: 5px;

  margin-bottom: -5px;
  transform: rotate(${({$up}) => ($up ? 0 : 180)}deg);

  i {
    color: ${({color}) => color || 'white'};
    font-size: 20px;
  }

  :active {
    opacity: 0.8;
  }
`
