import {useMemo} from 'react'
import {v4 as uuid} from 'uuid'
import {RelationsEnum} from '../constants'

const usePrepareDataForTree = (rawData, rawPersons) => {
  return useMemo(() => {
    // console.log('### usePrepareDataForTree', rawPersons)
    const rawPersonsMap = rawPersons.reduce((res, person) => {
      res[person.id] = person
      return res
    }, {})
    // console.log('### rawPersonsMap', rawPersonsMap[3906])
    const childrenMap = rawData.reduce((res, person) => {
      res[person.parent_id] = [...(res[person.parent_id] || []), person.id]

      return res
    }, {})

    const data = rawData.map((node) => {
      const person = rawPersonsMap[node.person_id]

      const partners = (person?.partners || [])
        .map(({partner_id, status}) => {
          const partnerData = rawPersonsMap[partner_id]

          if (!partnerData) return false

          return {
            id: partnerData.id,
            // person_id: partnerData.id,
            status,
            gender: partnerData?.gender,
            avatar: partnerData?.avatar?.url,
            name: [partnerData?.first_name, partnerData?.last_name]
              .filter((name) => name)
              .join(' '),
            parents: partnerData?.parents,
            pets: partnerData?.pets,
            is_alive: partnerData?.is_alive,
            first_name: partnerData?.first_name,
            last_name: partnerData?.last_name,
            look_id: partnerData?.look_id,
          }
        })
        .filter((data) => !!data)

      const pets = (person?.pets || [])
        .map((id) => {
          const petData = rawPersonsMap[id]

          if (!petData) return false

          return {
            id: petData.id,
            gender: petData?.gender,
            avatar: petData?.avatar?.url,
            name: [petData?.first_name, petData?.last_name].filter((name) => name).join(' '),
          }
        })
        .filter((data) => !!data)

      return {
        ...node,
        avatar: person?.avatar?.url,
        person_id: person && node.person_id,
        children: childrenMap[node.id],
        partners: partners,
        name: [person?.first_name, person?.last_name].filter((name) => name).join(' '),
        first_name: person?.first_name,
        last_name: person?.last_name,
        parents: person?.parents,
        pets: pets,
        is_alive: person?.is_alive,
        look_id: person?.look_id,
      }
    })

    // console.log('### data', data)

    const spousesMap = {}

    // virtual parents because children is not displayed if there are no two parents
    const virtualPartnersToAdd = data.reduce((res, person) => {
      const virtualPersonId = uuid()

      // @ts-ignore
      spousesMap[virtualPersonId] = [
        {
          id: person.id,
          type: 'married',
        },
      ]

      spousesMap[person.id] = [
        {
          id: virtualPersonId,
          type: 'married',
        },
      ]

      if (!person.partners?.length) return res

      // console.log('### person', person)
      // console.log(person)
      return [
        ...res,
        {
          id: virtualPersonId,
          name: 'virtual',
          gender: 'female',
          partners: [person],
          parents: [],
          children: person.children || [],
          ownerId: person.id,
          virtual: true,
        },
      ]
    }, [])

    const persons = [...data, ...virtualPartnersToAdd]
    // console.log('### usePrepareDataForTree.persons', persons)

    const childrenToParentIds = persons.reduce((res, person) => {
      if (person.children) {
        person.children.forEach((id) => {
          res[id] = [...(res[id] ? res[id] : []), person.id]
        })
      }

      return res
    }, {})

    const personsMap = persons.reduce((res, person) => {
      // console.log('### person.partners', person.partners)
      const partners = person.partners.map((partner, index) => ({
        id: `${person.id}_${index}`,
        person_id: partner.person_id && rawPersonsMap[partner.person_id],
        ...partner,
      }))

      res[person['id']] = {
        ...person,
        partners:
          partners.map((partner) => ({
            ...partner,
            status: partner.status || RelationsEnum.partner,
          })) || [],
      }

      return res
    }, {})

    const nodes = persons.map((person) => {
      return {
        id: person.id,
        gender: person.virtual ? 'female' : 'male',
        siblings: [],
        spouses: spousesMap[person.id] || [],
        parents:
          childrenToParentIds[person.id]?.map((id) => ({
            id,
            type: 'blood',
          })) || [],
        children:
          person.children?.map((id) => ({
            id,
            type: 'blood',
          })) || [],
        ownerId: person.ownerId,
        avatar: person.avatar,
        virtual: person.virtual,
      }
    })

    return {nodes, personsMap}
  }, [rawData, rawPersons])
}

export default usePrepareDataForTree
