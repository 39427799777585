/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'

import {uploadImage} from '@api/requests'
import {usePhotosStore} from '@store/photos'

import ReactQuillEditor from '@components/molecules/ReactQuillEditor'
import CustomFields from '@components/molecules/CustomFields'

import {AVATAR_IMAGE_TARGET_SIZES} from '@constants/imageUploading'

type Values = {name: string; description: string}

type AddTreeDrawerContent = {
  values?: Values
  onValuesChange: (values: Values) => void
}
const AddTreeDrawerContent = ({values, onValuesChange}) => {
  const intl = useIntl()

  const butOpenEditorRef = useRef<any>()
  const {selectPhoto, callback} = usePhotosStore()

  const [name, setName] = useState<Values['name']>('')
  const [avatarId, setAvatarId] = useState<number | null>()
  const [avatar, setAvatar] = useState<object | null>()
  const [selectedBack, setSelectedBack] = useState(0)
  const [customsFields, setCustomFields] = useState<any>([])
  const [persistFields, setPersistFields] = useState<any>({})

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const [description, setDescription] = useState<Values['description']>('')
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }
  const callBackForCrop = (file) => {
    let reader = new FileReader()
    reader.onload = function (e) {
      if (e?.target?.result) {
        uploadImage(file, 1).then((r) => {
          setAvatarId(r.data.id)
        })
      }
      // @ts-ignore
      setAvatar(e.target.result)
    }
    reader.readAsDataURL(file)
  }

  function handleFiles(event) {
    let file = event.currentTarget.files[0]
    selectPhoto(file, callBackForCrop, 1, AVATAR_IMAGE_TARGET_SIZES)

    event.currentTarget.value = ''

    butOpenEditorRef.current?.click()
  }

  useEffect(() => {
    onValuesChange({
      name,
      description,
      avatar_id: avatarId,
      background_id: selectedBack,
      params: {fields: customsFields || [], persist: persistFields || {}},
    })
  }, [name, description, avatarId, selectedBack, customsFields, persistFields])

  useEffect(() => {
    if (!values) {
      setName('')
      setDescription('')
      setAvatar(null)
      setAvatarId(null)
      setCustomFields([])
      setPersistFields({})
    }
  }, [values])

  return (
    <div className='card-body hover-scroll-overlay-y h-400px pt-5' id='kt_tree_body'>
      <button
        ref={butOpenEditorRef}
        style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_edit_photo'
      />
      {/* begin::Content */}
      <div className='row mb-6'>
        <label className='col-lg-12 col-form-label fw-semibold fs-6'>
          {intl.formatMessage({id: 'TREE.ADD.PHOTO'})}
        </label>
        <div className='col-lg-12'>
          <div className='image-input image-input-outline' data-kt-image-input='true'>
            <div
              style={{
                backgroundImage: avatar
                  ? `url('${avatar}')`
                  : 'url("https://my-engine.ru/modules/users/avatar.png")',
              }}
              className='image-input-wrapper w-125px h-125px'
            />
            <label
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              title='Change avatar'
            >
              <i className='ki-duotone ki-pencil fs-7'>
                <span className='path1' />
                <span className='path2' />
              </i>
              <input onChange={handleFiles} type='file' name='avatar' accept='.png, .jpg, .jpeg' />
              <input type='hidden' name='avatar_remove' />
            </label>
            <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='ki-duotone ki-cross fs-2'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </span>
          </div>
          <div className='form-text'>{intl.formatMessage({id: 'TREE.ADD.PHOTO.FORMAT'})}</div>
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-12 col-form-label required fw-semibold fs-6'>
          {intl.formatMessage({id: 'TREE.ADD.TITLE'})}
        </label>
        <div className='col-lg-12'>
          <input
            type='text'
            name='fname'
            className='form-control form-control-lg mb-3 mb-lg-0'
            placeholder=''
            value={name}
            onChange={handleNameChange}
          />
        </div>
      </div>
      <div className='row mb-6'>
        <label className='col-lg-12 col-form-label fw-semibold fs-6'>
          {intl.formatMessage({id: 'INFORMATION'})}
        </label>
        <div className='col-lg-12 fv-row'>
          <ReactQuillEditor initValue={description} setValue={setDescription} />
        </div>
      </div>
      <CustomFields values={customsFields} onChange={setCustomFields} />
      {/* end::Content */}
    </div>
  )
}

export {AddTreeDrawerContent}
