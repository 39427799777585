import React, {useState} from 'react'
import styled from 'styled-components'
import {useIntl} from 'react-intl'

import {sendImportJSsFiles} from '@api/requests'

type JSImportFormProps = {onComplete: () => void; onClose: () => void}
const JSImportForm = ({onComplete, onClose}: JSImportFormProps) => {
  const intl = useIntl()

  const [formValues, setFormValues] = useState()
  const [validFileNames, setValidFileNames] = useState(false)
  const handleFormChange = (e) => {
    const files = Array.from(e.target.files)
    // @ts-ignore
    const names = files.map(({name}) => name)
    setValidFileNames(names.includes('tree.js') && names.includes('people.js'))

    if (files) {
      // @ts-ignore
      setFormValues(files)
    }
  }

  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    setError(null)
    setLoading(true)
    sendImportJSsFiles(formValues)
      .then((response) => {
        onComplete()
      })
      .catch((error) => {
        console.log('### error', error)
        setError(error?.message)
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <div className='modal-body'>
        <form onChange={handleFormChange}>
          <h3>{intl.formatMessage({id: 'STEP.ONE'})}</h3>
          <p className='required form-label'>{intl.formatMessage({id: 'TREE.IMPORT.FILES'})}:</p>
          <input
            className='form-control form-control-lg mb-3 mb-lg-0'
            type='file'
            name='people'
            accept='text/javascript'
            multiple
          />
        </form>
        {formValues && !validFileNames ? (
          <Error>{intl.formatMessage({id: 'TREE.IMPORT.WRONG_FILES'})}</Error>
        ) : null}
        {error ? <Error>{error}</Error> : null}
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-light-primary' onClick={onClose}>
          {intl.formatMessage({id: 'ACTIONS.CANCEL'})}
        </button>

        <button
          id='submit'
          type='button'
          className='btn btn-primary'
          data-kt-indicator='on'
          disabled={!formValues || !validFileNames || loading}
          onClick={handleSubmit}
        >
          {loading ? (
            <span className='indicator-progress'>
              {intl.formatMessage({id: 'MSG.UPLOADING'})}...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            intl.formatMessage({id: 'ACTIONS.NEXT'})
          )}
        </button>
      </div>
    </>
  )
}

export default React.memo(JSImportForm)

const Error = styled.div`
  color: crimson;
  width: 100%;
  text-align: right;

  margin-top: 5px;
`
