import React from 'react'
import styled from 'styled-components'

import Lock from '@components/atoms/Lock'

import {BackgroundPackItemType} from '@components/molecules/StylingBackgrounds/types'

type BackgroundProps = {
  disabled: boolean
  selected: boolean
  background: BackgroundPackItemType
  onClick: () => void
}
const Background = ({disabled, selected, background, onClick}: BackgroundProps) => {
  return (
    <Wrapper className='col-4' $disabled={disabled} onClick={onClick}>
      <label className='form-check-image form-check-success'>
        <BackgroundImageWrapper className='form-check-wrapper border-gray-200 border-2'>
          <BackgroundImage
            className='form-check-rounded mw-100'
            src={background?.resource?.url}
            alt=''
          />
          {disabled ? <Lock /> : null}
        </BackgroundImageWrapper>
        <div className='form-check form-check-custom form-check-success form-check-sm form-check-solid'>
          <input
            className='form-check-input'
            type='radio'
            checked={selected}
            readOnly
            value='dark-sidebar'
            name='layout-builder[layout][app][general][layout]'
          />

          <div className='form-check-label text-gray-700'>{background.name}</div>
        </div>
      </label>
    </Wrapper>
  )
}

export default React.memo(Background)

const Wrapper = styled.div<{$disabled: boolean}>`
  cursor: ${({$disabled}) => ($disabled ? 'none' : 'pointer')};

  user-select: none;
`

const BackgroundImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
`

const BackgroundImage = styled.img`
  height: 100px;
`
