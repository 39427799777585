/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'

import useOnScreen from '@hooks/useOnScreen'

const AddEditTagDrawerContent = ({initialValues, onValuesChange}) => {
  const intl = useIntl()
  const butOpenEditorRef = useRef<any>()
  const [name, setName] = useState(initialValues?.name ?? '')

  const addSimDrawerRef = useRef(null)
  const visible = useOnScreen(addSimDrawerRef)

  useEffect(() => {
    if (!visible) {
      setName(initialValues?.name ?? '')
    }
  }, [visible, initialValues])

  useEffect(() => {
    onValuesChange({
      name,
    })
  }, [name])

  return (
    <div
      className='card-body hover-scroll-overlay-y h-400px pt-5'
      id='kt_help_body'
      ref={addSimDrawerRef}
    >
      {/* @ts-ignore */}
      <button
        ref={butOpenEditorRef}
        style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_edit_photo'
      />

      <div className='mb-10'>
        <label className='required form-label'>{intl.formatMessage({id: 'ADD.FIELD.NAME'})}</label>
        <input
          type='text'
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          className='form-control'
          placeholder=''
          maxLength={32}
        />
        <span className='form-text d-block mt-2.5'>
          {intl.formatMessage({id: 'TAG.STRING_LENGTH'})}
        </span>
      </div>

      {/* end::Content */}
    </div>
  )
}

export {AddEditTagDrawerContent}
