import React, {useEffect, useRef} from 'react'
import {create} from 'pinch-zoom-pan'
import styled from 'styled-components'

const PinchZoomPan = ({min, initialZoom, max = 1, captureWheel, className, style, children}) => {
  const root = useRef(null)

  useEffect(() => {
    const element = root.current

    if (!element || !min) return

    let canvas = null

    canvas = create({
      element,
      minZoom: min,
      maxZoom: max,
      captureWheel,
    })

    canvas.update({z: initialZoom > max ? max : initialZoom})

    return () => {
      canvas?.destroy()
    }
  }, [min, max, captureWheel])

  const paintRef = useRef(null)

  return (
    <Wrapper ref={root} className={className} style={style}>
      <Paint ref={paintRef}>
        <Canvas>{children}</Canvas>
      </Paint>
    </Wrapper>
  )
}

export default React.memo(PinchZoomPan)

const Wrapper = styled.div`
  position: relative;
  transform: translateZ(0);

  overflow: hidden;
`

const Paint = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  transform: translate(0, 0) scale(1);
  transform-origin: center;
`

const Canvas = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
`
