export const AUTH_LINK = 'https://app.thesimstree.com/auth'
export const REGISTRATION_LINK = 'https://app.thesimstree.com/auth/registration'

export const RU_OFFER_LINK = 'https://thesimstree.com/ru/publichnaya-oferta-sajta.html'
export const EN_OFFER_LINK = 'https://thesimstree.com/en/public-offer.html'

export const RU_POLICY_LINK = 'https://thesimstree.com/ru/politika-konfidenczialnosti.html'
export const EN_POLICY_LINK = 'https://thesimstree.com/en/confidentioal.html'

export const RU_RULES_LINK = 'https://thesimstree.com/ru/pravila.html'
