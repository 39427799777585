import {useEffect, useState} from 'react'
import {useAuth} from '@modules/auth'

const USER_LOCATION_STORAGE_KEY = '@user-location'
const USER_COUNTRY_CODE_STORAGE_KEY = '@user-country-code'

const getCountryCode = () =>
  fetch(
    'https://xgeo.wildx.ru/&apikey=01f59e435d62d40861db041e7b1cd43d787830a504cc8346b5328beff3e0cc46'
  )
    .then((response) => {
      // console.log(response)
      return response.json()
    })
    .then((response) => {
      // console.log(response)
      return response.country_info?.iso_code_2 || 'unknown'
    })

const useCountryCheck = () => {
  const {currentUser} = useAuth()

  const [storedCountryCode, setStoredCountryCode] = useState(
    localStorage.getItem(USER_COUNTRY_CODE_STORAGE_KEY)
  )

  useEffect(() => {
    const storedUserLocation = localStorage.getItem(USER_LOCATION_STORAGE_KEY)

    if (currentUser?.location && storedUserLocation !== currentUser?.location) {
      localStorage.setItem(USER_LOCATION_STORAGE_KEY, currentUser.email)

      localStorage.setItem(USER_COUNTRY_CODE_STORAGE_KEY, currentUser?.location)
      setStoredCountryCode(currentUser?.location)
    }
  }, [currentUser?.email])

  return storedCountryCode
}

export const useIsNonRUCountryCheck = () => {
  const countryCode = useCountryCheck()

  return countryCode && countryCode !== 'ru'
}

export default useCountryCheck
