import React from 'react'
import ContentLoader from 'react-content-loader'

const ParamSkeleton = () => {
  return (
    <ContentLoader viewBox='0 0 380 38' backgroundColor='#ccc' foregroundColor='#fff' speed={2}>
      <rect x='0' y='0' rx='3' ry='3' width='40' height='13' />
      <rect x='130' y='0' rx='3' ry='3' width='100' height='13' />
    </ContentLoader>
  )
}

export default ParamSkeleton
