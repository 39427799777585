import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import styled from 'styled-components'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import useHostBasedUrl from '@hooks/useHostBasedUrl'

import {Cult, useCultsStore} from '@pages/CultsPage/store'
import {Tag, useTagsStore} from "@pages/TagsPage/store";

const TagDrawerContent = ({tagId, readonly = false}) => {
    const intl = useIntl()
    const butOpenEditorRef = useRef<any>()

    const {tags} = useTagsStore()

    const [loading, setLoading] = useState(false)

    const [tag, setTag] = useState<Tag | null>(null)

    useEffect(() => {
        const foundTag = tags?.find(({id}) => id === tagId)
        console.log('foundTag', foundTag,tagId)
        if (foundTag) {
            setTag(foundTag)
        }
    }, [tagId, tags])

    useEffect(() => {
        if (!tagId) {
            setTag(null)
        }
    }, [tagId])


    return (
        <div className='pt-5' style={{overflowX: 'hidden'}}>
            <button
                ref={butOpenEditorRef}
                style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_edit_photo'
            />
            <div className='d-flex flex-column text-center mb-9 px-9'>
                <div className='text-center'>
                    <span className='text-gray-800 fw-bold text-hover-primary fs-1'>{tag && tag.name}</span>
                </div>
            </div>


        </div>
    )
}

export {TagDrawerContent}

const Description = styled.div`
  & blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }
`
