import React, {CSSProperties, useMemo, useState} from 'react'
import ItemsCarousel from 'react-items-carousel'
import styled from 'styled-components'

import Photo from './components/Photo'
import EditPhotoDescription from './components/EditPhotoDescription'

type PhotoType = {id: number; url: string; description?: string}

const defaultStyle: CSSProperties = {
  width: '100%',
  height: 150,
  borderRadius: 12,
  cursor: 'pointer',
  objectFit: 'cover',
}
type CustomGalleryProps = {
  photos: PhotoType[]
  photoStyle?: CSSProperties
  currentPhoto?: string
  readonly?: boolean
  onImageUpdate: (image: PhotoType) => void
}
const CustomGallery = ({
  photos: rawPhotos,
  photoStyle = defaultStyle,
  currentPhoto,
  readonly = false,
  onImageUpdate,
}: CustomGalleryProps) => {
  console.log('### rawPhotos', rawPhotos)
  const [deletedPhotoIds, setDeletedPhotoIds] = useState<number[]>([])
  const handlePhotoDelete = (photoId: number) =>
    setDeletedPhotoIds((state) => [...(state || []), photoId])

  const [activeSlide, setActiveSlide] = useState(0)

  const photos = useMemo(
    () => rawPhotos.filter((photo) => !deletedPhotoIds.includes(photo.id)),
    [deletedPhotoIds, rawPhotos]
  )
  console.log('### photos', photos)

  // const {open} = useImagesGalleryModalStore()

  const [editImage, setEditImage] = useState<PhotoType | null>(null)
  const handleSuccesfullUpdate = (data: PhotoType) => {
    setEditImage(null)
    onImageUpdate(data)
  }

  return (
    <Wrapper className='card-body p-5'>
      <CarouselWrapper $disabled={!!editImage}>
        <ItemsCarousel
          // Placeholder configurations
          // infiniteLoop
          // enablePlaceholder
          // numberOfPlaceholderItems={5}
          minimumPlaceholderTime={1000}
          // placeholderItem={<Placeholder>Placeholder</Placeholder>}
          // Carousel configurations
          numberOfCards={2}
          gutter={12}
          showSlither={false}
          firstAndLastGutter={false}
          freeScrolling={false}
          // Active item configurations
          activePosition={'center'}
          chevronWidth={50}
          activeItemIndex={activeSlide}
          alwaysShowChevrons
          requestToChangeActive={(value) => setActiveSlide(value)}
          outsideChevron
          rightChevron={<LeftChevron className='ki-duotone ki-right fs-3x' />}
          leftChevron={<RightChevron className='ki-duotone ki-left fs-3x' />}
        >
          {photos?.map((photo) => (
            // @ts-ignore
            <Photo
              key={photo.id}
              //@ts-ignore
              style={photoStyle}
              readonly={readonly || currentPhoto === photo.url}
              photo={photo}
              onDelete={handlePhotoDelete}
              /*onClick={() => {
                open({images: photos, startIndex: index})
              }}*/
              onEditClick={readonly ? undefined : () => setEditImage(photo)}
            />
          ))}
        </ItemsCarousel>
      </CarouselWrapper>
      {editImage ? (
        <EditPhotoDescription
          image={editImage}
          onSuccess={handleSuccesfullUpdate}
          onCancelClick={() => setEditImage(null)}
        />
      ) : null}
    </Wrapper>
  )
}
export default CustomGallery

const Wrapper = styled.div`
  position: relative;
`

const CarouselWrapper = styled.div<{$disabled?: boolean}>`
  opacity: ${({$disabled}) => ($disabled ? 0.01 : 1)};
  pointer-events: ${({$disabled}) => ($disabled ? 'none' : 'auto')}; ;
`

const Placeholder = styled.div`
  height: 200px;
  background-color: #900;
`

const LeftChevron = styled.i`
  position: absolute;
  left: -12px;
`

const RightChevron = styled.i`
  position: absolute;
  right: -12px;
`
