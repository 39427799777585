import {useTreesStore} from '@store/trees'

const useHeirChainFromRoot = (treeId: number): number[] => {
  const {trees} = useTreesStore()
  const {nodes, params} = trees[treeId]
  const heirNodeId = params?.heirNodeId

  const heirChainNodes: number[] = []

  let currentNode = nodes.find(({id}) => id === heirNodeId)
  let rootNodeReached = false

  if (currentNode) {
    heirChainNodes.push(currentNode.id)

    while (!rootNodeReached) {
      const parent_id = currentNode?.parent_id
      currentNode = nodes.find(({id}) => id === parent_id)

      if (!currentNode) {
        rootNodeReached = true
      } else {
        heirChainNodes.push(currentNode.id)
      }
    }
  }

  return heirChainNodes
}

export default useHeirChainFromRoot
