/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef, useState} from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useIntl} from 'react-intl'

import {useTreesStore} from '@store/trees'

import {AddTreeDrawerContent} from './AddTreeDrawerContent'
import {KTIcon} from '../../../helpers'
import {useSubscriptionModalStore} from '@components/molecules/SubscriptionModal/SubscriptionModal'

const MySwal = withReactContent(Swal)

const AddTreeDrawer = () => {
  const closeButtonRef = useRef<HTMLButtonElement>(null)
  const {addNewTree} = useTreesStore()
  const {show: showSubscriptionModal} = useSubscriptionModalStore()
  const [newTreeValues, setNewTreeValues] = useState<{
    name: string
    description: string
    avatar_id: number
    background_id: number
    params: object
  }>()
  const handleValuesChange = (data) => {
    setNewTreeValues(data)
  }
  const intl = useIntl()

  const handleSaveClick = () => {
    if (!newTreeValues) return

    addNewTree(newTreeValues)
      .then(() => {
        closeButtonRef.current?.click()
        MySwal.fire({
          position: 'center',
          icon: 'success',
          title: intl.formatMessage({id: 'MSG.TREE.ADDED'}),
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false,
        }).then(() => {
          setNewTreeValues(undefined)
        })
      })
      .catch((error) => {
        console.log('### error', error)
        if (error.response.status === 402) {
          showSubscriptionModal()
        }
      })
  }

  return (
    <div
      id='kt_addtree'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='addtree'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'350px', 'md': '525px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_addtree_toggle'
      data-kt-drawer-close='#kt_addtree_close'
    >
      {/* begin::Card */}
      <div className='card shadow-none rounded-0 w-100'>
        {/* begin::Header */}
        <div className='card-header' id='kt_help_header'>
          <h5 className='card-title fw-bold'>{intl.formatMessage({id: 'TREE.ADD.NAME'})}</h5>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='kt_addtree_close'
            >
              <KTIcon iconName='cross' className='fs-2' />
            </button>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <AddTreeDrawerContent values={newTreeValues} onValuesChange={handleValuesChange} />
        {/* end::Body */}
        {/* begin::Footer */}
        <div className='card-footer py-5 text-center' id='kt_activities_footer'>
          <div className='d-flex justify-content-end'>
            <button
              className='btn btn-secondary d-flex justify-content-end me-3'
              id='kt_addtree_close'
              ref={closeButtonRef}
            >
              {intl.formatMessage({id: 'TREE.ADD.CLOSE'})}
            </button>
            <button
              className='btn btn-success d-flex justify-content-end'
              disabled={!newTreeValues?.name}
              onClick={handleSaveClick}
            >
              {intl.formatMessage({id: 'TREE.ADD.SAVE'})}
            </button>
          </div>
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Card */}
    </div>
  )
}

export {AddTreeDrawer}
