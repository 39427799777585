import React from 'react'
import ContentLoader from 'react-content-loader'

const CultSkeleton = () => {
  return (
    <ContentLoader viewBox='0 0 380 38' backgroundColor='#ccc' foregroundColor='#fff' speed={2}>
      <rect x='0' y='11' rx='3' ry='3' width='40' height='13' />
      <circle cx='145' cy='17.5' r='12' />
      <rect x='166' y='11' rx='3' ry='3' width='60' height='13' />
    </ContentLoader>
  )
}

export default CultSkeleton
