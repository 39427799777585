import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/profile`
export const LOGIN_URL = `${API_URL}/user/login`
export const REGISTER_URL = `${API_URL}/user/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/user/reset-password`
export const VERIFY_EMAIL_URL = `${API_URL}/user/token`
export const RECOVER_PASSWORD_URL = `${API_URL}/user/token?token=`
export const GOOGLE_AUTH_URL = `${API_URL}/user/social-auth?id=2`
export const SOCIAL_CALLBACK_URL = `${API_URL}/user/social-callback`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export type RegistrationBody = {
  email: string
  first_name: string
  last_name: string
  password: string
  ref_token?: string
}
export function register(data: RegistrationBody) {
  return axios.post(REGISTER_URL, data)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    value: email,
  })
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function verifyEmail(token: string) {
  return axios.get<AuthModel>(VERIFY_EMAIL_URL, {
    params: {token},
  })
}

export function recoverPassword(password: string, token: string) {
  return axios.post(`${RECOVER_PASSWORD_URL}${token}`, {
    value: password,
  })
}

export function googleRedirect(ref?: string) {
  return axios.get(GOOGLE_AUTH_URL, {
    params: {
      ref_token: ref,
    },
  })
}

export function googleAuth(url: string) {
  return axios.get(`${SOCIAL_CALLBACK_URL}${url}`)
}
