import {useMemo} from 'react'

const RUSSIAN_LOCALE_NAME = 'ru-RU'

export const browserHasRuLocale = () => navigator.language === RUSSIAN_LOCALE_NAME

const useIsRuLocale = () => {
  return useMemo(() => browserHasRuLocale(), [])
}

export default useIsRuLocale
