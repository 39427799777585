import type { CSSProperties } from "react";
import type { ExtNode } from "dehimer-relatives-tree/lib/types";

import { NODE_HEIGHT, NODE_WIDTH } from "./constants";

export function getNodeStyle({
  left,
  top,
  id,
}: Readonly<ExtNode>): CSSProperties {
  const width = NODE_WIDTH;
  const height = NODE_HEIGHT;

  return {
    width: width,
    height: height,
    transform: `translate(${left * width + width / 2}px, ${
      top * height + height / 2
    }px)`,
  };
}
