import {usePersonsStore} from '@store/persons'
import usePrepareDataForTree from '@components/organisms/Tree/hooks/usePrepareDataForTree'
import useFirstNodeId from '@components/organisms/Tree/hooks/useFirstNodeId'

import {TreeModel} from '@api/models'

const useTreePublishRootNodePerson = (tree: TreeModel | null) => {
  const {persons} = usePersonsStore()
  const {nodes, personsMap} = usePrepareDataForTree(tree?.nodes || [], persons)
  const firstNodeId = useFirstNodeId(nodes)
  const rootNodePerson = personsMap[firstNodeId]?.person_id

  return rootNodePerson
}

export default useTreePublishRootNodePerson
