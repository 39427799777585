import {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const PREMIUM_STATE_STORAGE_KEY = '@premium-state-storage-key'

enum PremiumStates {
  unknown = 'unknown',
  free = 'free',
  paid = 'paid',
}

const usePremiumChange = () => {
  const [status, setStatus] = useState<PremiumStates>(() => {
    const storageValue = localStorage.getItem(PREMIUM_STATE_STORAGE_KEY)
    if (storageValue) {
      return storageValue === PremiumStates.paid ? PremiumStates.paid : PremiumStates.free
    }

    return PremiumStates.unknown
  })
  const updatePremium = (value: boolean) => {
    const newValue = value ? PremiumStates.paid : PremiumStates.free
    if (newValue === PremiumStates.paid && status === PremiumStates.free) {
      MySwal.fire({
        position: 'center',
        icon: 'success',
        title: 'The subscription is completed!',
        showConfirmButton: false,
        timer: 2500,
        heightAuto: false,
      })
    }

    setStatus(value ? PremiumStates.paid : PremiumStates.free)
  }

  useEffect(() => {
    localStorage.setItem(PREMIUM_STATE_STORAGE_KEY, status)
  }, [status])

  return updatePremium
}

export default usePremiumChange
