/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useState} from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useIntl} from 'react-intl'
import {create} from 'zustand'
import styled from 'styled-components'

import {Cult, useCultsStore} from '@pages/CultsPage/store'

import {AvatarModel} from '@api/models'

import {AddEditTagDrawerContent} from './AddEditTagDrawerContent'

import {KTIcon} from '../../../helpers'
import {Tag, useTagsStore} from "@pages/TagsPage/store";

const MySwal = withReactContent(Swal)

type AddEditTagDrawerStore = {
    opened: boolean
    tagId?: number
    open: (tagId?: number) => void
    close: () => void
}

export const useAddEditTagDrawerStore = create<AddEditTagDrawerStore>((set) => ({
    opened: false,
    tagId: undefined,
    open: (tagId) => set((state) => ({...state, opened: true, tagId})),
    close: () => set((state) => ({...state, opened: false, tagId: undefined})),
}))

const AddEditTagDrawer = () => {
    const intl = useIntl()
    const {tags, createTag, updateTag} = useTagsStore()

    const {opened, close, tagId} = useAddEditTagDrawerStore()

    const tag = useMemo(() => tags?.find(({id}) => id === tagId), [tagId, tags])
    // console.log('### AddEditCultDrawer.cult', cult)

    const [newTagValues, setNewTagValues] = useState<Tag | null>(null)

    const valid = newTagValues?.name?.trim();

    useEffect(() => {
        if (opened) {
            if (tag) {
                //setAvatar(tag.file ?? null)
                setNewTagValues(tag)
            }
        } else {
            //setAvatar(null)
            setNewTagValues(null)
        }
    }, [opened, tag])

    if (!opened) return null

    const handleValuesChange = (data) => {
        setNewTagValues(data)
    }
    const handleSubmitClick = () => {
        if (!setNewTagValues) return

        close()

        if (tag) {
            updateTag(tag.id!, {...newTagValues!})
        } else {
            createTag({...newTagValues!})
        }

        MySwal.fire({
            position: 'center',
            icon: 'success',
            title: intl.formatMessage({id: tag ? 'HANDBOOKS.TAGS.MSG.EDITED' : 'HANDBOOKS.TAGS.MSG.ADDED'}),
            showConfirmButton: false,
            timer: 1500,
            heightAuto: false,
        })
        setNewTagValues(null)
    }

    return (
        <>
            {opened ? <Overlay onClick={close} /> : null}
            <Wrapper className='bg-body' $hidden={!opened}>
                {/* begin::Card */}
                <div className='card shadow-none rounded-0 w-100'>
                    {/* begin::Header */}
                    <div className='card-header' id='kt_help_header'>
                        <h5 className='card-title fw-bold'>
                            {intl.formatMessage({id: tag ? 'HANDBOOKS.TAGS.EDIT' : 'HANDBOOKS.TAGS.ADD'})}
                        </h5>

                        <div className='card-toolbar'>
                            <button
                                type='button'
                                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                                onClick={close}
                            >
                                <KTIcon iconName='cross' className='fs-2' />
                            </button>
                        </div>
                    </div>
                    {/* end::Header */}

                    {/* begin::Body */}
                    <AddEditTagDrawerContent
                        initialValues={tag}
                        onValuesChange={handleValuesChange}
                    />
                    {/* end::Body */}
                    {/* begin::Footer */}
                    <div className='card-footer py-5 text-center' id='kt_activities_footer'>
                        <div className='d-flex justify-content-end'>
                            <button
                                id='kt_addsim_close'
                                className='btn btn-secondary d-flex justify-content-end me-3'
                                onClick={close}
                            >
                                {intl.formatMessage({id: 'TREE.ADD.CLOSE'})}
                            </button>
                            <button
                                className='btn btn-success d-flex justify-content-end'
                                disabled={!valid}
                                onClick={handleSubmitClick}
                            >
                                {intl.formatMessage({id: 'TREE.ADD.SAVE'})}
                            </button>
                        </div>
                    </div>
                    {/* end::Footer */}
                </div>
                {/* end::Card */}
            </Wrapper>
        </>
    )
}

export {AddEditTagDrawer}

const Wrapper = styled.div<{$hidden: boolean}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'flex')};

  position: fixed;
  top: 0;
  right: 0;

  width: 525px;
  height: 100%;

  z-index: 106;

  @media (max-width: 800px) {
    width: 350px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);

  z-index: 105;
`
