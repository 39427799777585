import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {create} from 'zustand'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import styled from 'styled-components'
import {useIntl} from 'react-intl'

import {KTIcon} from '@_metronic/helpers'
import {getSubscriptionPlans, invoice} from '@api/requests'

import {useLang} from '@_metronic/i18n/Metronici18n'
import useIsRuLocale from '@hooks/useIsRuLocale'

import SubscriptionPlan from '@components/molecules/SubscriptionModal/components/SubscriptionPlan'
import PromotionInput from '@components/molecules/SubscriptionModal/components/PromotionInput'

import ProbablyRussianWarning from './components/ProbablyRussianWarning'
import ProbablyEnglishWarning from './components/ProbablyEnglishWarning'

const MySwal = withReactContent(Swal)
const RU_LOCALE = 'ru'

type SubscriptionModalStore = {
  visible: boolean
  show: () => void
  hide: () => void
}

export const useSubscriptionModalStore = create<SubscriptionModalStore>((set) => ({
  visible: false,
  show: () => set((state) => ({...state, visible: true})),
  hide: () => set((state) => ({...state, visible: false})),
}))

export type SubscriptionPlanType = {
  id: number
  duration: number
  discount?: number
  price: number
  is_best: number
  is_promo: number
  link?: string
  promo?: boolean
}

type SubscriptionModalProps = {}
const SubscriptionModal = ({}: SubscriptionModalProps) => {
  const isRussianLocaleFound = useIsRuLocale()
  const language = useLang()
  const isRussianLanguage = language === RU_LOCALE

  const intl = useIntl()
  const {visible, hide} = useSubscriptionModalStore()

  const handleHide = () => {
    if (visible) {
      hide()
    }
  }
  const [plans, setPlans] = useState<SubscriptionPlanType[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedPlanId, setSelectedPlanId] = useState<SubscriptionPlanType['id']>()

  useEffect(() => {
    if (visible || (!visible && !plans.length)) {
      getSubscriptionPlans().then((response) => {
        const receivedPlans = response.data
          ?.filter(({locale}) => (isRussianLanguage ? locale === language : locale !== RU_LOCALE))
          .sort(({duration: durationA}, {duration: durationB}) => durationB - durationA)
          .map(({price, ...plan}) => ({...plan, price: price / 100}))

        setPlans(receivedPlans)
        setSelectedPlanId(receivedPlans.find(({duration}) => duration === 12)?.id)
      })
    }
  }, [visible])

  const [promoName, setPromoName] = useState<string>()
  const [promoAccepted, setPromoAccepted] = useState(false)

  const handleAcceptedPromoChange = (name?: string) => {
    setPromoName(name)
    setPromoAccepted(!!name)
  }

  const handleSubmitClick = () => {
    if (selectedPlanId) {
      setLoading(true)

      invoice(selectedPlanId, promoName)
        .then((response) => {
          window.location.replace(response?.data?.redirect_to)
        })
        .catch((error) => {
          MySwal.fire({
            title: 'Ошибка',
            text: error?.response?.statusText || error?.message,
            icon: 'error',
            heightAuto: false,
          })
        })
        .finally(() => setLoading(false))
    }
  }
  const [acceptReccurent, setAcceptReccurent] = useState(false)
  const handleAcceptReccurentChange = () => {
    setAcceptReccurent(!acceptReccurent)
  }

  const [acceptAgreement, setAcceptAgreement] = useState(false)
  const handleAcceptAgreementChange = () => {
    setAcceptAgreement(!acceptAgreement)
  }

  const [promoRelatedPlans, setPromoRelatedPlans] = useState<SubscriptionPlanType[]>([])
  useEffect(() => {
    if (plans) {
      const filteredPlans = plans.filter(({is_promo}) =>
        promoAccepted ? is_promo !== 2 : is_promo !== 1
      )

      const promoPlan = filteredPlans.find(({is_promo}) => is_promo === 1)

      setSelectedPlanId(
        filteredPlans.find(({is_promo}) => (promoAccepted ? is_promo === 1 : is_promo === 2))?.id
      )
      setPromoRelatedPlans(
        filteredPlans.map((plan) => ({...plan, promo: plan.id === promoPlan?.id}))
      )
    }

    // isRussianLanguage
  }, [promoAccepted, plans])

  return (
    <Modal
      className='modal fade'
      id='kt_modal_select_location'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={visible}
      dialogClassName='modal-dialog modal-dialog-centered'
      aria-hidden='true'
      onHide={handleHide}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h3 className='modal-title'>{intl.formatMessage({id: 'SUB.TITLE'})}</h3>

          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={hide}>
            <KTIcon iconName='cross' className='fs-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <p className='mb-5'>{intl.formatMessage({id: 'SUB.DESCRIPTION'})}</p>

          <PromotionInput onAcceptedPromo={handleAcceptedPromoChange} />

          <div data-kt-buttons='true'>
            {promoRelatedPlans?.map((plan, index) => (
              <SubscriptionPlan
                key={plan.id}
                plan={plan}
                checked={selectedPlanId === plan.id}
                onClick={() => setSelectedPlanId(plan.id)}
              />
            ))}
          </div>
          {/*{language === 'ru' ? (
            <>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  id='flexCheckDefault'
                  className='form-check-input'
                  type='checkbox'
                  checked={acceptReccurent}
                  onChange={handleAcceptReccurentChange}
                />
                <label className='form-check-label pt-2'>
                  {intl.formatMessage({id: 'SUB.CONSENT'})}{' '}
                  <a
                    href='https://thesimstree.com/publichnaya-oferta-sajta.html'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {intl.formatMessage({id: 'SUB.TERMS'})}
                  </a>{' '}
                  {intl.formatMessage({id: 'SUB.OFFER'})}{' '}
                  <a
                    target='_blank'
                    href='https://thesimstree.com/politika-konfidenczialnosti.html'
                    rel='noreferrer'
                  >
                    {intl.formatMessage({id: 'SUB.POLICY'})}
                  </a>
                  .
                </label>
              </div>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  id='flexCheckDefault'
                  className='form-check-input'
                  type='checkbox'
                  checked={acceptAgreement}
                  onChange={handleAcceptAgreementChange}
                />
                <label className='form-check-label '>
                  {intl.formatMessage({id: 'SUB.CONSENT'})}{' '}
                  <a
                    href='https://thesimstree.com/publichnaya-oferta-sajta.html'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {intl.formatMessage({id: 'SUB.AGREEMENT'})}
                  </a>
                  .
                </label>
              </div>
            </>
          ) : null}*/}
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-active-light'
            data-bs-dismiss='modal'
            onClick={hide}
          >
            {intl.formatMessage({id: 'ACTIONS.CLOSE'})}
          </button>
          <StartButton
            type='button'
            className='btn btn-primary'
            /*disabled={language === 'ru' ? !acceptReccurent || !acceptAgreement : false}*/
            onClick={handleSubmitClick}
          >
            {loading ? (
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            ) : (
              intl.formatMessage({id: 'ACTIONS.GET_STARTED'})
            )}
          </StartButton>
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(SubscriptionModal)

const StartButton = styled.button`
  min-width: 200px;
`
