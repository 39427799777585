import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'

import {usePhotosStore} from '@store/photos'
import useHostBasedUrl from '@hooks/useHostBasedUrl'

import {deleteAccount, uploadImage} from '@api/requests'
import {
  UserProfileValues,
  useUserProfileDrawerStore,
} from '@_metronic/partials/layout/userprofile-drawer/UserProfileDrawer'

import {AVATAR_IMAGE_TARGET_SIZES} from '@constants/imageUploading'
import ReactQuillEditor from '@components/molecules/ReactQuillEditor'
import CustomFields from '@components/molecules/CustomFields'
import Swal from 'sweetalert2'
import {useAuth} from '@modules/auth'

interface UserProfileDrawerContentProps {
  values?: UserProfileValues
  close: () => void
  onValuesChange: (values: UserProfileValues) => void
}

const UserProfileDrawerContent: React.FC<UserProfileDrawerContentProps> = ({
  values,
  close,
  onValuesChange,
}) => {
  const intl = useIntl()
  const {logout} = useAuth()
  const [avatar, setAvatar] = useState<{id: number; url: string} | null>(null)

  const [first_name, setFirstName] = useState('')
  const [description, setDescription] = useState('')
  const [customsFields, setCustomFields] = useState<any>([])
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  useEffect(() => {
    onValuesChange({
      first_name,
      avatar,
      description,
      socialNetworks: customsFields,
      password,
      passwordConfirm,
    })
  }, [first_name, avatar, description, customsFields, password, passwordConfirm])

  useEffect(() => {
    if (!values) {
      setFirstName('')
      setAvatar(null)
    } else {
      setFirstName(values.first_name || '')
      setAvatar(values.avatar || null)
      setDescription(values.description || '')
      setCustomFields(values.socialNetworks)
    }
  }, [values])

  const butOpenEditorRef = useRef<any>()

  const {selectPhoto} = usePhotosStore()

  const callBackForCrop = (file) => {
    let reader = new FileReader()
    reader.onload = function (e) {
      if (e && e.target && e.target.result) {
        uploadImage(file, 1).then((response) => {
          const {id, url} = response.data
          setAvatar({id, url})
        })
      }
      // @ts-ignore
      setAvatar(e.target.result)
    }
    reader.readAsDataURL(file)
  }
  function handleFiles(event) {
    let file = event.currentTarget.files[0]
    selectPhoto(file, callBackForCrop, 1, AVATAR_IMAGE_TARGET_SIZES)

    event.currentTarget.value = ''

    if (butOpenEditorRef && butOpenEditorRef.current)
      //@ts-ignore
      butOpenEditorRef.current.click()
  }

  const avatarUrl = useHostBasedUrl(avatar?.url)

  const [showPasswordChangeBlock, setShowPasswordChangeBlock] = useState(false)
  const {opened} = useUserProfileDrawerStore()
  useEffect(() => {
    if (!opened) {
      setShowPasswordChangeBlock(false)
    }
  }, [opened])

  const removeProfile = () => {
    close()
    Swal.fire({
      heightAuto: false,
      title: intl.formatMessage({id: 'A.YOU.SURE'}),
      text: intl.formatMessage({id: 'USER.CONFIRM.DELETE.ACCOUNT'}),
      icon: 'warning',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      preConfirm: async (word) => {
        if (word !== 'DELETE') {
          Swal.showValidationMessage(
            intl.formatMessage({id: 'USER.DELETE.ACCOUNT.VALIDATION.FAILED'})
          )
          return false
        }
        return true
      },
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#50cd89',
      cancelButtonText: intl.formatMessage({id: 'CANCEL'}),
      confirmButtonText: intl.formatMessage({id: 'DELETE'}),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAccount().then((r) => {
          logout()
        })
        //logout();
      }
    })
  }

  return (
    <div
      id='kt_help_body'
      className='card-body hover-scroll-overlay-y'
      data-kt-scroll='true'
      data-kt-scroll-height='auto'
      data-kt-scroll-wrappers='#kt_help_body'
      data-kt-scroll-dependencies='#kt_help_header'
      data-kt-scroll-offset='5px'
    >
      <button
        ref={butOpenEditorRef}
        style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_edit_photo'
      />
      {/* begin::Content */}

      <div className='row mb-6'>
        <label className='col-lg-12 col-form-label fw-semibold fs-6'>
          {intl.formatMessage({id: 'USER.PHOTO'})}
        </label>
        <div className='col-lg-12'>
          <div className='image-input image-input-outline' data-kt-image-input='true'>
            <div
              style={{
                backgroundImage: avatarUrl
                  ? `url('${avatarUrl}')`
                  : 'url("https://my-engine.ru/modules/users/avatar.png")',
              }}
              className='image-input-wrapper w-125px h-125px'
            />
            <label
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              title='Change avatar'
            >
              <i className='ki-duotone ki-pencil fs-7'>
                <span className='path1' />
                <span className='path2' />
              </i>
              <input onChange={handleFiles} type='file' name='avatar' accept='.png, .jpg, .jpeg' />
              <input type='hidden' name='avatar_remove' />
            </label>
            <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='ki-duotone ki-cross fs-2'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </span>
          </div>
          <div className='form-text'>{intl.formatMessage({id: 'USER.FILE_TYPES'})}</div>
        </div>
      </div>
      <div className='mb-10'>
        <label className='required form-label'>{intl.formatMessage({id: 'AUTH.INPUT.NAME'})}</label>
        <input
          type='text'
          value={first_name}
          className='form-control'
          onChange={(e) => setFirstName(e.currentTarget.value)}
        />
      </div>
      <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'TREE.ADD.DESCRIPTION'})}</label>
        <ReactQuillEditor initValue={description} setValue={setDescription} />
      </div>
      <CustomFields
        title={intl.formatMessage({id: 'ADD.FIELD.SOCIAL_NETWORKS_AND_LINKS'})}
        values={customsFields}
        onChange={setCustomFields}
      />
      {!showPasswordChangeBlock ? (
        <button className='btn btn-success' onClick={() => setShowPasswordChangeBlock(true)}>
          <i className='ki-duotone ki-pencil fs-7'>
            <span className='path1' />
            <span className='path2' />
          </i>
          {intl.formatMessage({id: 'USER.CHANGE.PASS.TITLE'})}
        </button>
      ) : (
        <>
          <h4>{intl.formatMessage({id: 'USER.CHANGE.PASS.TITLE'})}</h4>
          <p className='mb-4'>{intl.formatMessage({id: 'USER.CHANGE.PASS.DESCRIPTION'})}</p>
          <div className='mb-10 pt-4'>
            <label className='form-label'>{intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}</label>
            <input
              type='password'
              value={password}
              className='form-control'
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </div>
          <div className='mb-10'>
            <label className='form-label'>
              {intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
            </label>
            <input
              type='password'
              value={passwordConfirm}
              className='form-control'
              onChange={(e) => setPasswordConfirm(e.currentTarget.value)}
            />
          </div>
        </>
      )}
      <button className='mt-10 w-100 btn btn-danger' onClick={removeProfile}>
        <i className='ki-duotone ki-trash'>
          <span className='path1'></span>
          <span className='path2'></span>
          <span className='path3'></span>
          <span className='path4'></span>
          <span className='path5'></span>
        </i>
        {intl.formatMessage({id: 'USER.DELETE.ACCOUNT'})}
      </button>
      {/* end::Content */}
    </div>
  )
}

export {UserProfileDrawerContent}
