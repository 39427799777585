/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useLocation} from 'react-router-dom'
import {getUserByToken, googleAuth} from '../core/_requests'
import {useAuth} from '../core/Auth'

export function SocialCallback() {
  const [error, setError] = useState(false)
  const {search: queryParams} = useLocation()
  const {saveAuth, setCurrentUser} = useAuth()
  const intl = useIntl()

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (queryParams.includes('access_denied')) {
          setError(true)
          throw new Error('Access Denied')
        }
        const {data: auth} = await googleAuth(queryParams)
        if (auth) {
          setError(false)
          saveAuth(auth)
          const userData = (await getUserByToken(auth.TOKEN)).data
          setCurrentUser(userData)
        }
      } catch (error) {
        setError(true)
        console.error('Error processing authorization data:', error)
      }
    }

    fetchData()
  }, [queryParams, saveAuth, setCurrentUser])

  return (
    <div className='d-flex align-items-center'>
      {error ? (
        <div>
          <h1>{intl.formatMessage({id: 'AUTH.ERROR'})}</h1>
          <Link to='/auth/login'>{intl.formatMessage({id: 'AUTH.RETURN'})}</Link>
        </div>
      ) : (
        <div className='d-flex align-items-center'>
          <span className='p-2 spinner-border text-success' />
          <h1 className='p-2 mb-0'>{intl.formatMessage({id: 'AUTH.REDIRECT'})}</h1>
        </div>
      )}
    </div>
  )
}
