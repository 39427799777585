import React, {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import InnerHTML from 'dangerously-set-html-content'

import useReferralString from './hooks/useReferralString'
import {useIsNonRUCountryCheck} from '@hooks/useCountryCheck'

import {I18nProvider} from '@_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '@_metronic/layout/core'
import {MasterInit} from '@_metronic/layout/MasterInit'
import {AuthInit} from '@modules/auth'
import {ThemeModeProvider} from '@_metronic/partials'
import useSessionTs from '@hooks/useSessionTs'

const PIXEL_HTML = `
    <!-- Meta Pixel Code -->
    <script>
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
      console.log('### PIXEL_HTML')
      fbq('init', '1034475857630204');
      fbq('track', 'PageView');
    </script>
    <noscript><img height="1" width="1" style="display:none"
                   src="https://www.facebook.com/tr?id=1034475857630204&ev=PageView&noscript=1"
    /></noscript>
    <!-- End Meta Pixel Code -->
`

const App = () => {
  useReferralString()
  const notRussia = useIsNonRUCountryCheck()

  const {refreshTs} = useSessionTs()

  useEffect(() => {
    refreshTs()
  }, [])

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <ThemeModeProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </ThemeModeProvider>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
      {notRussia ? <InnerHTML html={PIXEL_HTML} /> : null}
    </>
  )
}

export {App}
