import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'

import {usePhotosStore} from '@store/photos'
import {uploadImage} from '@api/requests'
import {PinturaTargetSize} from '@pqina/pintura'

export type Image = {
  id: number
  url: string
}

type ImageFieldProps = {
  initialValue: Image | null
  labelId?: string
  defaultUrl?: string
  aspectRatio?: number
  targetSize?: PinturaTargetSize
  onChange: (image: Image | null) => void
}
const ImageField = ({
  initialValue,
  labelId,
  defaultUrl,
  aspectRatio,
  targetSize,
  onChange,
}: ImageFieldProps) => {
  const intl = useIntl()
  const butOpenEditorRef = useRef<any>()
  const {selectPhoto, callback} = usePhotosStore()

  const [image, setImage] = useState<Image | null>(null)

  useEffect(() => {
    if (image?.id !== initialValue?.id) {
      onChange(image || null)
    }
  }, [image])

  useEffect(() => {
    // console.log('### initialValue', initialValue)
    if (initialValue?.id !== image?.id) {
      setImage(initialValue)
    }
  }, [initialValue])

  const callBackForCrop = (file) => {
    let reader = new FileReader()
    reader.onload = function (e) {
      if (e?.target?.result) {
        uploadImage(file, 1).then((response) => setImage(response.data))
      }
      // @ts-ignore
      setImage(e.target.result)
    }
    reader.readAsDataURL(file)
  }

  function handleFiles(event) {
    let file = event.currentTarget.files[0]
    selectPhoto(file, callBackForCrop, aspectRatio, targetSize)

    event.currentTarget.value = ''

    butOpenEditorRef.current?.click()
  }

  return (
    <>
      <button
        ref={butOpenEditorRef}
        style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_edit_photo'
      />
      <div className='row mb-6'>
        <label className='col-lg-12 col-form-label fw-semibold fs-6'>
          {intl.formatMessage({id: labelId ?? 'TREE.ADD.PHOTO'})}
        </label>
        <div className='col-lg-12'>
          <div className='image-input image-input-outline' data-kt-image-input='true'>
            <div
              style={{
                backgroundImage: `url('${image?.url ?? defaultUrl}')`,
                backgroundPosition: 'center',
                aspectRatio: aspectRatio,
                width: 'auto',
                height: 100,
              }}
              className='image-input-wrapper'
            />
            <label
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              title='Change avatar'
            >
              <i className='ki-duotone ki-pencil fs-7'>
                <span className='path1' />
                <span className='path2' />
              </i>
              <input type='file' name='avatar' accept='.png, .jpg, .jpeg' onChange={handleFiles} />
              <input type='hidden' name='avatar_remove' />
            </label>
            <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='ki-duotone ki-cross fs-2'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </span>
          </div>
          <div className='form-text'>{intl.formatMessage({id: 'TREE.ADD.PHOTO.FORMAT'})}</div>
        </div>
      </div>
    </>
  )
}

export default React.memo(ImageField)
