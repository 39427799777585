import React from 'react'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const modules = {
  toolbar: [
    [{header: '1'}, {header: '2'}, {font: []}],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike'],
    [{color: []}, {background: []}],
    [{script: 'sub'}, {script: 'super'}],
    ['blockquote'],
    [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
    [{direction: 'rtl'}],
    [{align: []}],
    ['link', 'video'],
    ['clean'], // remove formatting button
  ],
}

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'color',
  'background',
  'script',
  'blockquote',

  'list',
  'bullet',
  'indent',
  'direction',
  'align',
  'link',
  'video',
]

type ClassicEditorProps = {
  initValue?: string
  setValue: (value: string) => void
}
const ReactQuillEditor = ({initValue, setValue}: ClassicEditorProps) => {
  return (
    <ReactQuill
      theme='snow'
      value={initValue}
      onChange={setValue}
      modules={modules}
      formats={formats}
    />
  )
}

export default React.memo(ReactQuillEditor)
