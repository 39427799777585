import {useAuth} from '@modules/auth'
import {Article, PersonModel, PublishStatusEnum} from '@api/models'
import {useTreesStore} from '@store/trees'

export const PERSON_ID_SHARE_LINK_PARAM = 'personId'
export const DIARY_ARTICLE_ID_SHARE_LINK_PARAM = 'diaryArticleId'

type UseShareTreeLinkOptions = {
  personId?: PersonModel['id'] | null
  articleId?: Article['_id']
  refToken?: string
}
const useShareTreeLink = (options?: UseShareTreeLinkOptions, onlyQuery?: boolean) => {
  const {currentUser} = useAuth()

  const {activeTreeId, trees} = useTreesStore()
  const tree = activeTreeId ? trees[activeTreeId] : null

  if (tree?.publish?.status === PublishStatusEnum.private) {
    return null
  }

  const params: string[] = []

  if (options?.refToken) {
    params.push(`ref=${options?.refToken}`)
  } else if (currentUser?.ref_token) {
    params.push(`ref=${currentUser?.ref_token}`)
  }

  if (options?.personId) {
    params.push(`${PERSON_ID_SHARE_LINK_PARAM}=${options.personId}`)
  }

  if (options?.articleId) {
    params.push(`${DIARY_ARTICLE_ID_SHARE_LINK_PARAM}=${options.articleId}`)
  }

  const queryParamsString = params.length > 0 ? `?${params.join('&')}` : ''

  if (onlyQuery) {
    return queryParamsString
  }

  return `${window.location.origin}/public/tree/${tree?.publish?.token}${queryParamsString}`
}

export default useShareTreeLink
