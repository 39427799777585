import React, {FC, createContext, useContext} from 'react'
import {WithChildren} from '../helpers'
import {browserHasRuLocale} from '@hooks/useIsRuLocale'

const I18N_CONFIG_INITIALIZED_KEY = 'i18nConfigInitialized'
const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

type Props = {
  selectedLang: 'en' | 'ru' | 'uk' | 'fr'
}

const initialState: Props = {
  selectedLang: 'en',
}

function getConfig(): Props {
  const configInitialized = localStorage.getItem(I18N_CONFIG_INITIALIZED_KEY)

  if (!configInitialized && browserHasRuLocale()) {
    localStorage.setItem(I18N_CONFIG_INITIALIZED_KEY, 'true')

    const ruLocale: Props = {selectedLang: 'ru'}
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify(ruLocale))
    return ruLocale
  }

  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  }

  return initialState
}

// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
  window.location.reload()
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}

const MetronicI18nProvider: FC<WithChildren> = ({children}) => {
  const lang = getConfig()

  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}

export {MetronicI18nProvider, useLang}
