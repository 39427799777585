/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'

import {MessageType} from '@components/molecules/MessengerModal/types'
import {CommentParamTypes, useMessengerModalStore} from '@components/molecules/MessengerModal/store'
import useGetChat from '@hooks/useGetChat'

import {useAuth} from '@modules/auth'

import Message from './Message'
import MessageInput from './MessageInput'
import Citation from './Citation'
import clsx from 'clsx'

type Props = {
  visible: boolean
  refToken?: string
  embeded?: boolean
}

const Messenger: FC<Props> = ({visible, refToken, embeded}) => {
  const {currentUser} = useAuth()
  const {payload, sentCallback} = useMessengerModalStore()

  const tree = payload?.type === CommentParamTypes.tree ? payload.tree : null
  const article = payload?.type === CommentParamTypes.article ? payload.article : null

  const owner =
    payload?.type === CommentParamTypes.tree ? currentUser?.id === tree?.author.id : false

  const {loading, chat, loadChat, sendMessage, deleteMessage} = useGetChat(
    payload?.type,
    tree?.token || tree?.tree_id || article?._id
  )

  const messagesRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = useCallback(() => {
    //@ts-ignore
    messagesRef.current?.scrollTo({left: 0, top: messagesRef.current?.scrollHeight})
  }, [])

  useEffect(() => {
    if (chat?.comments?.length) {
      const lastMessage = chat?.comments?.[chat?.comments?.length - 1]
      if (lastMessage?.user_id === currentUser?.id) {
        scrollToBottom()
      }
    }
  }, [chat?.comments?.length])

  const loadMessages = (noLoading?: boolean) => {
    console.log('### noLoading', noLoading)
    loadChat()
  }

  const [citation, setCitation] = useState<MessageType>()
  const selectCitation = (commentId: number) => {
    setCitation(chat?.comments.find(({id}) => id === commentId))
  }

  const unselectCitation = () => {
    setCitation(undefined)
  }

  useEffect(() => {
    if (visible) {
      loadMessages()
    } else {
      unselectCitation()
    }
  }, [visible, tree])

  useEffect(() => {
    if (visible) {
      const interval = setInterval(() => loadMessages(true), 15000)

      return () => clearInterval(interval)
    }
  }, [visible])

  const messagesMap = useMemo(() => {
    return (chat?.comments || []).reduce((allCommentsObj, comment) => {
      allCommentsObj[comment.id] = comment

      return allCommentsObj
    }, {})
  }, [chat])

  const onSendMessage = (text: string) => {
    sendMessage(text, currentUser, {
      citationId: citation?.id,
      unselectCitation,
      chatId: tree?.tree_id || article?._id,
    })
  }

  const intl = useIntl()
  const onDeleteMessage = (id: number) => {
    // ASK CONFIRMATION
    Swal.fire({
      heightAuto: false,
      title: intl.formatMessage({id: 'A.YOU.SURE'}),
      text: intl.formatMessage({id: 'TREE.CHAT.MESSAGE_DELETION'}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#50cd89',
      cancelButtonText: intl.formatMessage({id: 'CANCEL'}),
      confirmButtonText: intl.formatMessage({id: 'CONFIRM'}),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMessage(id)
      }
    })
  }

  // console.log('### loading', loading)

  return (
    <>
      <div
        className={clsx('card-body hover-scroll-overlay-y pt-5', {
          'h-400px': !embeded,
        })}
        ref={messagesRef}
      >
        {loading ? (
          <div className='d-flex flex-fill justify-content-center'>
            <span className='indicator-progress mt-5' style={{display: 'block'}}>
              {intl.formatMessage({id: 'ACTIONS.WAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </div>
        ) : null}
        {chat?.comments?.map((message) => (
          <Message
            key={message.id}
            authors={chat?.authors}
            message={message}
            citation={message?.comment_id ? messagesMap[message?.comment_id] : undefined}
            owner={owner}
            onDelete={onDeleteMessage}
            onSelect={selectCitation}
          />
        ))}
      </div>

      {citation ? (
        <Citation
          citation={citation}
          author={chat?.authors[citation.user_id]}
          onReset={unselectCitation}
        />
      ) : null}

      <div
        id='kt_activities_footer'
        className={clsx('card-footer py-5 text-center d-flex', {'pb-5': embeded})}
      >
        <MessageInput sendMessage={onSendMessage} refToken={refToken} />
      </div>
    </>
  )
}

export default Messenger
