import React, {useMemo} from 'react'
import clsx from 'clsx'
import moment from 'moment'
import {useIntl} from 'react-intl'

import {UserNotification} from '@modules/auth'

import {KTIcon} from '@_metronic/helpers'
import {readNotification} from '@api/requests'

type NotificationProps = {notification: UserNotification}
const Notification = ({notification}: NotificationProps) => {
  const intl = useIntl()
  const state = useMemo(() => {
    return notification.type === 'comment' ? 'primary' : 'primary'
  }, [notification])

  const icon = 'information-5'

  const onReadNotification = () => {
    readNotification(notification.id).then((response) => {
      console.log('### response', response)

      // TODO remove notification from array
    })
  }

  const author = useMemo(() => {
    if (notification.type === 'like_tree') return notification.reaction.author
    if (notification.type === 'comment') return notification.comment?.author
  }, [notification])

  const authorAvatar = author?.avatar
    ? author.avatar?.url
    : 'https://my-engine.ru/modules/users/avatar.png'

  const tree = useMemo(() => {
    if (notification.type === 'like_tree') return notification.reaction.tree
    if (notification.type === 'comment') return notification.comment?.tree
  }, [notification])

  const textId = useMemo(() => {
    if (notification.type === 'like_tree') return 'NOTIFICATIONS.REACTED_ON_YOUR_TREE'
    if (notification.type === 'comment') {
      return notification.comment?.quoted
        ? 'NOTIFICATIONS.QUOTED_COMMENT'
        : 'NOTIFICATIONS.LEFT_NEW_COMMENT'
    }
  }, [notification])

  const treeLink = useMemo(() => {
    const queryParams = []

    if (author) {
      queryParams['ref'] = author.ref_token
    }

    if (notification.type === 'comment') {
      queryParams['comments'] = '1'
    }

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    return `${window.location.origin}/public/tree/${tree?.token}?${queryString}`
  }, [tree, author])

  return (
    <div className='d-flex flex-stack flex-column justify-content-start py-4'>
      <div className='d-flex flex-fill align-items-start' style={{width: '100%'}}>
        <div className='symbol symbol-35px me-4'>
          <img src={authorAvatar} />
        </div>

        <div className='mb-0 me-2'>
          <b>{author?.first_name}</b>{' '}
          <span className='text-muted'>{moment(notification.create_time).fromNow()}</span>{' '}
          {intl.formatMessage({
            id: textId,
          })}{' '}
          <a target='_blank' href={treeLink}>
            "{tree?.name}"
          </a>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Notification)
