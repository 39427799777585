import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {v4 as uuid} from 'uuid'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'

import {useAuth} from '@modules/auth'
import {useSubscriptionModalStore} from '@components/molecules/SubscriptionModal/SubscriptionModal'
import {usePersonsStore} from '@store/persons'

import {NODE_HEIGHT} from '@components/organisms/Tree/constants'
import {editPersonAx} from '@api/requests'
import {PersonsModalMode, usePersonsModalStore} from '@components/molecules/PersonsModal'

import {ReactComponent as TrashIcon} from '@assets/icons/svg/trash-icon.svg'
import {Node} from '@api/models'

type SubTreeEditButtonsProps = {
  node: {id: number; children: number[]; parents: number[]}
  subTreeOwner: {id: number}
  person: {person_id: number}
  hover: boolean
}
const SubTreeEditButtons = ({subTreeOwner, node, person, hover}: SubTreeEditButtonsProps) => {
  const intl = useIntl()
  const {persons, updatePersons} = usePersonsStore()
  // const {persons: subTreePersons, updatePersons: updateSubTreePerson} = useSubTreePersonsStore()

  const {currentUser} = useAuth()
  const {show: showPersonSelectModal} = usePersonsModalStore()
  const {show: showSubscriptionModal} = useSubscriptionModalStore()

  const personIndex = useMemo(
    () => persons.findIndex(({id}) => id === subTreeOwner.id),
    [persons, subTreeOwner]
  )
  const [busy, setBusy] = useState(false)
  const updateSubTree = (personClone) => {
    console.log('### updateSubTree', subTreeOwner.id, personClone)
    setBusy(true)
    editPersonAx(subTreeOwner.id, personClone)
      .then((response) => {
        console.log('### editPersonAx.response', response)
        const personsClone = [...persons]
        personsClone[personIndex] = {...personsClone[personIndex], ...response.data}
        updatePersons(personsClone, true)
      })
      .finally(() => setBusy(false))
  }

  const handleEditPress = (event) => {
    if (busy) return
    event.preventDefault(true)
    event.stopPropagation()

    if (!currentUser?.is_premium) {
      showSubscriptionModal()
      return
    }

    showPersonSelectModal(
      {
        personSelect: (selectedPerson) => {
          // console.log('### personSelect', selectedPerson)
          const subtreeNodes = persons[personIndex].subtree!.nodes

          // add person_id to node
          const nodeIndex = subtreeNodes.findIndex(({id}) => id === node.id)
          subtreeNodes[nodeIndex] = {
            ...subtreeNodes[nodeIndex],
            person_id: selectedPerson.id,
          }

          updateSubTree({...persons[personIndex], is_subtree: 1, subtree: {nodes: subtreeNodes}})
          if (persons.findIndex(({id}) => id === selectedPerson.id) === -1) {
            updatePersons([...persons, selectedPerson], true)
          }
        },
      },
      PersonsModalMode.personForNodeSelect
    )
  }

  const handleBottomPlusPress = (event) => {
    console.log('### handleBottomPlusPress')
    if (busy) return
    event.preventDefault(true)
    event.stopPropagation()

    if (!currentUser?.is_premium) {
      showSubscriptionModal()
      return
    }

    // console.log('### persons', persons, personIndex, persons[personIndex])
    const subtreeNodes = persons[personIndex].subtree!.nodes
    const newNode = {id: uuid(), parent_id: node.id}
    console.log('### subtreeNodes', subtreeNodes, newNode)
    const subtreeNodesClone = [...subtreeNodes, newNode]
    console.log('### subtreeNodesClone', subtreeNodesClone)
    updateSubTree({...persons[personIndex], is_subtree: 1, subtree: {nodes: subtreeNodesClone}})
  }

  const handleDeletePress = (event) => {
    event.preventDefault(true)
    event.stopPropagation()

    // console.log('### handleDeletePress', person.person_id)
    Swal.fire({
      heightAuto: false,
      title: intl.formatMessage({id: 'A.YOU.SURE'}),
      text: intl.formatMessage({id: 'YOU.WHANT'}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#50cd89',
      cancelButtonText: intl.formatMessage({id: 'CANCEL'}),
      confirmButtonText: intl.formatMessage({id: 'DELETE'}),
    }).then((result) => {
      if (result.isConfirmed) {
        const subtree = persons[personIndex].subtree

        const subtreeNodes = subtree!.nodes.filter(({id}) => id !== node.id)

        updateSubTree({...persons[personIndex], is_subtree: 1, subtree: {nodes: subtreeNodes}})
      }
    })
  }

  const handleHeartPress = (event) => {
    // console.log('### handleHeartPress', node, person, persons[personIndex].subtree)
    event.preventDefault(true)
    event.stopPropagation()

    showPersonSelectModal({personId: person.person_id}, PersonsModalMode.partnerSelect)
  }

  const handleTopPlusPress = (event) => {
    event.preventDefault(true)
    event.stopPropagation()

    if (!currentUser?.is_premium) {
      showSubscriptionModal()
      return
    }

    const subtree = persons[personIndex].subtree
    // add empty node
    const newEmptyNode: Node = {id: uuid()}
    const subtreeNodes = [...subtree!.nodes, newEmptyNode]
    // add id of new node to parents of current node
    const currentNodeIndex = subtreeNodes.findIndex(({id}) => id === node.id)
    subtreeNodes[currentNodeIndex].parent_id = newEmptyNode.id
    // update subtree
    updateSubTree({...persons[personIndex], is_subtree: 1, subtree: {nodes: subtreeNodes}})
  }

  const handleDeleteRootPress = (e) => {
    e.preventDefault(true)
    e.stopPropagation()

    Swal.fire({
      heightAuto: false,
      title: intl.formatMessage({id: 'A.YOU.SURE'}),
      text: intl.formatMessage({id: 'YOU.WHANT'}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#50cd89',
      cancelButtonText: intl.formatMessage({id: 'CANCEL'}),
      confirmButtonText: intl.formatMessage({id: 'DELETE'}),
    }).then((result) => {
      if (result.isConfirmed) {
        const subtree = persons[personIndex].subtree
        // delete node
        const subtreeNodes = [...subtree!.nodes.filter(({id}) => id !== node.id)]

        // update subtree
        updateSubTree({...persons[personIndex], is_subtree: 1, subtree: {nodes: subtreeNodes}})
      }
    })
  }

  return (
    <>
      {subTreeOwner.id !== person.person_id ? (
        <EditButton disabled={busy} $visible={hover} onClick={handleEditPress}>
          <Icon src={require('@components/organisms/Tree/components/Node/assets/icons/edit.png')} />
        </EditButton>
      ) : null}
      <PlusBottomButton disabled={busy} $visible={hover} onClick={handleBottomPlusPress}>
        <Icon src={require('@components/organisms/Tree/components/Node/assets/icons/plus.png')} />
      </PlusBottomButton>

      {!node.children?.length && node.parents?.length > 0 ? (
        <TrashButton disabled={busy} $visible={hover} onClick={handleDeletePress}>
          <TrashIcon height={20} />
        </TrashButton>
      ) : null}

      {node.children?.length === 1 &&
      !node.parents?.length &&
      subTreeOwner.id !== person.person_id ? (
        <DeleteSelfButton disabled={busy} $visible={hover} onClick={handleDeleteRootPress}>
          <TrashIcon height={20} />
        </DeleteSelfButton>
      ) : null}

      {person?.person_id ? (
        <HeartButton disabled={busy} $visible={hover} onClick={handleHeartPress}>
          <Icon src={require(`../assets/icons/heart.png`)} />
        </HeartButton>
      ) : null}

      {!node.parents?.length ? (
        <PlusTopButton disabled={busy} $visible={hover} onClick={handleTopPlusPress}>
          <Icon src={require(`../assets/icons/plus.png`)} />
        </PlusTopButton>
      ) : null}
    </>
  )
}

export default React.memo(SubTreeEditButtons)

const BUTTON_SIZE = 32
const BUTTON_ICON_SIZE = 16

const Button = styled.button<{$visible: boolean}>`
  z-index: 3;
  width: ${BUTTON_SIZE}px;
  height: ${BUTTON_SIZE}px;
  border-radius: 50%;
  background-color: #46bc60;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  transition: opacity;
  transition-duration: 100ms;

  opacity: ${({$visible, disabled}) => ($visible ? (disabled ? 0.2 : 0.8) : 0)};

  &:hover {
    opacity: ${({disabled}) => (disabled ? 0.2 : 1)};
  }

  border: none;
`

const Icon = styled.img`
  width: ${BUTTON_ICON_SIZE}px;
  height: ${BUTTON_ICON_SIZE}px;
`

const EditButton = styled(Button)`
  position: absolute;
  left: -2px;
  top: ${NODE_HEIGHT / 2 - BUTTON_SIZE / 2}px;
`

const PlusBottomButton = styled(Button)`
  position: absolute;
  left: ${NODE_HEIGHT / 2 - BUTTON_SIZE / 2}px;
  bottom: -${BUTTON_SIZE / 2}px;
`

const TrashButton = styled(Button)<{$size: number}>`
  position: absolute;
  left: ${NODE_HEIGHT / 2 - BUTTON_SIZE / 2}px;
  top: -${BUTTON_SIZE / 2 - BUTTON_SIZE}px;

  background-color: crimson;
`

const DeleteSelfButton = styled(TrashButton)`
  left: ${NODE_HEIGHT / 2 - BUTTON_SIZE / 2}px;
  top: ${NODE_HEIGHT / 2 - BUTTON_SIZE / 2}px;
`

const HeartButton = styled(Button)`
  position: absolute;
  left: ${NODE_HEIGHT - BUTTON_SIZE}px;
  top: ${NODE_HEIGHT / 2 - BUTTON_SIZE / 2}px;
`

const PlusTopButton = styled(Button)`
  position: absolute;
  left: ${NODE_HEIGHT / 2 - BUTTON_SIZE / 2}px;
  top: -${BUTTON_SIZE / 2}px;
`
