import axios from 'axios'
import {Cult} from '@pages/CultsPage/store'
import {Tag} from "@pages/TagsPage/store";

const API_URL = process.env.REACT_APP_API_URL

const LOOK_BASE_URL = `${API_URL}/tags`
const getLookIdUrl = (tagId: Tag['id']) => `${LOOK_BASE_URL}/${tagId}`

export function loadTags() {
    return axios.get(LOOK_BASE_URL)
}

export function createTag(data: Tag) {
    return axios.post(LOOK_BASE_URL, data)
}

export function updateTag(tagId: Tag['id'], data: Tag) {
    return axios.patch(getLookIdUrl(tagId), data)
}

export function deleteTag(tagId: Tag['id']) {
    return axios.delete(getLookIdUrl(tagId))
}
