import React, {useMemo} from 'react'
import styled from 'styled-components'

import Lock from '@components/atoms/Lock'

import {BackgroundPackType} from '@components/molecules/StylingBackgrounds/types'
import {useAuth} from '@modules/auth'

enum PackType {
  free = 'free',
  donate = 'donate',
  price = 'price',
  pro = 'pro',
}

const colorsMap = {
  [PackType.free]: '#D6E2F0',
  [PackType.donate]: '#FBF1DE',
  [PackType.price]: '#FBF1DE',
  [PackType.pro]: '#D7F0D6',
}

type BackgroundPackProps = {
  pack: BackgroundPackType
  onSelect: (pack: BackgroundPackType) => void
}
const BackgroundPack = ({pack, onSelect}: BackgroundPackProps) => {
  const handleSelect = () => onSelect(pack)

  const {currentUser} = useAuth()

  const {color, label, unpaid} = useMemo(() => {
    if (pack.payment_type === 'fix-price')
      return {color: colorsMap[PackType.price], label: pack.price, unpaid: !pack.purchased}
    if (pack.access === 'subscribers')
      return {color: colorsMap[PackType.pro], label: 'Pro', unpaid: !currentUser?.is_premium}
    if (pack.payment_type === 'donate')
      return {color: colorsMap[PackType.donate], label: 'Donate', unpaid: !pack.purchased}

    return {color: colorsMap[PackType.free], label: 'Free'}
  }, [pack, currentUser])

  return (
    <Wrapper $color={color} onClick={handleSelect}>
      <AvatarContainer>
        <Avatar src={pack.preview?.url || 'https://my-engine.ru/modules/users/avatar.png'} />
      </AvatarContainer>
      <InfoContainer>
        <TextInfoContainer>
          <TeamName>{pack.name}</TeamName>
          <FileCount>{pack.items?.length} files</FileCount>
        </TextInfoContainer>
        <MembershipStatus>
          <MembershipText>{label}</MembershipText>
        </MembershipStatus>
      </InfoContainer>
      {unpaid ? <Lock /> : null}
    </Wrapper>
  )
}

export default BackgroundPack

const Wrapper = styled.div<{$color: string}>`
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  background: ${({$color}) => $color};
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  cursor: pointer;

  position: relative;
  overflow: hidden;
`

const AvatarContainer = styled.div`
  align-self: stretch;
  padding-bottom: 10px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
`

const Avatar = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 9999px;
  border: 2px white solid;
`

const InfoContainer = styled.div`
  align-self: stretch;
  border-radius: 16px;
  justify-content: flex-start;
  align-items: flex-end;
  display: inline-flex;
`

const TextInfoContainer = styled.div`
  flex: 1 1 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1px;
  display: inline-flex;
`

const TeamName = styled.div`
  color: black;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
`

const FileCount = styled.div`
  color: #98a1b7;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
`

const MembershipStatus = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  background: white;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
`

const MembershipText = styled.div`
  color: black;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;

  text-transform: capitalize;
`
