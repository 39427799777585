import React, {useState} from 'react'
import styled from 'styled-components'
import imageCompression from 'browser-image-compression'
import {useIntl} from 'react-intl'

import {sendImportAvatarsFiles} from '@api/requests'

const options = {
  maxSizeMB: 0.06,
  maxWidthOrHeight: 200,
  useWebWorker: true,
}

type AvatarsImportFormProps = {onComplete: () => void; onClose: () => void}
const AvatarsImportForm = ({onComplete, onClose}: AvatarsImportFormProps) => {
  const intl = useIntl()

  const [formValues, setFormValues] = useState()

  const [processingFiles, setProcessingFiles] = useState(false)
  const [processingFilesCount, setProcessingFilesCount] = useState()
  const [restProcessingFilesCount, setRestProcessingFilesCount] = useState()
  const handleFormChange = async (e) => {
    // Get the files
    const {files} = e.target

    // No files selected
    if (!files.length) return

    setProcessingFiles(true)

    const data = []
    setProcessingFilesCount(files.length)
    setRestProcessingFilesCount(files.length)
    let restFilesCount = files.length

    for (const file of files) {
      setRestProcessingFilesCount(restFilesCount)
      try {
        const compressedFile = await imageCompression(file, options)
        const base64File = await imageCompression.getDataUrlFromFile(compressedFile)
        // @ts-ignore
        data.push({name: file.name, data: base64File})
      } catch (error) {
        console.log(error)
      }
      restFilesCount = restFilesCount - 1
    }

    setProcessingFiles(false)

    // @ts-ignore
    setFormValues(data)
  }

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const handleSubmit = () => {
    setError(null)
    setLoading(true)
    sendImportAvatarsFiles(formValues)
      .then((response) => {
        // TODO show alert or success text inside
        onComplete()
      })
      .catch((error) => {
        setError(error?.message)
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <div className='modal-body'>
        <form onChange={handleFormChange}>
          <h3>{intl.formatMessage({id: 'STEP.TWO'})}</h3>
          <label className='required form-label'>
            {intl.formatMessage({id: 'AVATAR.CHOOSE'})}:
          </label>
          <input
            className='form-control form-control-lg mb-3 mb-lg-0'
            type='file'
            name='avatars'
            accept='.png, .jpg, .jpeg'
            multiple
            disabled={processingFiles || loading}
          />
        </form>
        {error ? <Error>{error}</Error> : null}
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-light-primary' onClick={onClose}>
          {intl.formatMessage({id: 'ACTIONS.CANCEL'})}
        </button>
        <SendButton
          id='submit'
          type='button'
          className='btn btn-primary'
          data-kt-indicator='on'
          disabled={!formValues || loading}
          onClick={handleSubmit}
        >
          {processingFiles || loading ? (
            <IndicatorProgress $processing={processingFiles} className='indicator-progress'>
              {processingFiles
                ? `${intl.formatMessage({
                    id: 'AVATAR.IMPORT.PROCESSING',
                  })} ${restProcessingFilesCount} ${intl.formatMessage({
                    id: 'AVATAR.IMPORT.FROM',
                  })} ${processingFilesCount}`
                : intl.formatMessage({id: 'MSG.UPLOADING'})}
              ... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </IndicatorProgress>
          ) : (
            intl.formatMessage({id: 'ACTIONS.SUBMIT'})
          )}
        </SendButton>
      </div>
    </>
  )
}

export default React.memo(AvatarsImportForm)

const Error = styled.div`
  color: crimson;
  width: 100%;
  text-align: right;

  margin-top: 5px;
`

const IndicatorProgress = styled.span<{$processing: boolean}>`
  transition: min-width 1s;
  min-width: ${({$processing}) => ($processing ? 250 : 110)}px;
`

const SendButton = styled.button``
