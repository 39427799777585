import {useTreesStore} from '@store/trees'

const useIsFirstOrImportedTree = (treeId: number | null) => {
  const {trees} = useTreesStore()

  const orderedTreeIds = Object.keys(trees)
    .map((id) => parseInt(id))
    .sort((a, b) => a - b)
    .slice(0, 2)

  if (treeId === null || orderedTreeIds.length === 0) return true

  return orderedTreeIds.includes(treeId)
}

export default useIsFirstOrImportedTree
