import {useEffect, useMemo, useRef} from 'react'

import useParentsHighlightStore from '@components/organisms/Tree/stores/useParentsHighlightStore'

type Person = {
  id?: number
  parents?: number[]
}

const useHighlightParents = (person: Person, hover: boolean, editMode: boolean) => {
  const {person: parentsOfPersonHighlight, activate, disactivate} = useParentsHighlightStore()
  const activateTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  useEffect(() => {
    if (activateTimeout.current) {
      clearTimeout(activateTimeout.current)
      activateTimeout.current = null
    }

    if (!editMode) {
      if (hover && person.id) {
        activateTimeout.current = setTimeout(() => {
          activate(person)
        }, 400)
      } else {
        disactivate()
      }
    }
  }, [editMode, hover])

  const highlight = useMemo(() => {
    if (!parentsOfPersonHighlight) return true
    if (!parentsOfPersonHighlight.parents?.length) return true
    if (parentsOfPersonHighlight.id === person.id) return true

    return parentsOfPersonHighlight.parents.includes(person.id!)
  }, [parentsOfPersonHighlight, person])

  return highlight
}

export default useHighlightParents
