import React, {MouseEventHandler, useEffect, useRef, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '@_metronic/helpers'
import useShareTreeLink from '@hooks/useShareTreeLink'
import Swal from 'sweetalert2'
import {removePersonArticle} from '@api/requests'
import {useEditArticleDrawerStore} from '@_metronic/partials/layout/editarticle-drawer/EditArticleDrawer'
import {useIntl} from 'react-intl'
import {useAuth} from '@modules/auth'
import {useArticleStore} from '@store/articles'
import {Article, PersonModel} from '@api/models'
import ShareLink, {ShareIcon} from '@components/atoms/ShareLink'
import clsx from 'clsx'
import styled from 'styled-components'
import useOutsideClick from '@hooks/useOutsideClick'

type SimArticleMenuProps = {
  first: boolean
  last: boolean
  readonly: boolean
  allowSharing?: boolean
  personId: PersonModel['id']
  article: Article
  upArticle: MouseEventHandler<HTMLSpanElement>
  downArticle: MouseEventHandler<HTMLSpanElement>
}
const SimArticleMenu = ({
  first,
  last,
  readonly,
  allowSharing,
  personId,
  article,
  upArticle,
  downArticle,
}: SimArticleMenuProps) => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  const {removeArticle} = useArticleStore()
  const {open: showEditArticle} = useEditArticleDrawerStore()

  const shareTreeLink = useShareTreeLink({
    personId,
    articleId: article._id,
  })

  const handleEditPress = () => {
    console.log('## handleEditPress')
    showEditArticle({article, personId})
  }

  const handleDeletePress = () => {
    const articleId = article._id

    Swal.fire({
      heightAuto: false,
      title: intl.formatMessage({id: 'A.YOU.SURE'}),
      // text: `Вы хотите удалить персонажа - ${person.first_name} ${person.last_name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#50cd89',
      cancelButtonText: intl.formatMessage({id: 'CANCEL'}),
      confirmButtonText: intl.formatMessage({id: 'DELETE'}),
    }).then((result) => {
      if (result.isConfirmed) {
        removeArticle(articleId)
        removePersonArticle(personId, articleId)
      }
    })

    return
  }

  const [menuVisible, setMenuVisible] = useState(false)
  const menuRef = useRef(null)

  useEffect(() => {
    const handleClick = (e) => {
      // if (menuRef.current && !menuRef.current.contains(e.target)) {
      setMenuVisible(false)
      // }
    }

    document.addEventListener('mouseup', handleClick)
    return () => {
      document.removeEventListener('mouseup', handleClick)
    }
  }, [])

  return (
    <div className='position-relative'>
      <button
        type='button'
        className='btn btn-sm btn-icon btn-secondary ms-2'
        onClick={() => setMenuVisible(true)}
      >
        <KTIcon iconName='menu' className='fs-2' />
      </button>

      <MenuDropdown
        ref={menuRef}
        className={clsx(
          'menu menu-sub menu-sub-dropdown position-absolute',
          menuVisible && 'd-flex'
        )}
      >
        <div className='d-flex flex-column px-7 py-5 ' data-kt-user-table-filter='form'>
          {shareTreeLink ? (
            <ShareLink link={shareTreeLink!} copiedLabelId={'SIM.VIEW.ARTICLE_LINK_COPIED'}>
              <div className='menu-item'>
                <div className='menu-link'>
                  <span className='menu-icon'>
                    <ShareIcon src={toAbsoluteUrl('/media/icons/duotune/arrows/arr078.svg')} />
                  </span>
                  <span className='menu-title'>{intl.formatMessage({id: 'SHARE'})}</span>
                </div>
              </div>
            </ShareLink>
          ) : null}
          {!readonly && currentUser?.is_premium ? (
            <>
              <div className='menu-item' onClick={handleEditPress}>
                <div className='menu-link'>
                  <span className='menu-icon'>
                    <i className='ki-duotone ki-notepad-edit fs-2'>
                      <span className='path1' />
                      <span className='path2' />
                      <span className='path3' />
                      <span className='path4' />
                    </i>
                  </span>
                  <span className='menu-title'>{intl.formatMessage({id: 'EDIT'})}</span>
                </div>
              </div>

              <div className='menu-item' onClick={handleDeletePress}>
                <div className='menu-link'>
                  <span className='menu-icon'>
                    <i className='ki-duotone ki-trash fs-2'>
                      <span className='path1' />
                      <span className='path2' />
                      <span className='path3' />
                      <span className='path4' />
                    </i>
                  </span>
                  <span className='menu-title'>{intl.formatMessage({id: 'DELETE'})}</span>
                </div>
              </div>
              {!first && (
                <div className='menu-item' onClick={upArticle}>
                  <div className='menu-link'>
                    <span className='menu-icon'>
                      <i className='ki-duotone ki-up'></i>
                    </span>
                    <span className='menu-title'>{intl.formatMessage({id: 'UP'})}</span>
                  </div>
                </div>
              )}

              {!last && (
                <div className='menu-item' onClick={downArticle}>
                  <div className='menu-link'>
                    <span className='menu-icon'>
                      <i className='ki-duotone ki-down'></i>
                    </span>
                    <span className='menu-title'>{intl.formatMessage({id: 'DOWN'})}</span>
                  </div>
                </div>
              )}
            </>
          ) : null}
        </div>
      </MenuDropdown>
    </div>
  )
}

export default React.memo(SimArticleMenu)

const MenuDropdown = styled.div`
  right: 0;
`
