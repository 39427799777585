/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useRef, useState} from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useIntl} from 'react-intl'

import {uploadImage} from '@api/requests'
import {TreeModel} from '@api/models'
import {KTIcon} from '../../../helpers'

import {usePhotosStore} from '@store/photos'
import {useTreesStore} from '@store/trees'

import {TreeBackgroundColorsEnum} from '@constants/treeBackgroundColors'

import ReactQuillEditor from '@components/molecules/ReactQuillEditor'
import CustomFields from '@components/molecules/CustomFields'

import {AVATAR_IMAGE_TARGET_SIZES} from '@constants/imageUploading'
import useHostBasedUrl from '@hooks/useHostBasedUrl'

const MySwal = withReactContent(Swal)

type Values = {name: string; description: string}

type EditTreeDrawerContent = {
  values?: Values
  setParentAvatar: (avatar: object | null) => void
  tree: TreeModel
  onValuesChange: (values: Values) => void
  onClose: () => void
}
const EditTreeDrawerContent = ({values, setParentAvatar, tree, onValuesChange, onClose}) => {
  const butOpenEditorRef = useRef<any>()
  const {selectPhoto, callback} = usePhotosStore()
  const {trees, deleteTree} = useTreesStore()

  const [name, setName] = useState<Values['name']>('')
  const [avatarId, setAvatarId] = useState<number | null>()
  const [avatar, setAvatar] = useState<{url: string} | null>()
  const [selectedBack, setSelectedBack] = useState<TreeBackgroundColorsEnum>(
    TreeBackgroundColorsEnum.green
  )
  const [customsFields, setCustomFields] = useState<any>([])
  const [persistFields, setPersistFields] = useState<any>({})

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const [description, setDescription] = useState<Values['description']>()

  const callBackForCrop = (file) => {
    let reader = new FileReader()
    reader.onload = function (event) {
      if (event && event.target && event.target.result) {
        uploadImage(file, 1).then((response) => {
          setParentAvatar(response.data)
          setAvatarId(response.data.id)
          setAvatar(response.data)
        })
      }
      // @ts-ignore
      // setAvatar(e.target.result)
    }
    reader.readAsDataURL(file)
  }

  function handleFiles(event) {
    let file = event.currentTarget.files[0]
    selectPhoto(file, callBackForCrop, 1, AVATAR_IMAGE_TARGET_SIZES)

    event.currentTarget.value = ''

    butOpenEditorRef.current?.click()
  }

  useEffect(() => {
    onValuesChange({
      name,
      description,
      avatar_id: avatarId,
      background_id: selectedBack,
      params: {...tree?.params, fields: customsFields || [], persist: persistFields || {}},
    })
  }, [name, description, avatarId, selectedBack, customsFields, persistFields])

  useEffect(() => {
    if (tree) {
      setName(tree.name)
      setDescription(tree.description)
      setAvatar(tree.avatar)
      if (tree.avatar) setAvatarId(tree.avatar.id)
      setSelectedBack(tree.background_id)
      setCustomFields(tree.params ? tree.params.fields : [])
      setPersistFields(tree.params ? tree.params.persist : [])
    }
  }, [tree])

  useEffect(() => {
    if (!values) {
      setName('')
      setDescription('')
      setAvatar(null)
      setAvatarId(null)
      setCustomFields([])
      setPersistFields({})
    }
  }, [values])

  const intl = useIntl()

  const handleDeleteDynastyClick = () => {
    Swal.fire({
      heightAuto: false,
      title: intl.formatMessage({id: 'A.YOU.SURE'}),
      text: intl.formatMessage({id: 'YOU.WHANT'}) + `- ${tree.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#50cd89',
      cancelButtonText: intl.formatMessage({id: 'CANCEL'}),
      confirmButtonText: intl.formatMessage({id: 'DELETE'}),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTree(parseInt(tree.id))
          .then((response) => {
            MySwal.fire({
              title: intl.formatMessage({id: 'WAS.DELETE'}),
              text: '',
              icon: 'success',
              heightAuto: false,
            })
            onClose()
          })
          .catch((error) => {
            console.log('### error', error)
            MySwal.fire({
              title: intl.formatMessage({id: 'ERROR.DELETE'}),
              text: error?.message || '',
              icon: 'error',
              heightAuto: false,
            })
          })
      }
    })
  }

  const avatarUrl = useHostBasedUrl(avatar?.url)

  return (
    <>
      <div className='card-body hover-scroll-overlay-y h-400px pt-5' id='kt_tree_body'>
        <button
          ref={butOpenEditorRef}
          style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_edit_photo'
        />
        {/* begin::Content */}
        <div className='row mb-6'>
          <label className='col-lg-12 col-form-label fw-semibold fs-6'>
            {intl.formatMessage({id: 'TREE.ADD.PHOTO'})}
          </label>
          <div className='col-lg-12'>
            <div className='image-input image-input-outline' data-kt-image-input='true'>
              <div
                style={{
                  backgroundImage: avatarUrl
                    ? `url('${avatarUrl}')`
                    : 'url("https://my-engine.ru/modules/users/avatar.png")',
                }}
                className='image-input-wrapper w-125px h-125px'
              />
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title='Change avatar'
              >
                <i className='ki-duotone ki-pencil fs-7'>
                  <span className='path1' />
                  <span className='path2' />
                </i>
                <input
                  type='file'
                  name='avatar'
                  accept='.png, .jpg, .jpeg'
                  onChange={handleFiles}
                />
                <input type='hidden' name='avatar_remove' />
              </label>
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                title='Cancel avatar'
              >
                <i className='ki-duotone ki-cross fs-2'>
                  <span className='path1' />
                  <span className='path2' />
                </i>
              </span>
            </div>
            <div className='form-text'>{intl.formatMessage({id: 'TREE.ADD.PHOTO.FORMAT'})}</div>
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-12 col-form-label required fw-semibold fs-6'>
            {intl.formatMessage({id: 'TREE.ADD.TITLE'})}
          </label>
          <div className='col-lg-12'>
            <input
              type='text'
              name='fname'
              className='form-control form-control-lg mb-3 mb-lg-0'
              placeholder=''
              value={name}
              onChange={handleNameChange}
            />
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-12 col-form-label fw-semibold fs-6'>
            {intl.formatMessage({id: 'INFORMATION'})}
          </label>
          <div className='col-lg-12 fv-row'>
            <ReactQuillEditor initValue={description} setValue={setDescription} />
          </div>
        </div>

        <CustomFields values={customsFields} onChange={setCustomFields} />

        <div className='mt-5 pt-5'>
          <button
            className='btn btn-danger d-flex justify-content-center align-items-center'
            onClick={handleDeleteDynastyClick}
          >
            <KTIcon iconName='trash' className='fs-3' />
            {intl.formatMessage({id: 'TREE.DEL'})}
          </button>
        </div>
        {/* end::Content */}
      </div>
    </>
  )
}

export {EditTreeDrawerContent}
