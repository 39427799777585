import {useEffect, useState} from 'react'
import {getPromoUrl} from '@api/adminRequests'

const ANY_REGION = 'any'

type Promocode = {code: string; parent_token: string}

const useFetchPromocodes = (language: string): Promocode[] | void => {
  const [promocodes, setPromocodes] = useState<Promocode[]>()
  useEffect(() => {
    getPromoUrl()
      .then((receivedPromocodes) => {
        setPromocodes(
          receivedPromocodes?.filter(({region}) => [language, ANY_REGION].includes(region))
        )
      })
      .catch((error) => {
        console.log('### useFetchPromocodes.error', error)
      })
  }, [])

  return promocodes
}

export default useFetchPromocodes
