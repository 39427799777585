import {useMemo, useState} from 'react'
import moment from 'moment/moment'

const STORAGE_KEY = '@ts'

const useSessionTs = () => {
  const [ts, setTs] = useState(localStorage.getItem(STORAGE_KEY) || moment.now())

  const refreshTs = () => {
    const newTs = moment.now()
    localStorage.setItem(STORAGE_KEY, `${newTs}`)
    setTs(newTs)
  }

  return {ts, refreshTs}
}

export default useSessionTs
