import React from 'react'
import {useIntl} from 'react-intl'

import {create} from 'zustand'
import styled from 'styled-components'

import {useAddEditCultDrawerStore} from '@_metronic/partials/layout/addcult-drawer/AddEditCultDrawer'

import {KTIcon} from '@_metronic/helpers'
import {Cult} from '@pages/CultsPage/store'
import {CultDrawerContent} from '@_metronic/partials/layout/cult-drawer/CultDrawerContent'
import {useAddEditTagDrawerStore} from "@_metronic/partials/layout/addtag-drawer/AddEditTagDrawer";
import {TagDrawerContent} from "./TagDrawerContent";
import {Tag} from "@pages/TagsPage/store";

type TagDrawerStore = {
    opened: boolean
    tagId?: Tag['id'] | null
    show: (tagId: Tag['id']) => void
    hide: () => void
}

export const useTagDrawerStore = create<TagDrawerStore>((set) => ({
    opened: false,
    tagId: null,
    show: (tagId) => set((state) => ({...state, opened: true, tagId})),
    hide: () => set((state) => ({...state, opened: false, tagId: null})),
}))

type TagDrawerProps = {
    readonly?: boolean
    refToken?: string
}

const TagDrawer = ({readonly = false, refToken}: TagDrawerProps) => {
    const intl = useIntl()
    const {opened, tagId, hide} = useTagDrawerStore()

    const {open: showEditTagDrawer} = useAddEditTagDrawerStore()

    const openEdit = () => {
        hide()
        showEditTagDrawer(tagId!)
    }

    return (
        <>
            {opened ? <Overlay onClick={hide} /> : null}
            <Wrapper className='bg-body' $hidden={!opened}>
                {/* begin::Card */}
                <div className='card shadow-none rounded-0 w-100'>
                    {/* begin::Header */}
                    <div className='card-header' id='kt_help_header'>
                        <h5 className='card-title fw-bold'>{intl.formatMessage({id: 'TAG.CARD.TITLE'})}</h5>

                        <div className='card-toolbar'>
                            <button
                                type='button'
                                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                                onClick={() => hide()}
                            >
                                <KTIcon iconName='cross' className='fs-2' />
                            </button>
                        </div>
                    </div>
                    {/* end::Header */}

                    {/* begin::Body */}
                    <div className={'card-body hover-scroll-overlay-y h-400px pt-0'}>
                        <TagDrawerContent tagId={tagId} readonly={readonly} />
                    </div>
                    {/* end::Body */}

                    {/* begin::Footer */}
                    {!readonly ? (
                        <div className='card-footer py-5 text-center' id='kt_activities_footer'>
                            <div className='d-flex justify-content-end'>
                                <button
                                    className='btn btn-secondary d-flex justify-content-end me-3'
                                    onClick={hide}
                                >
                                    {intl.formatMessage({id: 'TREE.ADD.CLOSE'})}
                                </button>
                                <button className='btn btn-success d-flex justify-content-end' onClick={openEdit}>
                                    {intl.formatMessage({id: 'EDIT'})}
                                </button>
                            </div>
                        </div>
                    ) : null}
                    {/* end::Footer */}
                </div>
                {/* end::Card */}
            </Wrapper>
        </>
    )
}

export {TagDrawer}

const Wrapper = styled.div<{$hidden: boolean}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'flex')};

  position: fixed;
  top: 0;
  right: 0;

  width: 525px;
  height: 100%;

  z-index: 106;

  @media (max-width: 800px) {
    width: 350px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);

  z-index: 105;
`
