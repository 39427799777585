/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useIntl} from 'react-intl'
import {create} from 'zustand'
import styled from 'styled-components'

import {KTIcon} from '../../../helpers'

import {AvatarModel, PersonModel} from '@api/models'

import {AddSimDrawerContent} from './AddSimDrawerContent'

import {usePersonsStore} from '@store/persons'
import {useAuth} from '@modules/auth'

const MySwal = withReactContent(Swal)

type AddSimDrawerStore = {
  opened: boolean
  open: (initialType: PersonModel['type']) => void
  close: () => void
  initialType: PersonModel['type'] | null
}

export const useAddSimDrawerStore = create<AddSimDrawerStore>((set) => ({
  opened: false,
  open: (initialType) => set((state) => ({...state, opened: true, initialType})),
  close: () => set((state) => ({...state, opened: false, initialType: null})),
  initialType: null,
}))

const AddSimDrawer = () => {
  const intl = useIntl()

  const {createPerson} = usePersonsStore()
  const [newPersonValues, setNewPersonValues] = useState<{
    first_name?: string
    last_name?: string
    type: PersonModel['type']
  } | null>(null)
  const [avatar, setAvatar] = useState<AvatarModel | null>(null)

  const {opened, close, initialType} = useAddSimDrawerStore()

  // useEffect(() => {
  //   console.log('### initialType', initialType)
  //   setNewPersonValues({...newPersonValues, type: initialType || 0})
  // }, [initialType])

  // console.log('### newPersonValues', newPersonValues)

  const {currentUser} = useAuth()

  if (!opened) return null

  const handleValuesChange = (data) => {
    setNewPersonValues(data)
  }
  const handleAddClick = () => {
    if (!newPersonValues) return

    close()
    createPerson(newPersonValues, avatar, {currentUser})
    MySwal.fire({
      position: 'center',
      icon: 'success',
      title: intl.formatMessage({id: 'MSG.CHARACTER.ADDED'}),
      showConfirmButton: false,
      timer: 1500,
      heightAuto: false,
    })
    setNewPersonValues(null)
  }

  const valid =
    newPersonValues?.first_name?.trim() &&
    (newPersonValues.type === 1 || newPersonValues?.last_name?.trim())

  return (
    <>
      {opened ? <Overlay onClick={close} /> : null}
      <Wrapper className='bg-body' $hidden={!opened}>
        {/* begin::Card */}
        <div className='card shadow-none rounded-0 w-100'>
          {/* begin::Header */}
          <div className='card-header' id='kt_help_header'>
            <h5 className='card-title fw-bold'>{intl.formatMessage({id: 'ADD.SIM'})}</h5>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                id='kt_addsim_close'
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <AddSimDrawerContent
            setParentAvatar={setAvatar}
            initialType={initialType}
            handleValuesChange={handleValuesChange}
          />
          {/* end::Body */}
          {/* begin::Footer */}
          <div className='card-footer py-5 text-center' id='kt_activities_footer'>
            <div className='d-flex justify-content-end'>
              <button
                id='kt_addsim_close'
                className='btn btn-secondary d-flex justify-content-end me-3'
                onClick={close}
              >
                {intl.formatMessage({id: 'TREE.ADD.CLOSE'})}
              </button>
              <button
                className='btn btn-success d-flex justify-content-end'
                disabled={!valid}
                onClick={handleAddClick}
              >
                {intl.formatMessage({id: 'TREE.ADD.SAVE'})}
              </button>
            </div>
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Card */}
      </Wrapper>
    </>
  )
}

export {AddSimDrawer}

const Wrapper = styled.div<{$hidden: boolean}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'flex')};

  position: fixed;
  top: 0;
  right: 0;

  width: 525px;
  height: 100%;

  z-index: 106;

  @media (max-width: 800px) {
    width: 350px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);

  z-index: 105;
`
