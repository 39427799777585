/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'

import {getUserByToken, login, googleRedirect} from '../core/_requests'
import {useAuth} from '../core/Auth'
import useReferralString from '../../../hooks/useReferralString'
import {loginSchema} from '../validation-schemas/login-schema'
import {toAbsoluteUrl} from '@_metronic/helpers'
import FormDivider from '@app/components/atoms/FormDivider'

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const ref_token = useReferralString()
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const intl = useIntl()
  const currentLocale = intl.locale

  const [error, setError] = useState('')

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema(currentLocale),
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        saveAuth(auth)
        const {data} = await getUserByToken(auth.TOKEN)
        setCurrentUser(data)
      } catch (error) {
        console.log(error)

        // @ts-ignore
        setError(error?.response?.data?.message)

        setTimeout(() => {
          saveAuth(undefined)
          setStatus('The login details are incorrect')
          setSubmitting(false)
          setLoading(false)
          setError('')
        }, 2000)
      }
    },
  })

  const getGoogleRedirect = async (refToken: string | null) => {
    return refToken ? googleRedirect(refToken) : googleRedirect()
  }

  const handleGoogleLogin = async () => {
    try {
      const {data} = await getGoogleRedirect(ref_token)
      window.location.href = data.value
    } catch (err) {
      console.error('Error during Google login: ', err)
    }
  }

  return (
    <form
      id='kt_login_signin_form'
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-5'>
        <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({id: 'AUTH.LOGIN.TITLE'})}</h1>
      </div>
      {/* begin::Heading */}

      <div className='text-gray-500 text-center fw-semibold fs-6 mb-6'>
        {intl.formatMessage({id: 'AUTH.LOGIN.NOT_MEMBER'})}{' '}
        <Link to='/auth/registration' className='link-primary'>
          {intl.formatMessage({id: 'AUTH.REGISTER.TITLE'})}
        </Link>
      </div>

      <div className='d-grid mb-6'>
        <button
          type='button'
          id='kt_sign_in_google'
          className='btn border border-success text-success mt-3'
          onClick={handleGoogleLogin}
        >
          {!loading && (
            <div className='d-flex align-items-center justify-content-center'>
              <img
                alt='Google Logo'
                src={toAbsoluteUrl('/media/logos/google.svg')}
                style={{height: '12px'}}
              />
              <span className='indicator-label ms-2'>
                {intl.formatMessage({id: 'AUTH.GOOGLE'})}
              </span>{' '}
            </div>
          )}
          {loading && !error && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'ACTIONS.WAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          )}
        </button>
      </div>

      <FormDivider text={intl.formatMessage({id: 'AUTH.WITH_EMAIL'})} />

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
        </label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
        </label>
        <input
          placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          {intl.formatMessage({id: 'AUTH.FORGOT.TITLE'})}
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-6'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className={`btn ${error ? 'btn-danger' : 'btn-success'}`}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {error ? <span className='indicator-label'>{error}</span> : null}
          {!loading && (
            <span className='indicator-label'>{intl.formatMessage({id: 'AUTH.LOGIN.BUTTON'})}</span>
          )}
          {loading && !error && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'ACTIONS.WAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
