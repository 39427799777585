export const ADMIN_USER_TOKEN = '1|mI2txRLHO8NDWXsi3E2e8d3NWhAfNOyv6igfAQJG60c09d6f'

const ADMIN_HOST = 'https://admin.thesimstree.com/api'
const HEADERS = {
  Authorization: `Bearer ${ADMIN_USER_TOKEN}`,
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

export const getUserBackgrounds = (userId: number, excludeItems?: boolean) =>
  fetch(`${ADMIN_HOST}/backgrounds?user_id=${userId}&${excludeItems ? 'exclude_items=1' : ''}`, {
    headers: HEADERS,
  }).then((response) => response.json())

export const getBackgroundItems = (backgroundId: number) =>
  fetch(`${ADMIN_HOST}/backgrounds/${backgroundId}`, {
    headers: HEADERS,
  }).then((response) => response.json())

export const getBackgrounds = (excludeItems?: boolean) =>
  fetch(`${ADMIN_HOST}/backgrounds?${excludeItems ? '?exclude_items=1' : ''}`, {
    headers: HEADERS,
  }).then((response) => response.json())

export const getPromoUrl = () =>
  fetch(`${ADMIN_HOST}/promo`, {
    headers: HEADERS,
  }).then((response) => response.json())

export const postBackgroundOrder = ({background, userId}) =>
  fetch(`${ADMIN_HOST}/backgrounds/${background}/order/${userId}`, {
    headers: HEADERS,
    method: 'post',
  }).then((response) => response.json())
