import {useEffect, useState} from 'react'

import {getUserBackgrounds} from '@api/adminRequests'
import {BackgroundPackType} from '@components/molecules/StylingBackgrounds/types'

const useFetchUserBackgrounds = (
  userId?: number,
  excludeItems?: boolean
): {backgrounds: BackgroundPackType[]; loading: boolean} => {
  const [userBackgrounds, setUserBackgrounds] = useState<BackgroundPackType[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (userId) {
      setLoading(true)
      getUserBackgrounds(userId, excludeItems)
        .then((receivedBackgrounds) => {
          setUserBackgrounds(receivedBackgrounds)
        })
        .catch((error) => {
          console.log('### useFetchUserBackgrounds.error', error)
        })
        .finally(() => setLoading(false))
    }
  }, [userId])

  return {backgrounds: userBackgrounds, loading}
}

export default useFetchUserBackgrounds
