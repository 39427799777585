import {useMemo} from 'react'

const useFirstNodeId = (nodes: {id: number; virtual: boolean; parents: {}[]; children: {}[]}[]) => {
  const firstNodeId = useMemo(() => {
    const realNodes = nodes.filter(({virtual}) => !virtual)

    return realNodes.length > 1
      ? realNodes.filter(({parents, children}) => !parents?.length && children.length)[0]?.id
      : realNodes[0]?.id
  }, [nodes])

  return firstNodeId
}

export default useFirstNodeId
