import React from 'react'
import styled from 'styled-components'

type FormDividerProps = {
    text: string;
}
const FormDivider = ({ text }: FormDividerProps) => {
  return (
    <Divider>
        <Line />
        <DividerLabel>
        {text}
        </DividerLabel>
        <Line />
    </Divider>
  )
}

export default React.memo(FormDivider)


const Divider = styled.div`
  justify-content: center;
  align-items: center;
  
  padding-top: 0;
  padding-bottom: 20px;

  display: flex;
`

const Line = styled.div`
  height: 1px;
  width: 100%;

  background-color: var(--bs-border-color);
`

const DividerLabel = styled.span`
  flex-shrink: 0;
  color: var(--bs-text-gray-500)!important;
  padding: 0 10px;
`