import React from 'react'
import ContentLoader from 'react-content-loader'

const DescriptionSkeleton = () => {
  return (
    <ContentLoader viewBox='0 0 380 70' backgroundColor='#ccc' foregroundColor='#fff' speed={2}>
      <rect x='10' y='17' rx='4' ry='4' width='330' height='13' />
      <rect x='10' y='40' rx='3' ry='3' width='250' height='13' />
    </ContentLoader>
  )
}

export default React.memo(DescriptionSkeleton)
