import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import {useAuth} from '@modules/auth'
import useReferralString from '@hooks/useReferralString'
import {useLang} from '@_metronic/i18n/Metronici18n'

import useFetchPromocodes from './useFetchPromocodes'

const PROMO_STORAGE_KEY = '@promo_storage2'

type PromotionInputProps = {onAcceptedPromo: (parent_token?: string) => void}
const PromotionInput = ({onAcceptedPromo}: PromotionInputProps) => {
  const language = useLang()

  const intl = useIntl()
  const {currentUser} = useAuth()
  const ref_token = useReferralString()

  const [promoTouched, setPromoTouched] = useState(false)
  const [promoValue, setPromoValue] = useState(
    () => localStorage.getItem(PROMO_STORAGE_KEY) || ref_token || ''
  )
  const [promoValid, setPromoValid] = useState(false)
  const [promoAccepted, setPromoAccepted] = useState(false)

  const promocodes = useFetchPromocodes(language)

  const parentTokenPromoCode = promocodes?.find(
    ({parent_token}) => parent_token === currentUser?.parent_token
  )
  const promoDisabled = !!parentTokenPromoCode

  useEffect(() => {
    if (parentTokenPromoCode) {
      setPromoValue(parentTokenPromoCode.code.toUpperCase())
    }
  }, [parentTokenPromoCode])

  const handlePromoBlur = () => {
    // if (promoValue) {
    //   setPromoTouched(true)
    // }
  }

  const handlePromoValueChange = (event) => {
    const value = event.target.value.toUpperCase()
    setPromoValue(value)
  }

  useEffect(() => {
    setPromoTouched(!!promoValue)
  }, [promoValue])

  useEffect(() => {
    const foundPromo = promocodes?.find(({code}) => code.toUpperCase() === promoValue.toUpperCase())
    if (foundPromo) {
      setPromoAccepted(true)
      setPromoValid(true)
      onAcceptedPromo(foundPromo.code)

      localStorage.setItem(PROMO_STORAGE_KEY, promoValue)
    } else {
      setPromoValid(false)
      onAcceptedPromo()
    }
  }, [promoValue, promocodes])

  if (promoDisabled && !promoAccepted) return null

  return (
    <div className='mb-10'>
      <label className='form-label'>{intl.formatMessage({id: 'PROMOCODE'})}</label>
      <input
        placeholder={intl.formatMessage({id: 'PROMOCODE'})}
        type='text'
        name='promo'
        value={promoValue || ''}
        onBlur={handlePromoBlur}
        onChange={handlePromoValueChange}
        className={clsx(
          'form-control mb-5 mb-lg-0',
          {'is-invalid': !promoValid && promoTouched},
          {'is-valid': promoValid}
        )}
        autoComplete='off'
      />
    </div>
  )
}

export default React.memo(PromotionInput)
