import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'

import {useTreesStore} from '@store/trees'

import {KTIcon} from '@_metronic/helpers'

import JSImportForm from './components/JSImportForm'
import AvatarsImportForm from './components/AvatarsImportForm'

type ImportTreeModalProps = {
  show: boolean
  onClose: () => void
}
const ImportTreeModal = ({show, onClose}: ImportTreeModalProps) => {
  const intl = useIntl()

  const handleHide = () => {
    onClose()
  }

  const [jsFilesImported, setJsFilesImported] = useState(false)

  useEffect(() => {
    if (!show) {
      setJsFilesImported(false)
    }
  }, [show])

  const {getAllTrees} = useTreesStore()

  const handleComplete = () => {
    getAllTrees()
    onClose()
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_select_location'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-dialog modal-dialog-centered'
      aria-hidden='true'
      onHide={handleHide}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>{intl.formatMessage({id: 'TREE.IMPORT.TITLE'})}</h5>

          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onClose}>
            <KTIcon iconName='cross' className='fs-2x' />
          </div>
        </div>

        {!jsFilesImported ? (
          <JSImportForm onComplete={() => setJsFilesImported(true)} onClose={onClose} />
        ) : (
          <AvatarsImportForm onComplete={handleComplete} onClose={onClose} />
        )}
      </div>
    </Modal>
  )
}

export default React.memo(ImportTreeModal)
