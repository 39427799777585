import {getPerson} from '@api/requests'
import {usePersonsStore} from '@store/persons'
import {PersonModel} from '@api/models'

const useRegetPerson = (personId: number): (() => Promise<PersonModel | undefined>) => {
  const {persons, updatePersons} = usePersonsStore()

  return async () => {
    if (personId) {
      console.log('### getPerson(personId)', personId)
      return getPerson(personId)
        .then((response) => {
          const receivedPerson = response.data
          console.log('### receivedPerson', receivedPerson)

          const foundPerson = persons.find(({id}) => id === personId)
          const refreshedPerson = {...foundPerson, ...receivedPerson}
          console.log('### refreshedPerson', refreshedPerson)

          const personsClone = [...persons]
          const foundPersonIndex = personsClone.findIndex(({id}) => id === refreshedPerson.id)

          console.log('### foundPersonIndex', foundPersonIndex)
          if (foundPersonIndex === -1) {
            updatePersons([...personsClone, refreshedPerson], true)
          } else {
            personsClone[foundPersonIndex] = refreshedPerson
            updatePersons(personsClone, true)
          }

          return refreshedPerson
        })
        .catch((error) => console.log('### error', error))
    }

    return Promise.resolve(undefined)
  }
}

export default useRegetPerson
