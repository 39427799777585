import React, {FC} from 'react'
import styled from 'styled-components'

type UserStatusProps = {user: {is_premium?: boolean; role: number}; hideFree?: boolean}
const UserStatus: FC<UserStatusProps> = ({user, hideFree = false}) => {
  const {is_premium, role} = user

  const isQueen = role === 2
  const isBlogger = role === 1
  const isPremium = is_premium

  return (
    <span className='badge fw-bolder fs-8 px-0 py-1 ms-2'>
      {isQueen ? <ProBadgeIcon alt='Influencer' src={require('@assets/images/crown.png')} /> : null}

      {isBlogger ? (
        <ProBadgeIcon alt='Influencer' src={require('@assets/images/star.png')} />
      ) : null}

      {isPremium && !isBlogger && !isQueen ? (
        <>
          <ProBadgeIcon alt='Pro Account' src={require('@assets/icons/images/plumbob.png')} />
        </>
      ) : null}

      {!isPremium && !isBlogger && !isQueen && !hideFree ? (
        <span className='badge-light-primary'>Free</span>
      ) : null}
    </span>
  )
}

export default React.memo(UserStatus)

const ProBadgeIcon = styled.img`
  height: 20px;
  margin-right: 5px;
`
