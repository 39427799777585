/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef} from 'react'
import {usePhotosStore} from '@store/photos'
import {PinturaEditorModal} from '@pqina/react-pintura'
import {
  createMarkupEditorToolStyle,
  createMarkupEditorToolStyles,
  getEditorDefaults,
  markup_editor_locale_en_gb,
  plugin_annotate_locale_en_gb,
} from '@pqina/pintura'
import '@pqina/pintura/pintura.css'
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  plugin_finetune_locale_en_gb,
  locale_en_gb,
  plugin_crop_locale_en_gb,
  createDefaultShapePreprocessor,
  markup_editor_defaults,
  plugin_filter_defaults,
  plugin_finetune_defaults,
} from '@pqina/pintura'

import {qualityUploadedFiles} from '@constants/uploadFiles'

const EditPhotosDrawer = ({}) => {
  const {selectedPhoto, targetSize, selectPhoto, aspectShape, callback, editMode, saveMimeType} =
    usePhotosStore()
  // @ts-ignore
  // console.log('### selectedPhoto', selectedPhoto, selectedPhoto.type)
  const butRef = useRef<any>()

  // console.log('### EditPhotosDrawer')
  // console.log({
  //   quality: !editMode ? qualityUploadedFiles : undefined,
  //   // @ts-ignore
  //   mimeType: selectedPhoto?.type === 'image/png' ? 'image/png' : 'image/jpeg',
  //   targetSize: targetSize,
  // })

  const editorDefaults = getEditorDefaults({
    imageReader: createDefaultImageReader(),
    // This will write the output image
    imageWriter: createDefaultImageWriter({
      quality: !editMode ? qualityUploadedFiles : undefined,
      // @ts-ignore
      mimeType: saveMimeType ? selectedPhoto?.type : 'image/jpeg',
      targetSize: targetSize,
    }),
    // The markup editor default options, tools, shape style controls
    ...markup_editor_defaults,
    ...plugin_filter_defaults,
    ...plugin_finetune_defaults,
    // This handles complex shapes like arrows / frames
    shapePreprocessor: createDefaultShapePreprocessor(),
    // This will set a square crop aspect ratio
    imageCropAspectRatio: aspectShape,

    stickers: ['😎', '😘', '😡', '😍'],

    locale: {
      ...locale_en_gb,
      ...plugin_crop_locale_en_gb,
      ...plugin_finetune_locale_en_gb,
      ...plugin_annotate_locale_en_gb,
      ...markup_editor_locale_en_gb,
    },
  })

  const close = () => {
    butRef.current?.click()
    selectPhoto(null, null, 1)
  }

  const process = (res) => {
    callback?.(res.dest)
    close()
  }
  return (
    <div
      data-bs-keyboard='false'
      className='modal fade bg-black'
      tabIndex={-1}
      id='kt_modal_edit_photo'
    >
      <div className='modal-dialog modal-fullscreen'>
        <div className='modal-content shadow-none'>
          <button style={{opacity: 0, position: 'absolute'}} ref={butRef} data-bs-dismiss='modal' />
          {selectedPhoto ? (
            <div style={{height: '100%'}}>
              <PinturaEditorModal
                {...editorDefaults}
                src={URL.createObjectURL(selectedPhoto)}
                markupEditorToolStyles={createMarkupEditorToolStyles({
                  text: createMarkupEditorToolStyle('text', {
                    // Set default text shape background to transparent
                    backgroundColor: [0, 0, 0, 0],

                    // Set default text shape outline to 0 width and white
                    textOutline: ['0%', [1, 1, 1]],

                    // Set default text shadow, shadow will not be drawn if x, y, and blur are 0.
                    textShadow: ['0%', '0%', '0%', [0, 0, 0, 0.5]],

                    // Allow newlines in inline text
                    disableNewline: false,

                    // Align to left by default, this triggers always showing the text align control
                    textAlign: 'left',

                    // Enable text formatting
                    format: 'html',
                  }),
                })}
                onProcess={process}
                onClose={() => close()}
              />
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  )
}

export {EditPhotosDrawer}
