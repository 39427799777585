import axios from 'axios'
import _ from 'lodash'

import {
  CreatePersonArticlesModel,
  FileModel,
  PersonArticlesModel,
  PersonModel,
  TreeModel,
} from '@api/models'
import {Relation} from '@pages/RelationsPage/store'
import {Cult} from '@pages/CultsPage/store'

const API_URL = process.env.REACT_APP_API_URL

//Пока оставлю этот урл тут.
// Тк у нас статьи на другом беке, то надо указать его в другой переменной
export const ARTICLES_URL = 'https://test-editor.thesimstree.com/editor-api'
const GET_PERSON_ARTICLES_URL = `${ARTICLES_URL}/article/:personId`
const PUT_PERSON_SORT_ARTICLES_URL = `${ARTICLES_URL}/article/:personId`
const CREATE_PERSON_ARTICLE_URL = `${ARTICLES_URL}/article`
const REMOVE_PERSON_ARTICLE_URL = `${ARTICLES_URL}/article/:personId/:articleId`
const EDIT_PERSON_ARTICLE_URL = `${ARTICLES_URL}/article/:personId/:articleId`

export const TREES_URL = `${API_URL}/tree`
export const DELETE_ACCOUNT_URL = `${API_URL}/profile`
export const DELETE_TREES_URL = (treeId: number) => `${API_URL}/tree/${treeId}`
export const PERSONS_URL = `${API_URL}/person`
export const FILE_URL = `${API_URL}/file`
export const FILE_UPLOAD_URL = `${API_URL}/file/upload`
export const FILES_GET_URL = `${API_URL}/file`
export const FILE_ID_URL = (id: number) => `${API_URL}/file/${id}`

export const LIKES = (token: string) => `${API_URL}/tree/likes?token=${token}`

type TreeCommentsParam = {
  id?: string | number
  token?: string
  type: ReactionTargetType.tree
}
type ArticleCommentsParams = {
  id: string | number
  type: ReactionTargetType.article
}
const COMMENTS = (params: TreeCommentsParam | ArticleCommentsParams) => {
  if (params.type === ReactionTargetType.tree) {
    return `${API_URL}/comments?${
      params.id ? `id=${params.id}&type=${params.type}` : `token=${params.token}`
    }`
  }

  if (params.type === ReactionTargetType.article) {
    return `${API_URL}/comments?id=${params.id}&type=${params.type}`
  }
}

// Get All Trees
export function getTrees() {
  return axios.get<TreeModel[]>(TREES_URL)
}

/*
 PERSON ARTICLES API
 */

//Change articles sort
export function changePersonArticlesSort(person_id, data) {
  return axios.put<PersonArticlesModel>(
    PUT_PERSON_SORT_ARTICLES_URL.replace(':personId', person_id),
    {data}
  )
}
//Get person articles
export function getPersonArticles(person_id) {
  return axios.get<PersonArticlesModel>(GET_PERSON_ARTICLES_URL.replace(':personId', person_id))
}
//Edit article from person
export function editPersonArticle(person_id, _id, name, doc, subtitle) {
  return axios.put<{result: boolean; html: String}>(
    EDIT_PERSON_ARTICLE_URL.replace(':personId', person_id).replace(':articleId', _id),
    {
      name,
      doc,
      subtitle,
    }
  )
}
//Remove article from person
export function removePersonArticle(person_id, _id) {
  return axios.delete<{result: boolean}>(
    REMOVE_PERSON_ARTICLE_URL.replace(':personId', person_id).replace(':articleId', _id)
  )
}
//New person articles
export function createPersonArticle(person_id, name, doc, subtitle) {
  return axios.post<CreatePersonArticlesModel>(CREATE_PERSON_ARTICLE_URL, {
    person_id,
    doc,
    name,
    subtitle,
  })
}

/*
 PERSON ARTICLES API
 */

// Get All Trees
export function getPersons() {
  return axios.get<PersonModel[]>(PERSONS_URL)
}
export function getPhotos(type: number | string) {
  return axios.get<FileModel>(FILES_GET_URL + `?type=${type}`)
}

export function getFile(id: number) {
  return axios.get<FileModel>(FILE_ID_URL(id))
}

export function patchFileDescription(id: number, description: string) {
  return axios.patch<FileModel>(FILE_ID_URL(id), {description})
}

export function deleteAccount() {
  return axios.delete<FileModel>(DELETE_ACCOUNT_URL)
}

export function uploadImage(file: any, type: number | string) {
  let formData = new FormData()
  formData.append('file', file)
  // @ts-ignore
  formData.append('type', type)

  return axios.post<FileModel>(FILE_UPLOAD_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

//CREATE PERSON
export function createPerson(data: object) {
  return axios.post<PersonModel>(PERSONS_URL, data)
}
//DELETE PERSON
export function deletePerson(personId: number) {
  return axios.delete<PersonModel>(`${PERSONS_URL}/${personId}`)
}
//EDIT PERSON
export function editPersonAx(personId: number, data: PersonModel) {
  console.log('### editPersonAx', personId, data)
  const mappedData = {...data}
  // @ts-ignore
  if (mappedData.tags) {
    // @ts-ignore
    mappedData.tags = mappedData.tags.map((tag) => tag.id || tag)
  }
  return axios.patch<PersonModel>(`${PERSONS_URL}/${personId}`, mappedData)
}
export function sendEditedTree(
  name: string,
  description: string,
  avatar_id: number | null,
  background_id: number,
  treeId?: number | string | null
) {
  return axios.patch<TreeModel>(`${TREES_URL}/${treeId}`, {
    name,
    description,
    avatar_id,
    background_id,
  })
}

export function editTree(treeId: number, data: any) {
  return axios.patch<TreeModel>(`${TREES_URL}/${treeId}`, data)
}

type CreateTreeData = {
  name: string
  description: string
  avatar_id: number | null
  background_id: number | null
  params?: object
}
//CREATE TREE
export function createTree(data: CreateTreeData) {
  return axios.post<TreeModel>(TREES_URL, data)
}

export const deleteTree = (treeId: number) => axios.delete<TreeModel>(DELETE_TREES_URL(treeId))

const ADD_TREE_NODE_URL = (treeId: number) => `${API_URL}/tree/${treeId}/node`
export function createNode(treeId: number, parent_id?: number, person_id?: number) {
  return axios.post<TreeModel>(ADD_TREE_NODE_URL(treeId), {parent_id, person_id})
}

const UPDATE_TREE_NODE_URL = (treeId: number, nodeId: number) =>
  `${API_URL}/tree/${treeId}/node/${nodeId}`

export function updateNode(
  treeId: number,
  node_id: number,
  parent_id?: number,
  person_id?: number
) {
  return axios.patch<TreeModel>(UPDATE_TREE_NODE_URL(treeId, node_id), {parent_id, person_id})
}

const SEND_IMPORT_TREE_JS_FILES_URL = `${API_URL}/import/asform`
export const sendImportJSsFiles = (files) => {
  let formData = new FormData()

  files.forEach((file) => {
    formData.append('file[]', file)
  })

  return axios.post(SEND_IMPORT_TREE_JS_FILES_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const SEND_IMPORT_TREE_AVATARS_URL = `${API_URL}/import`

export const sendImportAvatarsFiles = (files) => {
  const chunkSize = 10

  const sendChunk = (index) => {
    const filesChunk = files.slice(index, index + chunkSize)
    if (filesChunk.length > 0) {
      const data = filesChunk.map((file) => {
        return {
          ...file,
          type: 'avatar',
        }
      })

      return axios
        .post(SEND_IMPORT_TREE_AVATARS_URL, data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        .then(() => sendChunk(index + chunkSize))
    } else {
      return Promise.resolve()
    }
  }

  return sendChunk(0)
}

const DELETE_TREE_NODE_URL = (treeId: number, nodeId: number) =>
  `${API_URL}/tree/${treeId}/node/${nodeId}`
export function deleteNode(treeId: number, node_id: number) {
  return axios.delete<TreeModel>(DELETE_TREE_NODE_URL(treeId, node_id))
}

const SUBSCRIPTION_PLANS_URL = `${API_URL}/site/plans`
export function getSubscriptionPlans() {
  return axios.get(SUBSCRIPTION_PLANS_URL)
}

export function getSiteInitInfo() {
  return axios.get(`${API_URL}/site/init`)
}

const DELETE_FILE_URL = (fileId: number) => `${API_URL}/file/${fileId}`
export function deleteFile(fileId: number) {
  return axios.delete<TreeModel>(DELETE_FILE_URL(fileId))
}

const INVOICE_URL = `${API_URL}/invoice`
export function invoice(plan_id: number, promoName?: string) {
  const data: {plan_id: number; promocode?: string} = {plan_id}
  if (promoName) {
    data.promocode = promoName
  }

  return axios.post(INVOICE_URL, data)
}

export function updateProfile(data: {
  avatar_id?: number
  first_name?: string
  description?: string
  password?: string
  unsubscribe_payment?: number
}) {
  console.log('### updateProfile', data)
  return axios.patch(`${API_URL}/profile`, data)
}

export type PublicTreeAuthor = {
  avatar: string | null
  first_name: string
  id: number
  is_premium: boolean
  last_name: string | null
  ref_token?: string
}

const SHARED_TREE = (token) => `${API_URL}/tree/shared?token=${token}`
export function getPublicTree(token: string) {
  return axios.get<{
    tree: TreeModel
    persons: PersonModel[]
    author: PublicTreeAuthor
    partnership: Relation[]
    looks: Cult[]
  }>(SHARED_TREE(token))
}

export function getEntireTree(treeId: TreeModel['id']) {
  return axios.get<{
    tree: TreeModel
    persons: PersonModel[]
    author: PublicTreeAuthor
  }>(`${API_URL}/tree/${treeId}/entire`)
}

export enum GalleryTreesSort {
  dateInc = 'date',
  dateDec = '-date',
  likesInc = 'likes',
  likesDec = '-likes',
}

export enum SimsTreesSort {
  dateInc = 'date',
  dateDec = '-date',
  likesInc = 'likes',
  likesDec = '-likes',
}

type GetGalleryTreesParams = {
  page: number
  perPage?: number
  sort?: GalleryTreesSort
  location?: string
  treeName?: string
  authorName?: string
  treeIds?: number[]
  role?: number
}
export const GALLERY_TREES_URL = ({
  page,
  perPage = 30,
  sort = GalleryTreesSort.dateDec,
  location,
  treeName,
  authorName,
  treeIds,
  role,
}: GetGalleryTreesParams) => {
  const pageParam = !_.isUndefined(page) ? `page=${page}` : ''
  const sortParam = !_.isUndefined(sort) ? `sort=${sort}` : ''
  const pageSizeParam = !_.isUndefined(page) ? `per_page=${perPage}` : ''

  const locationFilterParam = location ? `filter[location]=${location}` : ''
  const treeNameFilterParam = treeName ? `filter[tree_name]=${treeName}` : ''
  const authorNameFilterParam = authorName ? `filter[user_name]=${authorName}` : ''

  const roleFilterParam = role ? `filter[role]=${role}` : ''

  const treeIdFilterParam =
    treeIds && treeIds.length > 0 ? `filter[tree_id]=${treeIds.join(',')}` : ''

  console.log('### role', role)

  const queryParams = [
    pageParam,
    pageSizeParam,
    sortParam,
    locationFilterParam,
    treeNameFilterParam,
    authorNameFilterParam,
    treeIdFilterParam,
    roleFilterParam,
  ]
    .filter((p) => p)
    .join('&')

  return `${API_URL}/tree/gallery?${queryParams}`
}

export function getGalleryTrees(searchParams: GetGalleryTreesParams) {
  return axios.get(GALLERY_TREES_URL(searchParams))
}

export function likeGalleryTree(token) {
  return axios.put(SHARED_TREE(token), {value: 'like'})
}

export function dislikeGalleryTree(token) {
  return axios.put(SHARED_TREE(token), {value: '-like'})
}

export function createComment(
  params: TreeCommentsParam | ArticleCommentsParams,
  text: string,
  commentId?: number
) {
  const data: {message: string; comment_id?: number} = {message: text}

  if (commentId) {
    data.comment_id = commentId
  }

  const commentsUrl = COMMENTS(params)

  if (commentsUrl) {
    return axios.post(commentsUrl, data)
  }

  return Promise.reject(new Error('Wrong comment type property'))
}

export function getComments(params: TreeCommentsParam | ArticleCommentsParams) {
  const commentsUrl = COMMENTS(params)

  if (commentsUrl) {
    return axios.get(commentsUrl)
  }

  return Promise.reject(new Error('Wrong comment type property'))
}

export function deleteComment(id: number) {
  return axios.delete(`${API_URL}/comments/${id}`)
}

export enum ReactionTargetType {
  tree = 'tree',
  person = 'person',
  article = 'article',
}

export enum ReactionTypes {
  like = 'like',
  dislike = '-like',
  favorite = 'favorite',
  disfavorite = '-favorite',
}

export function getReaction(params: {id: number | string; type: ReactionTargetType}) {
  return axios.get(`${API_URL}/reaction?id=${params.id}&type=${params.type}`)
}

export function putReaction(params: {
  id: number | string
  type: ReactionTargetType
  value: ReactionTypes
}) {
  console.log('### putReaction', params)
  return axios.put(`${API_URL}/reaction`, params)
}

export function addTreeLike(treeId: number) {
  return putReaction({id: treeId, type: ReactionTargetType.tree, value: ReactionTypes.like})
}

export function deleteTreeLike(treeId: number) {
  return putReaction({id: treeId, type: ReactionTargetType.tree, value: ReactionTypes.dislike})
}

export function markTreeAsFavorite(treeId: number) {
  return putReaction({id: treeId, type: ReactionTargetType.tree, value: ReactionTypes.favorite})
}

export function unmarkTreeAsFavorite(treeId: number) {
  return putReaction({id: treeId, type: ReactionTargetType.tree, value: ReactionTypes.disfavorite})
}

export function readAllNotifications() {
  return axios.head(`${API_URL}/profile/notifications`)
}

export function readNotification(id: number) {
  return axios.head(`${API_URL}/profile/notification/${id}`)
}

type GetPagedSimsParams = {
  page?: number
  perPage?: number
  name?: string
  id?: number
  ids?: number[]
  gender?: string
  looks?: string
  tree_id?: string
  tags?: string
  is_alive?: number
  type?: number
  fields?: string
  ignoreHeaders?: boolean
}
export function getPagedSims({
  page,
  perPage = 50,
  name,
  id,
  ids,
  gender,
  tags,
  tree_id,
  looks,
  is_alive,
  type,
  fields,
  ignoreHeaders,
}: GetPagedSimsParams) {
  // console.log('### getPagedSims', ignoreHeaders)
  const pageParam = typeof page !== 'undefined' ? `page=${page + 1}` : ''
  const pageSizeParam = typeof page !== 'undefined' ? `per_page=${perPage}` : ''

  // const locationFilterParam = location ? `filter[location]=${location}` : ''
  // const treeNameFilterParam = treeName ? `filter[tree_name]=${treeName}` : ''
  // const authorNameFilterParam = authorName ? `filter[user_name]=${authorName}` : ''
  const nameParam = name ? `filter[name]=${name}` : ''

  const idParam = id ? `filter[id]=${id}` : ''

  if (ids && ids.length === 0) {
    return Promise.resolve({data: []})
  }

  const idsParam = ids && ids?.length > 0 ? ids.map((id) => `filter[id][]=${id}`).join('&') : ''
  const genderParam = typeof gender !== 'undefined' ? `filter[gender]=${gender}` : ''
  const looksParam = typeof looks !== 'undefined' ? `filter[looks]=${looks}` : ''
  const tagsParam = typeof tags !== 'undefined' ? `filter[tags]=${tags}` : ''
  const tree_idParam = typeof tree_id !== 'undefined' ? `filter[tree_id]=${tree_id}` : ''
  const isAliveParam = typeof is_alive !== 'undefined' ? `filter[is_alive]=${is_alive}` : ''
  const typeParam = typeof type !== 'undefined' ? `filter[type]=${type}` : ''
  const fieldsParam = fields ? `fields=${fields}` : ''

  const queryParams = [
    pageParam,
    pageSizeParam,
    nameParam,
    idParam,
    idsParam,
    genderParam,
    looksParam,
    tagsParam,
    tree_idParam,
    isAliveParam,
    typeParam,
    fieldsParam,
  ]
    .filter((p) => p)
    .join('&')
  // console.log('### queryParams', queryParams)

  // console.log('### getPagedSims.queryParams', queryParams)
  return axios.get(
    `${API_URL}/person?${queryParams}`,
    ignoreHeaders
      ? {
          transformRequest: (data, headers) => {
            console.log('### transformRequest', headers)
            delete headers['Authorization']
            return data
          },
        }
      : undefined
  )
}

export function getPerson(id) {
  // console.log('### getPerson', id)
  return axios.get(`${API_URL}/person/${id}`)
}

export function getMineReactions(type: ReactionTypes) {
  return axios.get(`${API_URL}/reaction/mine?type=${type}`)
}
