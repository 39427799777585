import {AvatarModel} from '@api/models'
import {useTreesStore} from '@store/trees'

const useTreeAvatars = (treeId: number): AvatarModel[] => {
  const {trees} = useTreesStore()

  return trees[treeId]?.params?.avatars || []
}

export default useTreeAvatars
