import React, {useMemo} from 'react'
import styled from 'styled-components'

import {useTreesStore} from '@store/trees'
import {usePersonsStore} from '@store/persons'

import usePrepareDataForTree from '@components/organisms/Tree/hooks/usePrepareDataForTree'
import useSubTree from './useSubTree'

import TreeCanvas from '@components/organisms/Tree/components/TreeCanvas'

import {TREE_BACKGROUND_COLORS, TreeBackgroundColorsEnum} from '@constants/treeBackgroundColors'

import {PersonModel, TreeModel} from '@api/models'

type SubTreeProps = {
  treeId: TreeModel['id']
  person: PersonModel
  editMode: boolean
  readonly?: boolean
}
const SubTree = ({treeId, person, editMode, readonly}: SubTreeProps) => {
  // console.log('### SubTree', editMode)
  const {trees} = useTreesStore()
  const {persons} = usePersonsStore()
  const personTree = useMemo(() => trees[treeId], [treeId, trees])

  const background =
    personTree.background?.url ||
    (personTree.background_id < 0
      ? TREE_BACKGROUND_COLORS[personTree.background_id].image
      : TREE_BACKGROUND_COLORS[TreeBackgroundColorsEnum.green].image)

  const {initialized, nodes: subTreeNodes} = useSubTree(person)
  // console.log('### SubTree.subTree', subTree)
  // console.log('### persons', persons)
  const {nodes, personsMap} = usePrepareDataForTree(subTreeNodes, persons)
  // console.log('### nodes', nodes)
  console.log('### personsMap', personsMap)

  return (
    <Wrapper $background={background}>
      {persons?.length && persons?.length && nodes?.length > 0 ? (
        <TreeCanvas
          treeId={treeId}
          nodes={nodes}
          personsMap={personsMap}
          editMode={editMode}
          subTreeOwner={person}
          readonly={readonly}
          initialized={initialized}
        />
      ) : null}
    </Wrapper>
  )
}

export default React.memo(SubTree)

const Wrapper = styled.div<{$background: string}>`
  width: 100%;
  height: 100%;

  background-image: url(${({$background}) => $background});
  background-size: cover;
  background-position: center;

  overflow: hidden;
`
