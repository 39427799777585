import React, {useEffect, useMemo, useState} from 'react'
import {useLocation} from 'react-router'
import styled from 'styled-components'

import {useTreesStore} from '@store/trees'
import {usePersonsStore} from '@store/persons'
import {useSubTreeModalStore} from '@components/molecules/SubTreeModal/SubTreeModal'
import {useRelationsStore} from '@pages/RelationsPage/store'
import {useCultsStore} from '@pages/CultsPage/store'
import {useSimDrawerStore} from '@_metronic/partials/layout/sim-drawer/SimDrawer'

import {useAuth} from '@app/modules/auth'

import {getPublicTree} from '@api/requests'
import {PersonModel} from '@api/models'

import useShareTreeLink, {
  DIARY_ARTICLE_ID_SHARE_LINK_PARAM,
  PERSON_ID_SHARE_LINK_PARAM,
} from '@hooks/useShareTreeLink'

import {SimDrawer, SimDrawerStorePayload} from '@_metronic/partials/layout/sim-drawer/SimDrawer'
import {PageDataProvider} from '@_metronic/layout/core'
import {ToolbarWrapper} from './components/ToolbarWrapper'
import {ViewPhotosDrawer} from '@_metronic/partials/layout/viewphotos-drawer/ViewPhotosDrawer'
import {ViewTreeDrawer} from '@_metronic/partials/layout/viewtree-drawer/ViewTreeDrawer'
import {ViewArticleDrawer} from '@_metronic/partials/layout/viewarticle-drawer/ViewArticleDrawer'
import MessengerModal from '@components/molecules/MessengerModal'
import ViewTreeAuthorDrawer from '@components/molecules/ViewTreeAuthorDrawer'

import Tree from '@components/organisms/Tree'
import TreeCommentsAndLikes from '@components/atoms/TreeCommentsAndLikes'
import SubTreeModal from '@components/molecules/SubTreeModal'
import TreeInfoBlock from '@pages/PublicTree/components/TreeInfoBlock'
import SignInLink from './components/SignInLink'
import ImagesGalleryModal from '@components/molecules/ImagesGalleryModal'
import {CultDrawer} from '@_metronic/partials/layout/cult-drawer/CultDrawer'

const PublicTree = () => {
  const {currentUser} = useAuth()
  const location = useLocation()

  const {trees, activeTreeId, addTree} = useTreesStore()

  const {updatePersons} = usePersonsStore()
  const {updateRelations} = useRelationsStore()
  const {updateCults} = useCultsStore()

  const tree = useMemo(() => {
    if (activeTreeId && trees[activeTreeId]) return trees[activeTreeId]
  }, [trees, activeTreeId])

  const [persons, setPersons] = useState<PersonModel[]>()

  const {pathname} = useLocation()
  const token = pathname.split('/').reverse()[0]
  const [refToken, setRefToken] = useState<string>()
  const [loaded, setLoaded] = useState(false)

  const {show: showSimDrawer} = useSimDrawerStore()
  const openSharedPersonCard = () => {
    const searchParams = new URLSearchParams(location.search)

    const sharedPersonId = searchParams.get(PERSON_ID_SHARE_LINK_PARAM)
    console.log('### ', {sharedPersonId, loaded})
    if (sharedPersonId) {
      const sharedDiaryArticleId = searchParams.get(DIARY_ARTICLE_ID_SHARE_LINK_PARAM)

      let options: SimDrawerStorePayload = {allowSharing: !subTreeModalOpened}

      if (sharedDiaryArticleId) {
        options.articleId = sharedDiaryArticleId
      }
      showSimDrawer(parseInt(sharedPersonId), options)
    }
  }

  useEffect(() => {
    if (token) {
      getPublicTree(token)
        .then((response) => {
          console.log('### getPublicTree.response', response)

          const {tree, persons, author, partnership, looks} = response.data
          addTree(tree)
          setPersons(persons)
          setRefToken(author?.ref_token)

          console.log('### START')
          updatePersons(persons, true)
          console.log('### END')
          updateRelations(partnership)
          updateCults(looks)
        })
        .catch((error) => {
          console.log('### error', error)
        })
        .finally(() => {
          setLoaded(true)
        })
    }
  }, [token])

  const queryString = useShareTreeLink({refToken}, true)
  useEffect(() => {
    const containToken = window.location.href.includes('ref=')
    if (refToken && !containToken) {
      window.history.pushState(null, '', queryString)
    }
  }, [refToken])

  const {opened: subTreeModalOpened} = useSubTreeModalStore()
  console.log('### subTreeModalOpened', subTreeModalOpened)

  useEffect(() => {
    if (loaded) {
      openSharedPersonCard()
    }
  }, [location, loaded])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                {tree && persons?.length ? (
                  <Wrapper>
                    <Toolbar tree={tree} editMode={false} refToken={refToken} readonly>
                      {/*@ts-ignore*/}
                      {tree.publish ? (
                        <TreeCommentsAndLikes treePublish={tree.publish} hideFavorite />
                      ) : null}
                      {!currentUser && <SignInLink refToken={refToken} />}
                    </Toolbar>

                    <SubTreeModal readonly editMode={false}>
                      <FullSizeContent>
                        <Tree
                          treeId={tree?.id}
                          tree={tree}
                          persons={persons}
                          editMode={false}
                          readonly
                        />
                        <TreeInfoBlock treeId={tree?.id} tree={tree} editMode={false} />
                      </FullSizeContent>
                    </SubTreeModal>
                  </Wrapper>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {loaded ? (
        <>
          <ViewTreeDrawer readonly />
          <ViewTreeAuthorDrawer />
          <SimDrawer readonly refToken={refToken} />
          <ViewPhotosDrawer />
          <ImagesGalleryModal readonly />
          <MessengerModal refToken={refToken} />
          <ViewArticleDrawer refToken={refToken} />
          <CultDrawer readonly />
        </>
      ) : null}
    </PageDataProvider>
  )
}

export default React.memo(PublicTree)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Toolbar = styled(ToolbarWrapper)`
  padding-left: 10px !important;
  padding-top: 10px !important;
`

const FullSizeContent = styled.div<{$readonly?: boolean}>`
  width: 100%;
  height: 100%;

  position: relative;

  border-radius: ${({$readonly}) => ($readonly ? 0 : 5)}px;
  overflow: hidden;
`
