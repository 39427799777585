import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {create} from 'zustand'
import styled from 'styled-components'

import {useArticleStore} from '@store/articles'
import {useViewArticleDrawerStore} from '@_metronic/partials/layout/viewarticle-drawer/ViewArticleDrawer'
import {useEditSimDrawerStore} from '@_metronic/partials/layout/editsim-drawer/EditSimDrawer'

import useShareTreeLink from '@hooks/useShareTreeLink'
import usePersonAvatars from '@hooks/usePersonAvatars'

import {getPersonArticles} from '@api/requests'

import ShareLink from '@components/atoms/ShareLink'
import {SimDrawerArticles} from '@_metronic/partials/layout/sim-drawer/SimDrawerArticles'

import SimDrawerContent from './SimDrawerContent/SimDrawerContent'
import SimDrawerAvatars from './SimDrawerAvatars'

import {KTIcon} from '../../../helpers'
import {Article, PersonModel} from '@api/models'

export type SimDrawerStorePayload = {articleId?: Article['_id']; allowSharing?: boolean}
type SimDrawerStore = {
  opened: boolean
  personId: PersonModel['id'] | null
  payload?: SimDrawerStorePayload | null
  show: (personId: PersonModel['id'], payload?: SimDrawerStorePayload) => void
  hide: () => void
}

export const useSimDrawerStore = create<SimDrawerStore>((set) => ({
  opened: false,
  personId: null,
  show: (personId, payload) => set((state) => ({...state, opened: true, personId, payload})),
  hide: () => set((state) => ({...state, opened: false, personId: null, payload: null})),
}))

enum Tabs {
  info = 'info',
  articles = 'articles',
  avatars = 'avatars',
}
const DEFAULT_TAB = Tabs.info

type SimDrawerProps = {
  readonly?: boolean
  refToken?: string
}

const SimDrawer = ({readonly = false, refToken}: SimDrawerProps) => {
  const intl = useIntl()
  const {opened, personId, hide, payload} = useSimDrawerStore()

  const {setActivePersonArticles} = useArticleStore()
  const [tab, setTab] = useState(DEFAULT_TAB)

  const {show: showEditSimDrawer} = useEditSimDrawerStore()

  const openEdit = () => {
    hide()
    showEditSimDrawer(personId!)
  }

  const {open: showArticle} = useViewArticleDrawerStore()

  useEffect(() => {
    if (personId) {
      getPersonArticles(personId).then((response) => {
        let data = response.data
        if (data.result) {
          setActivePersonArticles(data.docs)

          if (payload?.articleId) {
            const foundAutoOpenArticle = data.docs.find(({_id}) => _id === payload?.articleId)

            if (foundAutoOpenArticle) {
              showArticle(foundAutoOpenArticle, {
                personId,
                allowSharing: payload?.allowSharing,
              })
            }
          }
        }
      })
    }
  }, [personId])

  const shareLinkPerson = useShareTreeLink({personId, refToken}, true)
  const shareLinkDefault = useShareTreeLink({refToken}, true)
  useEffect(() => {
    if (!opened) {
      setTab(DEFAULT_TAB)
      setActivePersonArticles([])
    } else if (payload?.allowSharing) {
      window.history.pushState(null, '', shareLinkPerson)
      return () => {
        window.history.pushState(null, '', shareLinkDefault)
      }
    }
  }, [opened])

  const shareTreeLink = useShareTreeLink({
    personId,
  })

  const {activePersonArticles} = useArticleStore()
  const avatars = usePersonAvatars(personId)

  return (
    <>
      {opened ? <Overlay onClick={hide} /> : null}
      <Wrapper className='bg-body' $hidden={!opened}>
        {/* begin::Card */}
        <div className='card shadow-none rounded-0 w-100'>
          {/* begin::Header */}
          <div className='card-header' id='kt_help_header'>
            <h5 className='card-title fw-bold'>{intl.formatMessage({id: 'SIM.CARD.TITLE'})}</h5>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                onClick={() => hide()}
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>
          </div>
          {/* end::Header */}
          <div className='card-header'>
            <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0'>
              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, {active: tab === Tabs.info})}
                  onClick={() => setTab(Tabs.info)}
                >
                  {intl.formatMessage({id: 'SIM.VIEW.TAB_INFO'})}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, {active: tab === Tabs.articles})}
                  onClick={() => setTab(Tabs.articles)}
                >
                  {intl.formatMessage({id: 'SIM.VIEW.TAB_ARTICLES'})}
                  {activePersonArticles.length ? (
                    <span className='badge badge-sm badge-circle badge-success m-1'>
                      {activePersonArticles.length}
                    </span>
                  ) : null}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, {active: tab === Tabs.avatars})}
                  onClick={() => setTab(Tabs.avatars)}
                >
                  {intl.formatMessage({id: 'SIM.VIEW.TAB_AVATARS'})}
                  {avatars.length ? (
                    <span className='badge badge-sm badge-circle badge-success m-1'>
                      {avatars.length}
                    </span>
                  ) : null}
                </a>
              </li>
            </ul>
            {payload?.allowSharing && shareTreeLink ? (
              <div className='card-toolbar'>
                <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                  <ShareLink link={shareTreeLink} copiedLabelId={'SIM.VIEW.PERSON_LINK_COPIED'} />
                </div>
              </div>
            ) : null}
          </div>

          {/* begin::Body */}
          <div className={'card-body hover-scroll-overlay-y h-400px pt-0'}>
            {tab === Tabs.info ? (
              <SimDrawerContent personId={personId} readonly={readonly} />
            ) : null}
            {tab === Tabs.articles && (
              <SimDrawerArticles
                personId={personId!}
                readonly={readonly}
                allowSharing={payload?.allowSharing}
              />
            )}
            {tab === Tabs.avatars && <SimDrawerAvatars readonly={readonly} personId={personId!} />}
          </div>
          {/* end::Body */}

          {/* begin::Footer */}
          {!readonly ? (
            <div className='card-footer py-5 text-center' id='kt_activities_footer'>
              <div className='d-flex justify-content-end'>
                <button
                  className='btn btn-secondary d-flex justify-content-end me-3'
                  onClick={hide}
                >
                  {intl.formatMessage({id: 'TREE.ADD.CLOSE'})}
                </button>
                <button className='btn btn-success d-flex justify-content-end' onClick={openEdit}>
                  {intl.formatMessage({id: 'EDIT'})}
                </button>
              </div>
            </div>
          ) : null}
          {/* end::Footer */}
        </div>
        {/* end::Card */}
      </Wrapper>
    </>
  )
}

export {SimDrawer}

const Wrapper = styled.div<{$hidden: boolean}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'flex')};

  position: fixed;
  top: 0;
  right: 0;

  width: 525px;
  height: 100%;

  z-index: 106;

  @media (max-width: 800px) {
    width: 350px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);

  z-index: 105;
`
